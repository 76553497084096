import React, { useRef, useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  Typography,
  Grid,
  Paper,
  MenuItem,
  Box,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import axios from "axios";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const EmbededContactForm = ({
  baseConfig,
  title = "Contact Us",
  subTitle = "Fill out the form below to get started.",
  dropDownOptions = ["General Inquiry", "Legal Consultation", "Other"],
  submitButtonLabel = "Submit",
}) => {
  const recaptchaRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [agreeToTerms, setAgreeToTerms] = useState(false);

  // Validation Schema
  const ContactSchema = Yup.object().shape({
    name: Yup.string()
      .max(50, "Name is too long!")
      .required("Name is required"),
    organization: Yup.string().max(50, "Organization name is too long!"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone: Yup.string()
      .matches(/^[0-9]+$/, "Invalid phone number")
      .max(20),
    dropdownSelection: Yup.string().required("Please select an option"),
    description: Yup.string().max(2000, "Description is too long!"),
    recaptcha: Yup.string().required("Please verify you are not a robot"),
  });

  // Initial Form Values
  const initialValues = {
    name: "",
    organization: "",
    email: "",
    phone: "",
    dropdownSelection: "",
    description: "",
    recaptcha: "",
  };

  // Form Submit Handler
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const fullUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

    try {
      const response = await axios.post(
        `${window._env_.REACT_APP_LPIM_API_URL}/patanal_api/contact/`,
        {
          ...values,
          subject: values.dropdownSelection, // ✅ Set the dropdown selection as the subject
          "g-recaptcha-response": values.recaptcha,
          page: fullUrl,
        }
      );

      toast.success(response.data.message);
      resetForm();
      recaptchaRef.current.reset();

      // ✅ Trigger GA4 conversion event
      if (window.gtag && baseConfig.googleAnalyticsId) {
        window.gtag("event", "conversion_event_submit_lead_form", {
          send_to: baseConfig.googleAnalyticsId,
          event_category: "Form",
          event_label: "Contact Us Form Submission",
          value: 1,
        });
      }
    } catch (error) {
      toast.error("Submission failed. Please try again.");
      console.error("Submission failed", error);
    }
    setSubmitting(false);
  };

  return (
    <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
      <Typography
        component="h1"
        variant="h6"
        fontWeight="bold"
        textAlign="center"
      >
        {title}
      </Typography>
      <Typography variant="body2" textAlign="center" mb={2}>
        {subTitle}
      </Typography>

      <Formik
        initialValues={initialValues}
        validationSchema={ContactSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values, errors, touched, isSubmitting }) => (
          <Form>
            <Grid container spacing={2}>
              {/* Name Field */}
              <Grid item xs={12} sm={6}>
                <Field
                  as={TextField}
                  name="name"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  required
                  error={touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                />
              </Grid>

              {/* Organization Field (Optional) */}
              <Grid item xs={12} sm={6}>
                <Field
                  as={TextField}
                  name="organization"
                  label="Organization"
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              {/* Email Field */}
              <Grid item xs={12} sm={6}>
                <Field
                  as={TextField}
                  name="email"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  required
                  error={touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                />
              </Grid>

              {/* Phone Field */}
              <Grid item xs={12} sm={6}>
                <Field
                  as={TextField}
                  name="phone"
                  label="Phone"
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              {/* Dropdown for Options */}
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="dropdownSelection"
                  label="What Can We Help You With?"
                  select
                  fullWidth
                  error={
                    touched.dropdownSelection && !!errors.dropdownSelection
                  }
                  helperText={
                    touched.dropdownSelection && errors.dropdownSelection
                  }
                >
                  {dropDownOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>

              {/* Description Field */}
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="description"
                  label="Non-Confidential Details (Optional)"
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              {/* ReCAPTCHA */}
              <Grid item xs={12}>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={window._env_.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={(value) => setFieldValue("recaptcha", value)}
                  size={isMobile ? "compact" : "normal"}
                />
              </Grid>

              {/* Required Checkbox */}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={agreeToTerms}
                      onChange={(e) => setAgreeToTerms(e.target.checked)}
                      color="primary"
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ fontSize: "0.8rem" }}
                    >
                      I understand and agree that{" "}
                      <strong>NO ATTORNEY-CLIENT RELATIONSHIP</strong> is formed
                      by submitting this form.
                    </Typography>
                  }
                />
              </Grid>

              {/* Submit Button - Disabled if Required Fields are Empty */}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={
                    !values.name ||
                    !values.email ||
                    !values.dropdownSelection ||
                    !agreeToTerms ||
                    isSubmitting
                  }
                >
                  {submitButtonLabel}
                </Button>
              </Grid>

              {/* Hardcoded Disclaimer */}
              <Grid item xs={12}>
                <Typography
                  variant="caption"
                  display="block"
                  textAlign="left"
                  mt={2}
                >
                  <strong>No solicitors or job applicants.</strong> By
                  submitting this form, you agree to our{" "}
                  <a href="/privacy-policy" style={{ textDecoration: "none" }}>
                    Privacy Policy
                  </a>{" "}
                  &{" "}
                  <a
                    href="/terms-of-service"
                    style={{ textDecoration: "none" }}
                  >
                    Terms of Service
                  </a>
                  . The information provided will be used to contact you about
                  legal services.
                </Typography>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Paper>
  );
};

export default EmbededContactForm;
