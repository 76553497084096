import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Card,
  Paper,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
  Link as MuiLink,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  useMediaQuery,
  Alert,
  CircularProgress,
  Grid,
  ListItemButton,
} from "@mui/material";
import { styled, useTheme } from "@mui/system";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import SetDocumentMeta from "../common/SetDocumentMeta";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const apiUrlKth = `${window._env_.REACT_APP_PATANALAI_API_URL}`;

// Styled components
const StyledCard = styled(Card)({
  marginTop: "20px",
  padding: "20px",
  border: "1px solid #ddd",
});

const Disclaimer = styled(Typography)({
  fontWeight: "bold",
  color: "red",
  marginTop: "10px",
});

const BackgroundBox = styled(Box)({
  backgroundColor: "#f8f9fa",
  padding: "20px",
  borderRadius: "8px",
  marginTop: "20px",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
});

const AiLegalTerms = ({ baseConfig }) => {
  SetDocumentMeta({
    title: "AI Legal Terms",
    description:
      "Expert legal counseling on legal agreements for AI applications, services, technology transfers, and more. \
    We provide expert legal advice on terms of service, privacy policies, SaaS agreements, and more.",
    keywords: "AI legal terms, TOS, click-wrap AI legal terms",
  });

  const {
    priColor,
    priHoverColor,
    tertColor,
    tertHoverColor,
    quadColor,
    quadHoverColor,
  } = baseConfig.siteBranding;

  const [terms, setTerms] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [showOnlyKeyTerms, setShowOnlyKeyTerms] = useState(false);
  const [showBlurbIndex, setShowBlurbIndex] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    fetchTerms();
  }, []);

  useEffect(() => {
    // Scroll to the term details section whenever a new term is selected
    if (selectedTerm) {
      const termDetailsSection = document.getElementById("term-details");
      if (termDetailsSection) {
        termDetailsSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [selectedTerm]);

  const fetchTerms = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${apiUrlKth}/kth-ai/eulas`);
      setTerms(response.data);
    } catch (error) {
      setError("Failed to fetch AI Legal terms.");
      console.error("Error fetching terms:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTermClick = (term) => {
    setSelectedTerm(term);
  };

  const handleShowOnlyKeyTermsChange = (event) => {
    setShowOnlyKeyTerms(event.target.value === "key");
    setSelectedTerm(null); // Close the term details when switching views
  };

  const handleToggleBlurb = (index) => {
    // Toggle the visibility of the blurb for a specific item
    if (isLargeScreen) {
      setShowBlurbIndex(null); // No toggle behavior for large screens
    } else {
      setShowBlurbIndex(showBlurbIndex === index ? null : index);
    }
  };

  // Calculate the counts for X and Y
  const importantTermsCount = terms.filter(
    (term) => term.importance === "Featured" || term.importance === "Important"
  ).length;
  const totalTermsCount = terms.length;

  // Filter and sort terms
  const filteredTerms = showOnlyKeyTerms
    ? terms.filter(
        (term) =>
          term.importance === "Featured" || term.importance === "Important"
      )
    : terms;

  // Sort terms by ordering, putting "Other" subgroup last
  const sortedTerms = [...filteredTerms].sort((a, b) => {
    if (a.term_subgroup === "Other" && b.term_subgroup !== "Other") return 1;
    if (b.term_subgroup === "Other" && a.term_subgroup !== "Other") return -1;
    return a.ordering - b.ordering;
  });

  const renderTermList = () => {
    let lastSubgroup = null;
    let termNumber = 1;

    return sortedTerms.map((term) => {
      const termSubgroup = term.term_subgroup || "Other";
      const subgroupChanged = termSubgroup !== lastSubgroup;
      lastSubgroup = termSubgroup;

      if (subgroupChanged) {
        termNumber = 1; // Reset numbering for each subgroup
      }

      const listItemNumber = termNumber++;
      return (
        <React.Fragment key={term.id}>
          {subgroupChanged && (
            <>
              <Typography variant="h6" style={{ marginTop: "20px" }}>
                {termSubgroup}
              </Typography>
              <Divider />
            </>
          )}
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => handleTermClick(term)}
              selected={selectedTerm && selectedTerm.id === term.id}
              style={{
                backgroundColor:
                  selectedTerm && selectedTerm.id === term.id
                    ? tertColor
                    : "inherit",
                color:
                  selectedTerm && selectedTerm.id === term.id
                    ? "#fff"
                    : "inherit",
              }}
            >
              <ListItemText
                primary={`${listItemNumber}. ${term.term_short}: ${term.term}`}
              />
            </ListItemButton>
          </ListItem>
        </React.Fragment>
      );
    });
  };

  const renderTermDetails = () => {
    if (!selectedTerm) return null;

    return (
      <StyledCard id="term-details">
        <Typography variant="h5">
          {selectedTerm.term_short} - {selectedTerm.term}
        </Typography>
        <Typography
          variant="body2"
          dangerouslySetInnerHTML={{ __html: selectedTerm.info }}
        />
        <Typography variant="h6" style={{ marginTop: "20px" }}>
          Legal Term's Use in Select AI Applications
        </Typography>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>AI Application</TableCell>
                <TableCell>Response</TableCell>
                <TableCell>Comments</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(selectedTerm.eula_terms) &&
              selectedTerm.eula_terms.length > 0 ? (
                selectedTerm.eula_terms.map((eulaTerm, index) => (
                  <TableRow key={index}>
                    <TableCell>{eulaTerm.service}</TableCell>
                    <TableCell>{eulaTerm.eula_response || "N/A"}</TableCell>
                    <TableCell>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: eulaTerm.discussion,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    No AI legal term usage available.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>

        {selectedTerm.related_defn_for_ai_term &&
          selectedTerm.related_defn_for_ai_term.length > 0 && (
            <Box sx={{ mt: 4 }}>
              <Typography variant="h6">Learn More</Typography>
              <List>
                {selectedTerm.related_defn_for_ai_term.map(
                  (definition, index) => (
                    <Box key={index} sx={{ mb: 1, ml: 2 }}>
                      <ListItem disablePadding>
                        <ListItemButton
                          onClick={() =>
                            setShowBlurbIndex(
                              showBlurbIndex === index ? null : index
                            )
                          }
                          style={{ padding: "4px 0" }}
                        >
                          <Typography variant="body1">
                            {definition.defn.term}
                          </Typography>
                        </ListItemButton>
                      </ListItem>
                      {showBlurbIndex === index && (
                        <Box sx={{ ml: 2, mt: 1 }}>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="span"
                          >
                            {definition.defn.term_blurb}
                          </Typography>
                          <MuiLink
                            href={`/legal-definitions-for-ai/${definition.defn.slug}`}
                            color="primary"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                              textDecoration: "none",
                              ml: 1,
                              display: "inline-flex",
                              alignItems: "center",
                              fontSize: "small", // Match the size of the ChevronRightIcon
                            }}
                          >
                            <Typography variant="body2" component="span">
                              more
                            </Typography>
                            <ChevronRightIcon
                              fontSize="small"
                              sx={{ ml: 0.5 }}
                            />
                          </MuiLink>
                        </Box>
                      )}
                    </Box>
                  )
                )}
              </List>
            </Box>
          )}
      </StyledCard>
    );
  };

  if (isLoading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="link-no-decoration">
        <Grid item xs={12}>
          <Alert severity="error" sx={{ mt: 4 }}>
            {error}
          </Alert>
          <Button
            startIcon={<FirstPageIcon />}
            color="primary"
            component={RouterLink}
            to="/legal-compliance-for-ai"
            sx={{ mt: 2, mb: 2 }}
          >
            Search AI Compliance
          </Button>
        </Grid>
      </Container>
    );
  }

  return (
    <Container className="link-no-decoration">
      <Typography variant="h4" gutterBottom align="center" sx={{ mt: 2 }}>
        {`${importantTermsCount} key legal terms and ${totalTermsCount} total terms to consider for your AI Legal Agreements`}
      </Typography>
      <Disclaimer align="center">*** THIS IS NOT LEGAL ADVICE ***</Disclaimer>
      <Typography variant="body2" color="textSecondary" align="center">
        Please{" "}
        <MuiLink href="/contact" target="_blank">
          contact us
        </MuiLink>{" "}
        for legal advice on your specific situation. No attorney-client
        privilege will attach until we agree to the representation in writing.
      </Typography>
      <Typography variant="body1" style={{ marginTop: "20px" }}>
        This page highlights important legal terms found (or that ought to be
        found) in legally enforceable AI Contracts. It provides insights into
        various terms that may need consideration when drafting or reviewing
        AI-related agreements.
      </Typography>

      <BackgroundBox>
        <Typography variant="h5" align="center" gutterBottom>
          AI Legal Terms
        </Typography>
        <RadioGroup
          row
          value={showOnlyKeyTerms ? "key" : "all"}
          onChange={handleShowOnlyKeyTermsChange}
          style={{
            marginTop: "5px",
            marginBottom: "5px",
            justifyContent: "center",
          }}
        >
          <FormControlLabel
            value="key"
            control={<Radio />}
            label="Show Only Key Terms"
          />
          <FormControlLabel
            value="all"
            control={<Radio />}
            label="Show All Terms"
          />
        </RadioGroup>
        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
          sx={{ mb: 2 }}
        >
          Click on term below to view details
        </Typography>

        <List component="nav" style={{ marginBottom: "20px" }}>
          {renderTermList()}
        </List>
      </BackgroundBox>

      {renderTermDetails()}
    </Container>
  );
};

export default AiLegalTerms;
