import React, { useEffect } from "react";

const EUAIActChecker = () => {
  useEffect(() => {
    /*****************************************
     * 1. Dynamically load Bootstrap JS
     *****************************************/
    const bootstrapScript = document.createElement("script");
    bootstrapScript.src = "https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js";
    bootstrapScript.async = true;
    bootstrapScript.onload = () => {
      // Once Bootstrap is loaded, run your original JS code:
      initEUAIActChecker();
    };
    document.body.appendChild(bootstrapScript);

    // We place your entire <script> logic below, as a function:
    function initEUAIActChecker() {
      /***********************************************************
       * Original Variables & Step Data
       ************************************************************/
      const steps = [
        {
          id: "step_aiSystemCheck",
          title: "Is my system an 'AI System' according to the EU AI Act?",
          info: `
            <div class="ai-definition text-muted">
              <p>
                ‘AI system’ means a machine-based system that is designed to operate with varying levels of autonomy and that may exhibit adaptiveness after deployment, and that, for explicit or implicit objectives, infers, from the input it receives, how to generate outputs such as predictions, content, recommendations, or decisions that can influence physical or virtual environments.
              </p>
            </div>

            <footer class="text-muted">
              Source: <cite title="Article 3">
                <a href="https://artificialintelligenceact.eu/article/3/" target="_blank" rel="noopener noreferrer">
                  Article 3, point 1
                </a>
              </cite> of the EU AI Act
            </footer>

            <p></p>
          `,
          questions: [
            {
              name: "isAiSystem",
              type: "radio",
              label: "Does your system meet this definition?",
              required: true,
              options: [
                { value: "yes", label: "Yes, it meets the definition" },
                { value: "no", label: "No, it does not" },
              ],
            },
          ],
        },
        {
          id: "step_entityType",
          title: "Entity Type",
          info: `
            <p><strong>Which kind of entity is your organisation?</strong></p>
            <p><em>Note:</em> If you match the definition of multiple 'types' of entity, you must complete the form multiple times, once for each entity type.</p>
            <ul>
              <li><strong>Provider:</strong> A natural or legal person, public authority, agency or other body that develops an AI system or a general-purpose AI model (or that has an AI system or a general-purpose AI model developed) and places them on the market or puts the system into service under its own name or trademark, whether for payment or free of charge. <a href="https://artificialintelligenceact.eu/article/3/" target="_blank">Article 3(3)</a></li>
              <li><strong>Deployer:</strong> Any natural or legal person, public authority, agency or other body using an AI system under its authority, except where the AI system is used in the course of a personal non-professional activity. <a href="https://artificialintelligenceact.eu/article/3/" target="_blank">Article 3(4)</a></li>
              <li><strong>Distributor:</strong> Any natural or legal person in the supply chain, other than the provider or the importer, that makes an AI system available on the Union market. <a href="https://artificialintelligenceact.eu/article/3/" target="_blank">Article 3(7)</a></li>
              <li><strong>Importer:</strong> Any natural or legal person located or established in the Union that places on the market an AI system that bears the name or trademark of a natural or legal person established outside the Union. <a href="https://artificialintelligenceact.eu/article/3/" target="_blank">Article 3(6)</a></li>
              <li><strong>Product Manufacturer:</strong> Places on the market or puts into service an AI system together with their product and under their own name or trademark. <a href="https://artificialintelligenceact.eu/article/2/" target="_blank">Article 2(1)(e)</a></li>
              <li><strong>Authorised Representative:</strong> Any natural or legal person located or established in the Union who has received and accepted a written mandate from a provider of an AI system or a general-purpose AI model to, respectively, perform and carry out on its behalf the obligations and procedures established by this Regulation. <a href="https://artificialintelligenceact.eu/article/3/" target="_blank">Article 3(5)</a></li>
            </ul>
          `,
          questions: [
            {
              name: "entityType",
              type: "radio",
              label: "Select your entity type:",
              required: true,
              options: [
                { value: "provider", label: "Provider" },
                { value: "deployer", label: "Deployer" },
                { value: "distributor", label: "Distributor" },
                { value: "importer", label: "Importer" },
                { value: "productManufacturer", label: "Product Manufacturer" },
                { value: "authorizedRep", label: "Authorised Representative" },
              ],
            },
          ],
        },
        // ... (All the remaining steps are unchanged, exactly as in your code)
        {
          id: "step_significantRisk",
          title: "Significant Risk of Harm",
          info: `
            <p>Does your AI system pose a significant risk of harm to health, safety or fundamental rights? 
               (<a href="https://artificialintelligenceact.eu/article/6/#point3" target="_blank">Article 6(3)</a>)</p>
            <p><em>
              The system does NOT pose significant risk if it performs only a narrow procedural task, 
              or improves the result of a previously completed human activity without replacing/influencing it, etc.
            </em></p>
          `,
          questions: [
            {
              name: "sigRisk",
              type: "radio",
              label: "Select one:",
              required: true,
              options: [
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ],
            },
          ],
        },
        {
          id: "step_transparency",
          title: "Transparent Systems",
          info: `
            <p>Does your system perform any of these functions?</p>
            <p><strong>Source:</strong> <a href="https://artificialintelligenceact.eu/article/50/" target="_blank">Article 50</a></p>
          `,
          questions: [
            {
              name: "transparencyFuncs",
              type: "checkboxGroup",
              label: "Select all that apply:",
              required: true,
              options: [
                { value: "interacting", label: "Interacting with people" },
                { value: "emotionBiometric", label: "Emotion recognition or biometric categorisation" },
                { value: "syntheticContent", label: "Generating synthetic content (audio, image, video, text)" },
                { value: "deepFake", label: "Generating/manipulating deep fake content" },
                { value: "none", label: "None of the above" },
              ],
            },
          ],
        },
      ];

      let userData = {};
      let currentStepIndex = 0;

      // Grab the prohibitedModal by ID for bootstrap
      const prohibitedModalEl = document.getElementById("prohibitedModal");
      const prohibitedModal = prohibitedModalEl
        ? new window.bootstrap.Modal(prohibitedModalEl, { keyboard: false })
        : null;

      /**************************************************************
       * Render Step function, skip logic, validate, showResults, etc.
       * EXACT same code from your <script> block
       **************************************************************/

      function renderStep() {
        const wizard = document.getElementById("wizard");
        if (!wizard) return;
        wizard.innerHTML = "";

        const dynamicHeader = document.getElementById("dynamicHeader");
        const introCard = document.getElementById("introCard");

        if (dynamicHeader) dynamicHeader.innerHTML = "";
        if (introCard) introCard.classList.add("hidden");

        // If we've surpassed the steps length, show results
        if (currentStepIndex >= steps.length) {
          showResults();
          return;
        }

        const stepData = steps[currentStepIndex];

        // Check if step should be skipped based on user answers
        if (skipStep(stepData)) {
          currentStepIndex++;
          renderStep();
          return;
        }

        // Render dynamic header only on first step
        if (currentStepIndex === 0 && dynamicHeader) {
          dynamicHeader.innerHTML = `
            <h1 class="mb-4">EU AI Act Compliance Checker</h1>
            <div class="card mb-4">
              <div class="card-body">
                <p class="card-text">
                  The European Union’s Artificial Intelligence Act introduces new legal requirements for organizations
                  both within and outside the EU. Use this interactive tool to see if your AI system falls under these obligations.
                </p>
              </div>
            </div>
            <h3 class="mb-3">How will the EU AI Act affect my AI system?</h3>
          `;
        }

        // Build the step card
        const card = document.createElement("div");
        card.className = "card mb-4";

        const cardBody = document.createElement("div");
        cardBody.className = "card-body";

        const title = document.createElement("h3");
        title.className = "card-title";
        title.textContent = stepData.title;
        cardBody.appendChild(title);

        if (stepData.info) {
          const infoEl = document.createElement("div");
          infoEl.innerHTML = stepData.info;
          cardBody.appendChild(infoEl);
        }

        // Questions
        stepData.questions.forEach((q) => {
          const formGroup = document.createElement("div");
          formGroup.className = "mb-3";

          if (q.label) {
            const labelEl = document.createElement("label");
            labelEl.className = "form-label fw-bold";
            labelEl.textContent = q.label + (q.required ? " *" : "");
            formGroup.appendChild(labelEl);
          }

          if (q.type === "radio") {
            (q.options || []).forEach((opt) => {
              const div = document.createElement("div");
              div.className = "form-check";

              const input = document.createElement("input");
              input.className = "form-check-input";
              input.type = "radio";
              input.name = q.name;
              input.id = `${q.name}_${opt.value}`;
              input.value = opt.value;
              input.required = q.required;
              input.checked = userData[q.name] === opt.value;
              input.onclick = () => {
                userData[q.name] = opt.value;
              };

              const label = document.createElement("label");
              label.className = "form-check-label";
              label.htmlFor = `${q.name}_${opt.value}`;
              label.textContent = opt.label;

              div.appendChild(input);
              div.appendChild(label);
              formGroup.appendChild(div);
            });
          } else if (q.type === "checkboxGroup") {
            const currentValues = userData[q.name] || [];
            (q.options || []).forEach((opt) => {
              const div = document.createElement("div");
              div.className = "form-check";

              const input = document.createElement("input");
              input.className = "form-check-input";
              input.type = "checkbox";
              input.name = q.name;
              input.id = `${q.name}_${opt.value}`;
              input.value = opt.value;
              input.checked = currentValues.includes(opt.value);
              input.onclick = () => {
                if (!userData[q.name]) userData[q.name] = [];
                if (input.checked) {
                  userData[q.name].push(opt.value);
                } else {
                  userData[q.name] = userData[q.name].filter((v) => v !== opt.value);
                }
              };

              const label = document.createElement("label");
              label.className = "form-check-label";
              label.htmlFor = `${q.name}_${opt.value}`;
              label.textContent = opt.label;

              div.appendChild(input);
              div.appendChild(label);
              formGroup.appendChild(div);
            });
          }

          cardBody.appendChild(formGroup);
        });

        // Nav buttons
        const navDiv = document.createElement("div");
        navDiv.className = "d-flex justify-content-between";

        // Previous
        if (currentStepIndex > 0) {
          const prevBtn = document.createElement("button");
          prevBtn.className = "btn btn-secondary";
          prevBtn.textContent = "Previous";
          prevBtn.onclick = () => {
            currentStepIndex--;
            renderStep();
          };
          navDiv.appendChild(prevBtn);
        } else {
          const spacer = document.createElement("div");
          navDiv.appendChild(spacer);
        }

        // Reset + Next
        const buttonGroup = document.createElement("div");

        const resetBtn = document.createElement("button");
        resetBtn.className = "btn btn-warning me-2";
        resetBtn.textContent = "Reset";
        resetBtn.onclick = () => {
          if (window.confirm("Are you sure you want to reset the form? All your answers will be lost.")) {
            window.resetForm();
          }
        };
        buttonGroup.appendChild(resetBtn);

        const nextBtn = document.createElement("button");
        nextBtn.className = "btn btn-primary";
        nextBtn.textContent = currentStepIndex === steps.length - 1 ? "Show Results" : "Next";
        nextBtn.onclick = () => {
          if (validateStep(stepData)) {
            // Check prohibited
            if (stepData.id === "step_prohibited") {
              if (
                userData.prohibitedFuncs &&
                userData.prohibitedFuncs.length > 0 &&
                !userData.prohibitedFuncs.includes("none")
              ) {
                if (prohibitedModal) {
                  prohibitedModal.show();
                }
                currentStepIndex = steps.length;
                showResults();
                return;
              }
            }
            // Check excluded
            if (stepData.id === "step_exclusions") {
              const excluded = userData.excludedCats || [];
              if (excluded.includes("military") || excluded.includes("publicAuth3rd")) {
                currentStepIndex = steps.length;
                showResults();
                return;
              }
            }

            currentStepIndex++;
            if (checkOutOfScope()) {
              currentStepIndex = steps.length;
            }
            renderStep();
          } else {
            alert("Please fill out required fields (*) before continuing.");
          }
        };
        buttonGroup.appendChild(nextBtn);

        navDiv.appendChild(buttonGroup);
        cardBody.appendChild(navDiv);
        card.appendChild(cardBody);

        wizard.appendChild(card);

        updateSidebar();

        // Show intro card only on first step
        if (currentStepIndex === 0 && introCard) {
          introCard.classList.remove("hidden");
        } else if (introCard) {
          introCard.classList.add("hidden");
        }
      }

      function skipStep(stepData) {
        if (!userData.entityType) return false;
        if (stepData.id === "step_systemModifications") {
          if (!["deployer", "distributor", "importer"].includes(userData.entityType)) {
            return true;
          }
        }
        if (stepData.id === "step_downstreamModifications") {
          if (userData.entityType !== "provider") {
            return true;
          }
        }
        if (stepData.id === "step_productsSafety") {
          if (userData.entityType !== "productManufacturer") {
            return true;
          }
        }
        return false;
      }

      function validateStep(stepData) {
        for (const q of stepData.questions) {
          if (q.required) {
            if (q.type === "radio") {
              if (!userData[q.name]) return false;
            } else if (q.type === "checkboxGroup") {
              const arr = userData[q.name] || [];
              if (arr.length === 0) return false;
            }
          }
        }
        return true;
      }

      function checkOutOfScope() {
        if (userData.isAiSystem === "no") {
          return true;
        }
        if (
          userData.excludedCats &&
          (userData.excludedCats.includes("military") || userData.excludedCats.includes("publicAuth3rd"))
        ) {
          return true;
        }

        const systemModsNone = userData.systemMods && userData.systemMods.includes("none");
        const downstreamModsNone = userData.downstreamMods && userData.downstreamMods.includes("none");
        const scopeCriteriaNone = userData.scopeCriteria && userData.scopeCriteria.includes("none");

        if ((systemModsNone || downstreamModsNone) && scopeCriteriaNone) {
          return true;
        }

        return false;
      }

      function showResults() {
        const wizardEl = document.getElementById("wizard");
        if (wizardEl) wizardEl.classList.add("hidden");

        const dynamicHeader = document.getElementById("dynamicHeader");
        if (dynamicHeader) {
          dynamicHeader.innerHTML = `
            <h3 class="mb-3">How will the EU AI Act affect my AI system?</h3>
            <p>Please complete this form for each individual AI system used in your organisation.</p>
          `;
        }

        const resultsEl = document.getElementById("results");
        if (resultsEl) resultsEl.classList.remove("hidden");

        const rcEl = document.getElementById("resultsContent");
        if (rcEl) rcEl.innerHTML = "";

        const userSelectionsEl = document.getElementById("userSelections");
        if (userSelectionsEl) userSelectionsEl.innerHTML = generateUserSelections();

        // If out-of-scope
        if (checkOutOfScope()) {
          if (rcEl) {
            rcEl.innerHTML = `
              <div class="alert alert-warning" role="alert">
                <p><strong>Your system is likely <span class="text-danger">Out of Scope</span> for the EU AI Act.</strong></p>
                <p>Please review the relevant sections below for more information:</p>
                <ul>
                  <li><a href="https://artificialintelligenceact.eu/article/2/" target="_blank">Article 2: Scope</a></li>
                  <li><a href="https://artificialintelligenceact.eu/article/3/" target="_blank">Article 3: Definitions</a></li>
                  <li><a href="https://artificialintelligenceact.eu/article/5/" target="_blank">Article 5: Prohibited AI Practices</a></li>
                  <li><a href="https://artificialintelligenceact.eu/article/25/" target="_blank">Article 25: Obligations along the AI value chain</a></li>
                </ul>
                <p><em>We recommend verifying your results with a legal professional to ensure compliance.</em></p>
              </div>
            `;
          }
          return;
        }

        // If specifically excluded
        if (
          userData.excludedCats &&
          (userData.excludedCats.includes("military") || userData.excludedCats.includes("publicAuth3rd"))
        ) {
          if (rcEl) {
            rcEl.innerHTML = `
              <div class="alert alert-info" role="alert">
                <strong>Excluded System:</strong> Your AI system is used exclusively for military purposes 
                or by public authorities in third countries for law enforcement. Such systems are excluded 
                from the EU AI Act obligations.
              </div>
            `;
          }
          return;
        }

        // Otherwise show normal results
        let finalHtml = `<p>Based on your inputs, here are the key considerations regarding your AI system's compliance with the EU AI Act:</p><ul>`;

        if (userData.entityType) {
          finalHtml += `<li><strong>Entity Type:</strong> ${formatEntityType(
            userData.entityType
          )}. 
            Consult your role-specific obligations in the official Act 
            (e.g. <a href="https://artificialintelligenceact.eu/article/16/" target="_blank">Article 16</a> for Providers, 
            <a href="https://artificialintelligenceact.eu/article/26/" target="_blank">Article 26</a> for Deployers, etc.).</li>`;
        }

        if (userData.systemMods && userData.systemMods.length && !userData.systemMods.includes("none")) {
          finalHtml += `<li>You have indicated system modifications (Article 25(1)). 
            This may reclassify you as "provider" for that modified system.</li>`;
        }

        if (userData.downstreamMods && userData.downstreamMods.length && !userData.downstreamMods.includes("none")) {
          finalHtml += `<li>You indicated a downstream party has modified your system (Article 25(2)). 
            They might become the new provider, and you have "handover" obligations (technical docs, assistance, etc.).</li>`;
        }

        if (userData.entityType === "productManufacturer" && userData.aiSafetyCats) {
          if (!userData.aiSafetyCats.includes("none")) {
            finalHtml += `<li>You have AI safety components in categories: ${formatList(userData.aiSafetyCats)}. 
              This may subject you to high-risk or provider obligations under 
              <a href="https://artificialintelligenceact.eu/article/25/" target="_blank">Article 25(3)</a>.</li>`;
          }
        }

        if (userData.scopeCriteria && userData.scopeCriteria.length && !userData.scopeCriteria.includes("none")) {
          finalHtml += `<li>Your system is likely in scope of the EU AI Act (Article 2). 
            You must verify further obligations based on risk, etc.</li>`;
        } else if (userData.scopeCriteria && userData.scopeCriteria.includes("none")) {
          finalHtml += `<li>You indicated no scope criteria. Your system might be out of scope. 
            Review <a href="https://artificialintelligenceact.eu/article/2/" target="_blank">Article 2</a>.</li>`;
        }

        if (userData.isGPAI === "yes") {
          finalHtml += `<li>You indicated it is a <strong>General Purpose AI model</strong>. 
            Obligations in <a href="https://artificialintelligenceact.eu/article/53/" target="_blank">Article 53</a>, 
            plus <a href="https://artificialintelligenceact.eu/article/41/" target="_blank">Article 41</a>, <a href="https://artificialintelligenceact.eu/article/51/" target="_blank">Article 51</a>, 
            <a href="https://artificialintelligenceact.eu/article/52/" target="_blank">Article 52</a>, <a href="https://artificialintelligenceact.eu/article/55/" target="_blank">Article 55</a>, etc. may apply.</li>`;
        }

        if (
          userData.excludedCats &&
          userData.excludedCats.length &&
          !userData.excludedCats.includes("military") &&
          !userData.excludedCats.includes("publicAuth3rd")
        ) {
          finalHtml += `<li>You selected certain exclusions (Article 2). 
            If applicable, some obligations may be exempt based on the selected exclusions.</li>`;
        }

        if (userData.prohibitedFuncs && !userData.prohibitedFuncs.includes("none")) {
          finalHtml += `
            <li>
              <span style="color: red; font-weight: bold;">
                Your system may perform prohibited practices under 
                <a href="https://artificialintelligenceact.eu/article/5/" target="_blank" style="color: red; font-weight: bold;">
                  Article 5
                </a>. 
                This could be subject to bans or highest penalties.
              </span>
            </li>`;
        }

        const { annex1B, annex1A, annex3Cats } = userData;
        if (
          (annex1B && annex1B.length && !annex1B.includes("none")) ||
          (annex1A && annex1A.length && !annex1A.includes("none")) ||
          (annex3Cats && annex3Cats.length && !annex3Cats.includes("none"))
        ) {
          finalHtml += `<li>You indicated high-risk domains (<a href="https://artificialintelligenceact.eu/annex/1/" target="_blank">Annex I</a> or <a href="https://artificialintelligenceact.eu/annex/3/" target="_blank">Annex III</a>). 
            Refer to <a href="https://artificialintelligenceact.eu/article/9/" target="_blank">Article 9</a>, 
            <a href="https://artificialintelligenceact.eu/article/10/" target="_blank">Article 10</a>, 
            <a href="https://artificialintelligenceact.eu/article/11/" target="_blank">Article 11</a>, 
            <a href="https://artificialintelligenceact.eu/article/12/" target="_blank">Article 12</a>, 
            <a href="https://artificialintelligenceact.eu/article/13/" target="_blank">Article 13</a>, 
            <a href="https://artificialintelligenceact.eu/article/14/" target="_blank">Article 14</a>, 
            and <a href="https://artificialintelligenceact.eu/article/15/" target="_blank">Article 15</a>.</li>`;
        }

        if (userData.sigRisk === "yes") {
          finalHtml += `<li>You said the system poses a significant risk of harm. 
            This may reinforce high-risk obligations 
            (see <a href="https://artificialintelligenceact.eu/article/6/#point3" target="_blank">Article 6(3)</a>).</li>`;
        }

        if (userData.transparencyFuncs && !userData.transparencyFuncs.includes("none")) {
          finalHtml += `<li>Your system performs transparency-relevant functions 
            (interacting with people, synthetic content, deep fakes, etc.). 
            You must comply with <a href="https://artificialintelligenceact.eu/article/50/" target="_blank">Article 50</a> obligations.</li>`;
        }

        finalHtml += `</ul>`;

        finalHtml += `
          <p><strong>Next Steps:</strong></p>
          <ul>
            <li>Review specific obligations in <a href="https://artificialintelligenceact.eu/section/3-2/" target="_blank">Chapter III, Section 2</a> for high-risk systems.</li>
            <li>Check <a href="https://artificialintelligenceact.eu/article/16/" target="_blank">Article 16</a> for provider obligations or 
              <a href="https://artificialintelligenceact.eu/article/26/" target="_blank">Article 26</a> for deployer obligations, 
              <a href="https://artificialintelligenceact.eu/article/24/" target="_blank">Article 24</a> for distributor, 
              <a href="https://artificialintelligenceact.eu/article/23/" target="_blank">Article 23</a> for importer, 
              <a href="https://artificialintelligenceact.eu/article/22/" target="_blank">Article 22</a> for authorized reps, etc.</li>
            <li>If you made or plan to make any modifications, see <a href="https://artificialintelligenceact.eu/article/25/" target="_blank">Article 25</a> 
              on responsibilities along the AI value chain.</li>
            <li>If you are out of scope or excluded, ensure to verify with the official text or legal counsel to confirm no obligations apply.</li>
          </ul>
          <p>You may also want to print the screen and save to PDF to preserve your results.</p>
        `;

        if (rcEl) {
          rcEl.innerHTML = finalHtml;
        }
      }

      function formatEntityType(type) {
        const mapping = {
          provider: "Provider",
          deployer: "Deployer",
          distributor: "Distributor",
          importer: "Importer",
          productManufacturer: "Product Manufacturer",
          authorizedRep: "Authorised Representative",
        };
        return mapping[type] || type;
      }

      function formatList(arr) {
        const mapping = {
          machinery: "Machinery",
          toys: "Toys",
          watercraft: "Recreational craft & watercraft",
          lifts: "Lifts & safety components",
          explosiveAtmo: "Equipment in explosive atmospheres",
          radioEquip: "Radio equipment",
          pressureEquip: "Pressure equipment",
          ppe: "Personal protective equipment",
          medicalDevices: "Medical devices",
          inVitroDiag: "In vitro diagnostic medical devices",
          civilAviationSec: "Civil aviation security",
          twoThreeWheel: "Two- or three-wheel vehicles & quadricycles",
          agriForestry: "Agricultural/forestry vehicles",
          marineEquip: "Marine equipment",
          railSystems: "Interoperability of rail systems",
          motorVehicles: "Motor vehicles & trailers",
          civilAviation: "Civil aviation",
          biometrics: "Biometrics",
          criticalInfra: "Critical infrastructure",
          education: "Educational/vocational training",
          employment: "Employment/workers management",
          essentialServices: "Access to essential services/benefits",
          lawEnforcement: "Law enforcement",
          migrationAsylum: "Migration, asylum, border control",
          justice: "Administration of justice/democratic processes",
        };
        return arr.map((item) => mapping[item] || item).join(", ");
      }

      window.resetForm = function resetForm() {
        userData = {};
        currentStepIndex = 0;

        const resultsEl = document.getElementById("results");
        if (resultsEl) resultsEl.classList.add("hidden");

        const wizard = document.getElementById("wizard");
        if (wizard) wizard.classList.remove("hidden");

        const dynamicHeader = document.getElementById("dynamicHeader");
        if (dynamicHeader) {
          dynamicHeader.innerHTML = `
            <h1 class="mb-4">EU AI Act Compliance Checker</h1>
            <div class="card mb-4">
              <div class="card-body">
                <p class="card-text">
                  Complete this form for each individual AI system in your organization. 
                  Since the EU AI Act applies to entities around the world, this tool will help you
                  determine whether your system is subject to the new rules.
                </p>
              </div>
            </div>
            <h3 class="mb-3">How will the EU AI Act affect my AI system?</h3>
            <p>Please complete this form for each individual AI system used in your organisation.</p>
          `;
        }

        renderStep();
        updateSidebar();
      };

      function generateUserSelections() {
        let html = "";
        for (const key in userData) {
          if (Object.prototype.hasOwnProperty.call(userData, key)) {
            const displayName = getDisplayName(key);
            const value = userData[key];
            let displayValue = "";
            if (Array.isArray(value)) {
              displayValue = formatList(value);
            } else {
              displayValue = formatEntityType(value);
            }
            html += `<li><strong>${displayName}:</strong> ${displayValue}</li>`;
          }
        }
        return html;
      }

      function getDisplayName(key) {
        const mapping = {
          isAiSystem: "Is AI System",
          entityType: "Entity Type",
          systemMods: "System Modifications",
          downstreamMods: "Downstream Modifications",
          aiSafetyCats: "AI Safety Categories",
          scopeCriteria: "Scope Criteria",
          isGPAI: "General Purpose AI Model",
          excludedCats: "Excluded Categories",
          prohibitedFuncs: "Prohibited Functions",
          annex1B: "Annex I, Section B",
          annex1A: "Annex I, Section A",
          annex3Cats: "Annex III Categories",
          thirdPartyConf: "Third-Party Conformity Assessment",
          sigRisk: "Significant Risk of Harm",
          transparencyFuncs: "Transparency Functions",
        };
        return mapping[key] || key;
      }

      // Handle the prohibited modal close => show results
      if (prohibitedModalEl) {
        prohibitedModalEl.addEventListener("hidden.bs.modal", function () {
          currentStepIndex = steps.length;
          showResults();
        });
      }

      // Sidebar click
      const sidebarNav = document.getElementById("sidebar-nav");
      if (sidebarNav) {
        sidebarNav.addEventListener("click", function (e) {
          if (e.target && e.target.matches(".nav-link")) {
            e.preventDefault();
            const step = parseInt(e.target.getAttribute("data-step"), 10);
            if (step < currentStepIndex) {
              currentStepIndex = step;
              renderStep();
            }
          }
        });
      }

      // Navbar for small screens
      const navbarNavSmall = document.getElementById("navbarNavSmall");
      if (navbarNavSmall) {
        navbarNavSmall.addEventListener("click", function (e) {
          if (e.target && e.target.matches(".nav-link")) {
            e.preventDefault();
            const step = parseInt(e.target.getAttribute("data-step"), 10);
            if (step < currentStepIndex) {
              currentStepIndex = step;
              renderStep();

              const navbarCollapse = new window.bootstrap.Collapse(
                document.getElementById("navbarNavSmall"),
                { toggle: false }
              );
              navbarCollapse.hide();
            }
          }
        });
      }

      function updateSidebar() {
        const sidebarLinks = document.querySelectorAll("#sidebar-nav .nav-link, #navbarNavSmall .nav-link");
        sidebarLinks.forEach((link) => {
          const step = parseInt(link.getAttribute("data-step"), 10);
          if (step === currentStepIndex) {
            link.classList.add("active");
          } else {
            link.classList.remove("active");
          }
        });
      }

      // Initialize
      renderStep();
    }
  }, []); // End of useEffect

  return (
    <div>
      {/* Load Bootstrap CSS */}
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"
        rel="stylesheet"
      />

      {/* Inline Styles from <style> */}
      <style>
        {`
          /* General Styles */
          body {
            margin: 0;
            padding: 0;
            font-family: Arial, sans-serif;
            background-color: #f5f5f5;
            min-height: 100vh;
          }
          .sidebar {
            background-color: #ffffff;
            border-right: 1px solid #dee2e6;
            padding: 20px;
            position: sticky;
            top: 20px;
            height: fit-content;
          }
          .sidebar h4 {
            text-align: center;
            margin-bottom: 30px;
            color: #343a40;
          }
          .sidebar .nav-link {
            color: #343a40;
            margin-bottom: 10px;
            border-radius: 4px;
            padding: 10px 15px;
            transition: background-color 0.3s, color 0.3s;
          }
          .sidebar .nav-link:hover {
            background-color: #e2e6ea;
            text-decoration: none;
            color: #343a40;
          }
          .sidebar .nav-link.active {
            background-color: #0d6efd;
            color: #fff !important;
            font-weight: bold;
          }
          .main-content {
            background-color: #ffffff;
            border-radius: 8px;
            box-shadow: 0 4px 6px rgba(0,0,0,0.1);
            padding: 20px;
            min-height: 72vh;
            max-width: 1200px;
            margin: 0 auto;
          }
          .card {
            border: none;
            box-shadow: 0 4px 6px rgba(0,0,0,0.1);
          }
          @media (max-width: 992px) {
            .sidebar,
            .navbar {
              display: none !important;
            }
          }
          @media (min-width: 993px) {
            .centered-row-container {
              max-width: 1200px;
              margin: 0 auto;
            }
            .navbar {
              display: none !important;
            }
          }
          .hidden {
            display: none;
          }
          .navigation-buttons button {
            min-width: 100px;
          }
          .disclaimer a {
            text-decoration: none;
            color: #0d6efd;
          }
          .disclaimer a:hover {
            text-decoration: underline;
          }
          .flag {
            color: red;
            font-weight: bold;
            margin-left: 10px;
          }
          .results-summary {
            margin-bottom: 20px;
          }
          .results-summary h4 {
            margin-bottom: 15px;
          }
          .results-summary ul {
            list-style-type: none;
            padding-left: 0;
          }
          .results-summary ul li {
            background: #e9ecef;
            padding: 10px;
            margin-bottom: 5px;
            border-radius: 5px;
          }
          .modal-header {
            background-color: #f8f9fa;
          }
          #excludedAlert {
            margin-top: 20px;
          }
        `}
      </style>

      {/* Main Container Structure (formerly <body> ... ) */}
      <div className="container-fluid">
        <div className="centered-row-container">
          <div className="row g-0 align-items-start">
            {/* Sidebar */}
            <nav className="col-lg-3 d-none d-lg-block sidebar" id="sidebarColumn">
              <h4 className="text-start">Compliance Steps</h4>
              <ul className="nav flex-column" id="sidebar-nav">
                <li className="nav-item">
                  <a className="nav-link active" href="#" data-step="0">
                    AI System Check
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#" data-step="1">
                    Entity Type
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#" data-step="2">
                    System Modifications
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#" data-step="3">
                    Downstream Modifications
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#" data-step="4">
                    Products Safety
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#" data-step="5">
                    Scope
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#" data-step="6">
                    General Purpose AI
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#" data-step="7">
                    Excluded Systems
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#" data-step="8">
                    Prohibited Systems
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#" data-step="9">
                    High-Risk System
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#" data-step="10">
                    Third-Party Conformity
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#" data-step="11">
                    Significant Risk
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#" data-step="12">
                    Transparency
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#" data-step="13">
                    Results
                  </a>
                </li>
              </ul>
            </nav>

            {/* Navbar for Small Screens */}
            <nav className="navbar navbar-expand-lg navbar-light bg-light d-lg-none col-12">
              <div className="container-fluid">
                <a className="navbar-brand" href="#">
                  Compliance Steps
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNavSmall"
                  aria-controls="navbarNavSmall"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavSmall">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <a className="nav-link active" href="#" data-step="0">
                        AI System Check
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#" data-step="1">
                        Entity Type
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#" data-step="2">
                        System Modifications
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#" data-step="3">
                        Downstream Modifications
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#" data-step="4">
                        Products Safety
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#" data-step="5">
                        Scope
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#" data-step="6">
                        General Purpose AI
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#" data-step="7">
                        Excluded Systems
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#" data-step="8">
                        Prohibited Systems
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#" data-step="9">
                        High-Risk System
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#" data-step="10">
                        Third-Party Conformity
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#" data-step="11">
                        Significant Risk
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#" data-step="12">
                        Transparency
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#" data-step="13">
                        Results
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>

            {/* Main Content */}
            <div className="col-lg-9 col-12 main-content">
              <div className="container">
                {/* Dynamic Titles and Instructions */}
                <div id="dynamicHeader" className="mb-4"></div>

                <div className="card mb-4" id="introCard">
                  <div className="card-body">
                    <p className="card-text">
                      Complete this form for each individual AI system in your organization.
                      Since the EU AI Act applies to entities around the world, this tool will help you determine
                      whether your system is subject to the new rules.
                    </p>
                  </div>
                </div>

                <div id="wizard" className="mb-4"></div>

                {/* Results card (hidden by default) */}
                <div id="results" className="card hidden">
                  <div className="card-body">
                    <h2>Your Results</h2>

                    {/* Reset Button on Results Page */}
                    <div className="d-flex justify-content-end mb-3">
                      {/* For React, we call window.resetForm in onClick */}
                      <button className="btn btn-warning" onClick={() => window.resetForm()}>
                        Reset Form
                      </button>
                    </div>

                    {/* Results Summary */}
                    <div className="results-summary">
                      <h4>Your Selections:</h4>
                      <ul id="userSelections"></ul>
                    </div>

                    <div id="resultsContent"></div>

                    <div className="alert alert-secondary disclaimer mt-4" role="alert">
                      <p>
                        <strong>Disclaimer:</strong> The information in this tool is not legal advice.
                        Please consult the{" "}
                        <a href="https://artificialintelligenceact.eu/the-act/" target="_blank">
                          official Act
                        </a>{" "}
                        or a qualified professional.
                      </p>
                    </div>
                  </div>
                </div>

                {/* Prohibited Items Modal */}
                <div
                  className="modal fade"
                  id="prohibitedModal"
                  tabIndex={-1}
                  aria-labelledby="prohibitedModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="prohibitedModalLabel">
                          Prohibited AI Practices
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                        <p>
                          You have selected prohibited AI practices.
                          According to the EU AI Act, your system may be subject to bans or severe penalties.
                        </p>
                        <p>
                          Please review the relevant sections of the Act or consult a qualified professional
                          to ensure compliance.
                        </p>
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Excluded Items Alert */}
                <div id="excludedAlert" className="alert alert-info hidden" role="alert">
                  <strong>Excluded System:</strong> Your AI system is used exclusively for military purposes
                  or by public authorities in third countries for law enforcement.
                  Such systems are excluded from the EU AI Act obligations.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EUAIActChecker;
