import { kthNavbarConfig } from "./kthlaw/kthNavbarConfig";
import KthRoutes from "./kthlaw/KthRoutes";
import KthHeader from "./kthlaw/KthHeader";
import KthFooter from "./kthlaw/KthFooter";
import Worksheet from "./common/Worksheet";
import PatanalAiRoutes from "./patanalai/PatanalAiRoutes";
// import PatanalaiWorksheet from "./patanalai/utils/PatanalaiWorksheet";
import { patanalAiNavbarConfig } from "./patanalai/patanalAiNavbarConfig";
import PatentAnalyticsRoutes from "./patentanalytics/PatentAnalyticsRoutes";
import { patentAnalyticsNavbarConfig } from "./patentanalytics/patentAnalyticsNavbarConfig";
import PatentAnalyticsHeader from "./patentanalytics/PatentAnalyticsHeader";
import PatentAnalyticsFooter from "./patentanalytics/PatentAnalyticsFooter";
import PartsFinderRoutes from "./parts-finder/PartsFinderRoutes";
import { partsFinderNavbarConfig } from "./parts-finder/partsFinderNavbarConfig";
import LpimRoutes from "./lpim/LpimRoutes";
import { lpimNavbarConfig } from "./lpim/lpimNavbarConfig";
import domains from "../domainCfg";
import BaseComponentSaas from "./BaseComponentSaas";
import BaseComponentWeb from "./BaseComponentWeb";

const baseConfigs = {
  // ----------- WWW.KTHLAW.COM -----------
  [domains.kthlaw]: {
    BaseComponent: BaseComponentWeb,
    HeaderComponent: KthHeader,
    WorksheetComponent: Worksheet,
    worksheetTabs: [
      "patent-enforcement",
      "ai-compliance",
      "ai-legal-terms",
      "licensing",
    ],
    worksheetUserAuth: false,
    siteBranding: {
      logo: "Kth-logo-pe-250x100.png",
      priColor: "#96858F",
      priHoverColor: "#7A4E78",
      secColor: "#EE86B7",
      tertColor: "#4ABDAC",
      tertHoverColor: "#2D93AD",
      quadColor: "#9099A2",
      quadHoverColor: "#7A828B",
      sextColor: "#99D3DF",
      sextHoverColor: "#77C2CF",
      blackishColor: "#343a40",
      blackishHoverColor: "#495057",
      buttColor: "#4ABDAC",
      buttHoverColor: "#66c9b9",
      headerTextColor: "#4ABDAC",
      worksheetButtonColor: "#96858F",
    },
    faviconFolder: "kthlaw-favicon",
    navbarConfig: kthNavbarConfig,
    RoutesComponent: KthRoutes,
    welcomeText: "Welcome to the Patents, AI & Wireless Telecom Law Firm!",

    FooterComponent: KthFooter,
    googleAnalyticsId: "G-29Y2FFYYNM",
    cookieConsentScript:
      "https://cdn-cookieyes.com/client_data/fc8bcb1e26bb6f2428089a69/script.js",
  },
  // ----------- WWW.PATENTANALYTICS.COM -----------
  [domains.patentanalytics]: {
    BaseComponent: BaseComponentWeb,
    siteBranding: {
      // logo: "patanal-logo-204x64.png",
      logo: "patanal-logo-v2-204x64.png",
      priColor: "#94618E",
      secColor: "#9099A2",
      tertColor: "#88BBD6",
      buttColor: "#88BBD6",
      buttHoverColor: "#71A5C7",
      headerTextColor: "#94618E",
    },
    faviconFolder: "patentanalytics-favicon",
    navbarConfig: patentAnalyticsNavbarConfig,
    RoutesComponent: PatentAnalyticsRoutes,
    welcomeText: "Welcome to Patent Analytics ...",
    footerText: "Patent Analytics, Inc. All rights reserved.",
    HeaderComponent: PatentAnalyticsHeader,
    FooterComponent: PatentAnalyticsFooter,
    googleAnalyticsId: "G-STDCPX1PB3",
  },
  // ----------- WWW.PATANAL.AI ------------------
  [domains.patanalai]: {
    BaseComponent: BaseComponentSaas,
    WorksheetComponent: Worksheet,
    worksheetTabs: ["summary", "patents", "devices", "licensing"],
    worksheetUserAuth: true,
    siteBranding: {
      logo: "patanal-logo-rev-1l-204x64.png",
      appBarColor: "#94618E",
      drawerColor: "#94618E",
      worksheetButtonColor: "#fff",
    },
    faviconFolder: "patanalai-favicon",
    navbarConfig: patanalAiNavbarConfig,
    RoutesComponent: PatanalAiRoutes,
    welcomeText: "Patents AI",
    footerText:
      "Patanal is a registered trademark of <a href='https://www.patentanalytics.com' target='_blank'>Patent Analytics, Inc</a> in the United States. All rights reserved.",
    footerLegalLinks:
      "<a href='/aboutus'>About Us</a> | <a href='https://www.patentanalytics.com/terms-of-service' target='_blank'>Terms of Service</a> | <a href='https://www.patentanalytics.com/privacy-policy' target='_blank'>Privacy Policy</a> | <a href='https://www.patentanalytics.com/dmca-notice' target='_blank'>DMCA Notice</a> | <a href='/contact'>Contact Us</a>",
    googleAnalyticsId: "G-7N4CK7K6EK",
  },
  // ----------- PARTS-FINDER.RFWEL.COM -----------
  [domains.partsFinder]: {
    BaseComponent: BaseComponentSaas,
    siteBranding: {
      // logo: "rfwel_logo_204x64.jpg",
      logo: "",
      appBarColor: "#CC6633",
      drawerColor: "#CC6633",
      priColor: "#CC6633",
      priHoverColor: "#b3542a",
    },
    faviconFolder: "lpim-favicon",
    navbarConfig: partsFinderNavbarConfig,
    RoutesComponent: PartsFinderRoutes,
    welcomeText: "Parts Finder Webtool",
    footerText: "Rfwel Engineering, LLC. All rights reserved.",
    footerLegalLinks: "",
    googleAnalyticsId: "G-9SSNVJM5FE",
  },
  // ----------- LPIM.RFWEL.COM -------------------
  [domains.lpim]: {
    BaseComponent: BaseComponentSaas,
    siteBranding: {
      // logo: "rfwel_logo_204x64.jpg",
      logo: "",
      appBarColor: "#CC6633",
      drawerColor: "#CC6633",
      priColor: "#CC6633",
      priHoverColor: "#b3542a",
    },
    faviconFolder: "lpim-favicon",
    navbarConfig: lpimNavbarConfig,
    RoutesComponent: LpimRoutes,
    welcomeText: "LPIM Webtool",
    footerText: "Rfwel Engineering, LLC. All rights reserved.",
    footerLegalLinks: "",
  },
};

export default baseConfigs;
