import _ from "lodash";
import { formatDistanceToNow } from "date-fns";
import cost from "../utils/cost";
import { getBigcCost } from "../utils/cost";
import profit from "../utils/priceMargin";
import { getBigcPrice } from "../utils/priceMargin";
import * as CONFIG from "../utils/Global-Labels-Links-Vars";
import { formatDate, isDateFuture, isDateTAgo } from "../utils/formatDateTime";
import { getPriceLocks } from "../utils/priceLocks";
import { getInventoryLocks } from "../utils/inventoryLocks";
import { buildChannelLinks } from "./buildChannelLink";

//**********************************************************************************************************************************/
// BUILD CHANNEL PRODUCTS
//**********************************************************************************************************************************/

export function buildChannelProducts(product) {
  // -------------------------------------------------------------------------------
  // Build/Map the Channel Products
  // -------------------------------------------------------------------------------
  // Get number of products, if have just product then no need to map (no array)

  const numProds = product.length;
  let productCloned;
  if (numProds >= 1) {
    // Clone product so we use the same array in both cases
    // The below spread does shallow copy - copies by reference (does not work for nested objects)
    // productCloned = [...product];

    // Use JSON stringify for deep copy (see -> https://www.freecodecamp.org/news/how-to-clone-an-array-in-javascript-1d3183468f6a/)
    productCloned = JSON.parse(JSON.stringify(product));
  } else {
    // Convert the single product object into an array so can use the same map object below
    //productCloned.push(pLocksroduct);
    productCloned = [product];
  }

  // Map elements
  const channelProducts = productCloned.map((p) => {
    // Get the BigC Price for the product instance
    // Other channel prices fetched below
    const priceBigc = getBigcPrice(p);

    // Get the Cost
    // const costObj = cost.get(p);
    // const prodCost = _.get(costObj, "cost");
    const prodCost = _.get(p, "product_cost");
    const costBigc = getBigcCost(prodCost, p); // BigC cost could have MOQ

    // Get the MOQ for BigC to normalize when comparing with channel price
    const moqBigc = _.get(p, "inventory_for_product.moq_bigc");
    const priceBigcUnit =
      moqBigc > 1 ? Number((priceBigc / moqBigc).toFixed(2)) : priceBigc;

    // Is item used
    const isUsed = _.get(p, "item_condition") === "Used";

    // Is item on clearance. Note that if item is used, it is tantamount to being on clearance
    const onClearance = _.get(p, "prices_for_product.on_clearance") || isUsed;

    // Is item price locked for specific channels
    const prodPriceLocks = getPriceLocks(p);

    // Expand out to create array of channel products
    let channProd = [
      {
        id: p.id,
        sku: p.sku,
        category: p.category,
        channel: CONFIG.BIGC,
        listat: p.listing_status_bigc,
        channid: p.pid_bigc,
        price: priceBigc,
        cost: costBigc,
        price_bigc: priceBigc,
        price_bigc_unit: priceBigcUnit,
        cost_bigc: costBigc,
        on_clearance: onClearance,
        qty: _.get(p, "inventory_for_product.qty_bigc"),
        handling: _.get(p, "inventory_for_product.availability_bigc"),
        moq: moqBigc,
        price_locked: prodPriceLocks.price_lock_bigc,
        price_lock_release: prodPriceLocks.price_lock_release_bigc,
      },
      {
        id: p.id,
        sku: p.sku,
        category: p.category,
        channel: CONFIG.AMZ_SELLER_US,
        listat: p.listing_status_amz_seller_us,
        channid: p.pid_amz,
        price: _.get(p, "prices_for_product.price_amz_seller_us"),
        cost: prodCost,
        price_bigc: priceBigc,
        price_bigc_unit: priceBigcUnit,
        cost_bigc: costBigc,
        on_clearance: onClearance,
        qty: _.get(p, "inventory_for_product.qty_amz_seller_us"),
        handling: _.get(p, "inventory_for_product.handling_amz"),
        moq: 1,
        price_locked: prodPriceLocks.price_lock_amz_seller_us,
        price_lock_release: prodPriceLocks.price_lock_release_amz_seller_us,
      },
      {
        id: p.id,
        sku: p.sku,
        category: p.category,
        channel: CONFIG.AMZ_SELLER_US_B2B,
        listat: p.listing_status_amz_seller_us,
        channid: p.pid_amz,
        price: _.get(p, "prices_for_product.price_amz_seller_us_b2b"),
        cost: prodCost,
        price_bigc: priceBigc,
        price_bigc_unit: priceBigcUnit,
        cost_bigc: costBigc,
        on_clearance: onClearance,
        qty: _.get(p, "inventory_for_product.qty_amz_seller_us"),
        handling: _.get(p, "inventory_for_product.handling_amz"),
        moq: 1,
        price_locked: prodPriceLocks.price_lock_amz_seller_us,
        price_lock_release: prodPriceLocks.price_lock_release_amz_seller_us,
      },
      {
        id: p.id,
        sku: p.sku,
        category: p.category,
        channel: CONFIG.AMZ_FBA_US,
        listat: p.listing_status_amz_fba_us,
        channid: p.pid_amz,
        price: _.get(p, "prices_for_product.price_amz_fba_us"),
        cost: prodCost,
        price_bigc: priceBigc,
        price_bigc_unit: priceBigcUnit,
        cost_bigc: costBigc,
        on_clearance: onClearance,
        qty: _.get(p, "inventory_for_product.qty_amz_fba_shippable"),
        handling: "--",
        moq: 1,
        price_locked: prodPriceLocks.price_lock_amz_fba_us,
        price_lock_release: prodPriceLocks.price_lock_release_amz_fba_us,
      },
      {
        id: p.id,
        sku: p.sku,
        category: p.category,
        channel: CONFIG.AMZ_FBA_US_B2B,
        listat: p.listing_status_amz_fba_us,
        channid: p.pid_amz,
        price: _.get(p, "prices_for_product.price_amz_fba_us_b2b"),
        cost: prodCost,
        price_bigc: priceBigc,
        price_bigc_unit: priceBigcUnit,
        cost_bigc: costBigc,
        on_clearance: onClearance,
        qty: _.get(p, "inventory_for_product.qty_amz_fba_shippable"),
        handling: "--",
        moq: 1,
        price_locked: prodPriceLocks.price_lock_amz_fba_us,
        price_lock_release: prodPriceLocks.price_lock_release_amz_fba_us,
      },
      {
        id: p.id,
        sku: p.sku,
        category: p.category,
        channel: CONFIG.AMZ_SELLER_CA,
        listat: p.listing_status_amz_seller_ca,
        channid: p.pid_amz,
        price: _.get(p, "prices_for_product.price_amz_seller_ca_usd"),
        cost: prodCost,
        price_bigc: priceBigc,
        price_bigc_unit: priceBigcUnit,
        cost_bigc: costBigc,
        on_clearance: onClearance,
        qty: _.get(p, "inventory_for_product.qty_amz_seller_ca"),
        handling: _.get(p, "inventory_for_product.handling_amz"),
        moq: 1,
        price_locked: prodPriceLocks.price_lock_amz_seller_ca,
        price_lock_release: prodPriceLocks.price_lock_release_amz_seller_ca,
      },
      {
        id: p.id,
        sku: p.sku,
        category: p.category,
        channel: CONFIG.EBAY,
        listat: p.listing_status_ebay,
        channid: p.pid_ebay,
        price: _.get(p, "prices_for_product.price_ebay"),
        cost: prodCost,
        price_bigc: priceBigc,
        price_bigc_unit: priceBigcUnit,
        cost_bigc: costBigc,
        on_clearance: onClearance,
        qty: _.get(p, "inventory_for_product.qty_ebay"),
        handling: _.get(p, "inventory_for_product.handling_ebay"),
        moq: 1,
        price_locked: prodPriceLocks.price_lock_ebay,
        price_lock_release: prodPriceLocks.price_lock_release_ebay,
      },
      {
        id: p.id,
        sku: p.sku,
        category: p.category,
        channel: CONFIG.WMT,
        listat: p.listing_status_wmt,
        channid: p.pid_wmt,
        price: _.get(p, "prices_for_product.price_wmt"),
        cost: prodCost,
        price_bigc: priceBigc,
        price_bigc_unit: priceBigcUnit,
        cost_bigc: costBigc,
        on_clearance: onClearance,
        qty: _.get(p, "inventory_for_product.qty_wmt"),
        handling: _.get(p, "inventory_for_product.handling_wmt"),
        moq: 1,
        price_locked: prodPriceLocks.price_lock_wmt,
        price_lock_release: prodPriceLocks.price_lock_release_wmt,
      },
      {
        id: p.id,
        sku: p.sku,
        category: p.category,
        channel: CONFIG.NE,
        listat: p.listing_status_newegg,
        channid: p.pid_ne,
        price: _.get(p, "prices_for_product.price_ne"),
        cost: prodCost,
        price_bigc: priceBigc,
        price_bigc_unit: priceBigcUnit,
        cost_bigc: costBigc,
        on_clearance: onClearance,
        qty: _.get(p, "inventory_for_product.qty_ne"),
        handling: "--",
        moq: 1,
        price_locked: prodPriceLocks.price_lock_newegg,
        price_lock_release: prodPriceLocks.price_lock_release_newegg,
      },
      {
        id: p.id,
        sku: p.sku,
        category: p.category,
        channel: CONFIG.NE_BIZ,
        listat: p.listing_status_newegg_biz,
        channid: p.pid_nebiz,
        price: _.get(p, "prices_for_product.price_nebiz"),
        cost: prodCost,
        price_bigc: priceBigc,
        price_bigc_unit: priceBigcUnit,
        cost_bigc: costBigc,
        on_clearance: onClearance,
        qty: _.get(p, "inventory_for_product.qty_nebiz"),
        handling: "--",
        moq: 1,
        price_locked: prodPriceLocks.price_lock_newegg_biz,
        price_lock_release: prodPriceLocks.price_lock_release_newegg_biz,
      },
    ];

    // Run through created array of channel products to update cost and margin
    // @TODO --> is there a way to do it above withouth relooping with foreach
    channProd.forEach((cp) => {
      // ------------------------------------------
      // Price/Margin per Channel
      // ------------------------------------------
      if (cp.price && cp.cost) {
        const margin = profit.get(cp.channel, cp.price, cp.cost);
        const prodMarginAbs = margin.margin;
        const prodMarginPercent = margin.margin_percent;

        //if (prodMargin !== "-unavailable-") {
        if (prodMarginAbs && prodMarginPercent) {
          cp["margin"] = prodMarginAbs;
          cp["margin_percent"] = prodMarginPercent;

          // If this is BigC, set bigc_margin attribute here (else set later)
          if (cp.channel === CONFIG.BIGC) {
            cp["margin_percent_bigc"] = prodMarginPercent;
          }

          // Set Price Action for calculated margin
          if (
            prodMarginPercent > CONFIG.MARGIN_HIGH &&
            prodMarginAbs > CONFIG.MARGIN_HIGH_ABS &&
            cp.price > CONFIG.MINPRICE_FOR_MARGINHIGH
          ) {
            cp["price_action"] = CONFIG.MARGIN_HIGH_STR;
          } else {
            if (
              prodMarginPercent < CONFIG.MARGIN_LOW ||
              prodMarginAbs < CONFIG.MARGIN_LOW_ABS
            ) {
              if (onClearance) {
                cp["price_action"] = CONFIG.ON_CLEARANCE_STR;
              } else {
                cp["price_action"] = CONFIG.MARGIN_LOW_STR;
              }
            } else {
              cp["price_action"] = CONFIG.MARGIN_OK_STR;
            }
          }
        } else {
          // Have Price/Cost and returned null
          //cp["margin_percent"] = CONFIG.UNK_STR;
          cp["margin"] = null;
          cp["margin_percent"] = null;
          cp["price_action"] = CONFIG.UNK_STR;
        }
      } else {
        // Dont have price and/or cost
        //cp["margin_percent"] = CONFIG.UNK_STR;
        cp["margin"] = null;
        cp["margin_percent"] = null;
        cp["price_action"] = CONFIG.UNK_STR;
      }
      // ------------------------------------------
      // Channel Price above Bigc Price
      // ------------------------------------------

      if (cp.price && cp.price_bigc_unit) {
        const percentAboveBigC =
          (100 * (cp.price - cp.price_bigc_unit)) / cp.price_bigc_unit;
        cp["percent_above_bigc"] = Number(percentAboveBigC.toFixed(1));
      } else {
        cp["percent_above_bigc"] = 0;
      }
      // ------------------------------------------
      // BigC Margin to add to other Channel objects
      // ------------------------------------------
      // If this is BigC, set bigc_margin attribute here (else set later)
      if (cp.channel !== CONFIG.BIGC && cp.price_bigc && cp.cost_bigc) {
        const bigcMargin = profit.get(CONFIG.BIGC, cp.price_bigc, cp.cost_bigc);
        cp["margin_percent_bigc"] = bigcMargin.margin_percent;
      }
      // ------------------------------------------------
      // Blank price, qty, margin, margin% if not listed
      // ------------------------------------------------
      if (cp.listat !== "Listed") {
        cp["price"] = null;
        cp["qty"] = null;
        cp["handling"] = null;
        cp["margin"] = null;
        cp["margin_percent"] = null;
      }
    });
    // Return from product.map()
    return channProd;
  });

  // Return from function buildChannelProducts
  //console.log(`channelProducts at buildChannelProducts Func-->`);
  //console.log(channelProducts);
  // if had more than one products return channelProducts, else just return first item
  if (numProds >= 1) {
    return channelProducts;
  }
  return channelProducts[0];
}

//**********************************************************************************************************************************/
// MAP CHANNEL PRODUCTS
//**********************************************************************************************************************************/
// convert from:
/*
[
[ {id: 8, sku: '12-101', channel: 'BIGC', price_action: 'Margin High', …},
  {id: 8, sku: '12-101', channel: 'AMZ_SELLER_US', price_action: 'Margin OK', …},
  {id: 8, sku: '12-101', channel: 'AMZ-FBA-US', price_action: 'Margin Low', …},
]
[ {id: 9, sku: '12-104', channel: 'BIGC', price_action: '--unavailable--', …},
  {id: 9, sku: '12-104', channel: 'AMZ_SELLER_US', price_action: 'Margin OK', …},
  {id: 9, sku: '12-104', channel: 'AMZ-FBA-US', price_action: 'Margin Low', …},
]
]
*/
// To:
/*
[ {id: 8, sku: '12-101', margin_stat_bigc: 'Margin High', marging_stat_amz_seller_us: 'Margin OK', margin_stat_amz_fba_us: 'Margin Low', …},
  {id: 9, sku: '12-104', margin_stat_bigc: '--unavailable--', marging_stat_amz_seller_us: 'Margin OK', margin_stat_amz_fba_us: 'Margin Low', …},
]
*/

export function mapChannelProducts(channProds) {
  let mappedChannProds = channProds.map((cp) => {
    let properties = {
      id: "tbd",
      sku: "tbd",
      compe_stat: "tbd",
      list_stat_bigc: "tbd",
      list_stat_amz_seller_us: "tbd",
      list_stat_amz_fba_us: "tbd",
      list_stat_amz_seller_ca: "tbd",
      list_stat_ebay: "tbd",
      list_stat_wmt: "tbd",
      list_stat_ne: "tbd",
      list_stat_nebiz: "tbd",
      margin_stat_bigc: "tbd",
      margin_stat_amz_seller_us: "tbd",
      margin_stat_amz_fba_us: "tbd",
      margin_stat_amz_seller_ca: "tbd",
      margin_stat_ebay: "tbd",
      margin_stat_wmt: "tbd",
      margin_stat_ne: "tbd",
      margin_stat_nebiz: "tbd",
    };

    let obj = null;

    // Get BigC Object
    obj = _.find(cp, { channel: CONFIG.BIGC });

    // Use the BigC object for all the SKUs and IDs
    properties["id"] = _.get(obj, "id");
    properties["sku"] = _.get(obj, "sku");
    properties["list_stat_bigc"] = _.get(obj, "listat");
    properties["margin_stat_bigc"] = _.get(obj, "price_action");

    // Get AMZ Seller Object
    obj = _.find(cp, { channel: CONFIG.AMZ_SELLER_US });
    properties["list_stat_amz_seller_us"] = _.get(obj, "listat");
    properties["margin_stat_amz_seller_us"] = _.get(obj, "price_action");

    // Get AMZ FBA Object
    obj = _.find(cp, { channel: CONFIG.AMZ_FBA_US });
    properties["list_stat_amz_fba_us"] = _.get(obj, "listat");
    properties["margin_stat_amz_fba_us"] = _.get(obj, "price_action");

    // Get AMZ CA Object
    obj = _.find(cp, { channel: CONFIG.AMZ_SELLER_CA });
    properties["list_stat_amz_seller_ca"] = _.get(obj, "listat");
    properties["margin_stat_amz_seller_ca"] = _.get(obj, "price_action");

    // Get EBAY Object
    obj = _.find(cp, { channel: CONFIG.EBAY });
    properties["list_stat_ebay"] = _.get(obj, "listat");
    properties["margin_stat_ebay"] = _.get(obj, "price_action");

    // Get WMT Object
    obj = _.find(cp, { channel: CONFIG.WMT });
    properties["list_stat_wmt"] = _.get(obj, "listat");
    properties["margin_stat_wmt"] = _.get(obj, "price_action");

    // Get NE Object
    obj = _.find(cp, { channel: CONFIG.NE });
    properties["list_stat_ne"] = _.get(obj, "listat");
    properties["margin_stat_ne"] = _.get(obj, "price_action");

    // Get NE-BIZ Object
    obj = _.find(cp, { channel: CONFIG.NE_BIZ });
    properties["list_stat_nebiz"] = _.get(obj, "listat");
    properties["margin_stat_nebiz"] = _.get(obj, "price_action");

    return properties;
  });

  //console.log(`mappedChannProds -->`);
  //console.log(mappedChannProds);
  return mappedChannProds;
}

//**********************************************************************************************************************************/
// Ceck if Item is Listed
//**********************************************************************************************************************************/
// Given product object and channel, determine if item is listed and the pid
export function is_listed(channel, prodObj) {
  let listed = false;
  let pid = null;
  let matchedChannel = "";

  // Check if channel exists, if it does, use that.
  // Use Lodash has method to see if channel is in a direct path
  matchedChannel = _.get(prodObj, "channel");

  // If channel exists, extract listing status, and ID for that channel
  if (matchedChannel !== undefined) {
    //-----------------------------------------------------------------------------------------
    // CASE 1: Product Object is per Channel
    //------------------------------------------------------------------------------------------
    listed = _.get(prodObj, "listat") === CONFIG.lbl_LISTING_ACTIVE;
    pid = _.get(prodObj, "channid");
  } else {
    //---------------------------------------------------------------------------------------------
    // CASE 2: Product Object is per SKU
    //---------------------------------------------------------------------------------------------
    // Extract the listing status based on channel
    //matchedChannel = channel;
    switch (channel) {
      case CONFIG.BIGC:
        listed =
          _.get(prodObj, "listing_status_bigc") === CONFIG.lbl_LISTING_ACTIVE;
        pid = _.get(prodObj, "pid_bigc");
        break;
      case CONFIG.AMZ: // AMZ defaults to AMZ_SELLER_US
        listed =
          _.get(prodObj, "listing_status_amz_seller_us") ===
          CONFIG.lbl_LISTING_ACTIVE;
        pid = _.get(prodObj, "pid_amz");
        break;
      case CONFIG.AMZ_SELLER_US:
        listed =
          _.get(prodObj, "listing_status_amz_seller_us") ===
          CONFIG.lbl_LISTING_ACTIVE;
        pid = _.get(prodObj, "pid_amz");
        break;
      case CONFIG.AMZ_FBA_US:
        listed =
          _.get(prodObj, "listing_status_amz_fba_us") ===
          CONFIG.lbl_LISTING_ACTIVE;
        pid = _.get(prodObj, "pid_amz");
        break;
      case CONFIG.AMZ_SELLER_CA:
        listed =
          _.get(prodObj, "listing_status_amz_seller_ca") ===
          CONFIG.lbl_LISTING_ACTIVE;
        pid = _.get(prodObj, "pid_amz");
        break;
      case CONFIG.EBAY:
        listed =
          _.get(prodObj, "listing_status_ebay") === CONFIG.lbl_LISTING_ACTIVE;
        pid = _.get(prodObj, "pid_ebay");
        break;
      case CONFIG.WMT:
        listed =
          _.get(prodObj, "listing_status_wmt") === CONFIG.lbl_LISTING_ACTIVE;
        pid = _.get(prodObj, "pid_wmt");
        break;
      case CONFIG.NE:
        listed =
          _.get(prodObj, "listing_status_newegg") === CONFIG.lbl_LISTING_ACTIVE;
        pid = _.get(prodObj, "pid_ne");
        break;
      case CONFIG.NE_BIZ:
        listed =
          _.get(prodObj, "listing_status_newegg_biz") ===
          CONFIG.lbl_LISTING_ACTIVE;
        pid = _.get(prodObj, "pid_nebiz");
        break;
    }
  }

  return { channel: matchedChannel, listed: listed, pid: pid };
}

//**********************************************************************************************************************************/
// Extract Margins Meeting Certain Criteria
//**********************************************************************************************************************************/
// Input :
/*
[
[ {id: 8, sku: '12-101', channel: 'BIGC', price_action: 'Margin High', …},
  {id: 8, sku: '12-101', channel: 'AMZ_SELLER_US', price_action: 'Margin OK', …},
  {id: 8, sku: '12-101', channel: 'AMZ-FBA-US', price_action: 'Margin Low', …},
  ...
]
[ {id: 9, sku: '12-104', channel: 'BIGC', price_action: '--unavailable--', …},
  {id: 9, sku: '12-104', channel: 'AMZ_SELLER_US', price_action: 'Margin OK', …},
  {id: 9, sku: '12-104', channel: 'AMZ-FBA-US', price_action: 'Margin Low', …},
  ...
]
]
*/
export function getFilteredMargins({
  products,
  marginThreshold,
  marginLimit,
  compeThreshold,
  skusToGet,
  clearanceHide,
  priceLockedHide,
}) {
  // Flatten internal arrays --> https://lodash.com/docs/4.17.15#flatten (Flattens array a single level deep.)
  const flattenedProds = _.flatten(products);

  //console.log(`flattenedProds -->`);
  //console.log(flattenedProds);

  //----------------------------------------
  // Filter out clearance items
  //----------------------------------------
  const clearanceFilteredArray = _.filter(flattenedProds, function (obj) {
    // Hide if item is on clearance and have HIDE_CLEARANCE option selected
    const hideClearanceObj =
      clearanceHide === "HIDE_CLEARANCE" && obj.on_clearance;
    if (marginLimit === "<THRESH" && !hideClearanceObj)
      // Return objects with margin% below threshold set OR if absolute margin below min absolute margin (e.g., <$6)
      return (
        obj.margin_percent &&
        (obj.margin_percent < marginThreshold ||
          obj.margin < CONFIG.MARGIN_LOW_ABS)
      );
    if (marginLimit === ">THRESH" && !hideClearanceObj)
      // Return objects with margin% above threshold set AND if absolute margin not at least above high absolute (e.g., >$15)
      return (
        obj.margin_percent &&
        obj.margin_percent > marginThreshold &&
        obj.margin > CONFIG.MARGIN_HIGH_ABS
      );
  });

  //----------------------------------------
  // Filter out price-locked items
  //----------------------------------------
  const priceLockFilteredArray = _.filter(
    clearanceFilteredArray,
    function (obj) {
      // Hide if item is pricelocked and have HIDE_PRICELOCKED option selected
      // Consider date-fns isFuture here --> https://date-fns.org/v2.29.3/docs/isFuture
      const hidePriceLockObj =
        priceLockedHide === "HIDE_PRICELOCKED" &&
        obj.price_locked &&
        isDateFuture(obj.price_lock_release);
      if (marginLimit === "<THRESH" && !hidePriceLockObj)
        return (
          obj.margin_percent &&
          (obj.margin_percent < marginThreshold ||
            obj.margin < CONFIG.MARGIN_LOW_ABS)
        );
      if (marginLimit === ">THRESH" && !hidePriceLockObj)
        return (
          obj.margin_percent &&
          obj.margin_percent > marginThreshold &&
          obj.margin > CONFIG.MARGIN_LOW_ABS &&
          obj.price > CONFIG.MINPRICE_FOR_MARGINHIGH
        );
    }
  );

  //----------------------------------------
  // Sort on THRESH
  //----------------------------------------
  // sort based on <THRESH OR >THRESH and return first skusToGet items in list
  // see -> https://masteringjs.io/tutorials/lodash/sortby
  let iteratees;
  if (marginLimit === "<THRESH") {
    iteratees = (obj) => obj.margin_percent;
  } else {
    iteratees = (obj) => -obj.margin_percent;
  }

  const sortedArray = _.sortBy(priceLockFilteredArray, iteratees);

  // Return first skusToGet items in sorted array
  // see -> https://www.geeksforgeeks.org/lodash-_-take-method/
  const limitedArray = _.take(sortedArray, skusToGet);

  // Remove duplicates where SKU and CHANNEL are the same
  let uniqueProducts = [];
  let seenEntries = new Set();

  limitedArray.forEach((entry) => {
    let descriptor = `${entry.sku}|${entry.channel}`; // create a unique descriptor for each object

    if (!seenEntries.has(descriptor)) {
      uniqueProducts.push(entry);
      seenEntries.add(descriptor);
    }
  });

  return uniqueProducts;
}

//**********************************************************************************************************************************/
// Get Channel Prices below BigC or X% Above BigC
//**********************************************************************************************************************************/

export function getPriceRelBigc({
  products,
  thresholdAboveBigc,
  analysisType,
  skusToGet,
  clearanceHide,
  priceLockedHide,
  outOfStockHide,
}) {
  // Flatten internal arrays --> https://lodash.com/docs/4.17.15#flatten (Flattens array a single level deep.)
  const flattenedProds = _.flatten(products);

  //----------------------------------------
  // Filter out clearance & Price-locked items
  //----------------------------------------
  const filteredArray = _.filter(flattenedProds, function (obj) {
    // Hide if item is on clearance and have HIDE_CLEARANCE option selected
    const hideClearanceObj =
      clearanceHide === "HIDE_CLEARANCE" && obj.on_clearance;
    // Hide if item is pricelocked and have HIDE_PRICELOCKED option selected
    const hidePriceLockObj =
      priceLockedHide === "HIDE_PRICELOCKED" &&
      obj.price_locked &&
      isDateFuture(obj.price_lock_release);
    // Hide if item is out of stock in the listing channel and HIDE_OUT_OF_STOCK option is selected
    const hideOutOfStockObj =
      outOfStockHide === "HIDE_OUT_OF_STOCK" && !(obj.qty >= 1);
    // Get channel price and Bigc price. For BigC, if have an MOQ get Unit price
    const channel_price = obj.price;
    const bigc_price = obj.price_bigc_unit;

    if (
      analysisType === "<BIGC" &&
      !hideClearanceObj &&
      !hidePriceLockObj &&
      !hideOutOfStockObj
    )
      return channel_price && channel_price < bigc_price;
    if (
      analysisType === ">BIGC" &&
      !hideClearanceObj &&
      !hidePriceLockObj &&
      !hideOutOfStockObj
    )
      return (
        obj.percent_above_bigc &&
        obj.percent_above_bigc > thresholdAboveBigc &&
        obj.margin_percent > obj.margin_percent_bigc &&
        obj.margin_percent - obj.margin_percent_bigc >
          CONFIG.THRESHOLD_MARGIN_ABOVE_BIGC
      );
  });

  //----------------------------------------
  // Sort on Analysis Type
  //----------------------------------------
  // sort based on <BIGC OR >BIGC and return first skusToGet items in list
  // see -> https://masteringjs.io/tutorials/lodash/sortby
  let iteratees;
  if (analysisType === "<BIGC") {
    iteratees = (obj) => obj.percent_above_bigc;
  } else {
    iteratees = (obj) => -obj.percent_above_bigc;
  }

  const sortedArray = _.sortBy(filteredArray, iteratees);

  // Return first skusToGet items in sorted array
  // see -> https://www.geeksforgeeks.org/lodash-_-take-method/
  const limitedArray = _.take(sortedArray, skusToGet);

  // Remove duplicates where SKU and CHANNEL are the same
  let uniqueProducts = [];
  let seenEntries = new Set();

  limitedArray.forEach((entry) => {
    let descriptor = `${entry.sku}|${entry.channel}`; // create a unique descriptor for each object

    if (!seenEntries.has(descriptor)) {
      uniqueProducts.push(entry);
      seenEntries.add(descriptor);
    }
  });

  return uniqueProducts;
}

//**********************************************************************************************************************************/
// BUILD COMPE DETAIL PRODUCT
//**********************************************************************************************************************************/

export function buildCompeDetailProduct(product) {
  // -------------------------------------------------------------------------------
  // Build/Map the Compe Products
  // -------------------------------------------------------------------------------
  // Get number of products, if have just product then no need to map (no array)

  const numProds = product.length;
  let productCloned;
  if (numProds >= 1) {
    // Clone product so we use the same array in both cases
    // Use JSON stringify for deep copy
    productCloned = JSON.parse(JSON.stringify(product));
  } else {
    // Convert the single product object into an array so can use the same map object below
    productCloned = [product];
  }

  // Map elements
  const compeProducts = productCloned.map((p) => {
    // Get the price for the product instance
    let prodPrice = getBigcPrice(p);

    // Get the Cost
    // const costObj = cost.get(p);
    // const prodCost = getBigcCost(_.get(costObj, "cost"), p);
    const prodCost = _.get(p, "product_cost");

    const prodUrl = `https://shop.rfwel.com/products.php?productId=${p.pid_bigc}&source=${CONFIG.SOURCE_TAG}`;
    let margin, margin_percent;
    if (prodPrice && prodCost) {
      prodPrice = parseFloat(prodPrice);
      prodPrice = Number(prodPrice.toFixed(2));
      const prodMargin = profit.get(CONFIG.BIGC, prodPrice, prodCost);
      margin = prodMargin.margin;
      margin_percent = prodMargin.margin_percent;
    }

    // // Get Rfwel stock status
    // const rfwStock = getStockStatus(p);
    // const hasQboStock = rfwStock.qbo;
    // const qtyQbo = rfwStock.qbo_qty;
    // const hasSuppStock = rfwStock.any_supp;

    // const rfwStockStat = hasQboStock
    //   ? `Yes - QBO (${qtyQbo})`
    //   : hasSuppStock
    //   ? `Yes - ${rfwStock.supp_with_stock}`
    //   : "No";

    // Get Stock Status
    const inStock = _.get(p, "in_stock");
    const stockSource = _.get(p, "cost_source");
    const rfwStockStat = inStock ? `Yes (${stockSource})` : "No";

    const bigcSalePrice = _.get(p, "prices_for_product.price_sale_bigc");
    const bigcDefaultPrice = _.get(p, "prices_for_product.price_bigc");

    const rfwSalePrice = bigcSalePrice ? Number(bigcSalePrice.toFixed(2)) : "";
    const rfwPrice = bigcDefaultPrice
      ? Number(bigcDefaultPrice.toFixed(2))
      : "";

    const priceLastUpdate = _.get(p.prices_for_product, "last_update");
    const rfwPriceLastUpdate = priceLastUpdate
      ? formatDistanceToNow(new Date(priceLastUpdate), {
          addSuffix: true,
        })
      : "";

    // Crete the Rfwel object
    const rfwelProd = {
      competitor: "rfwel",
      compe_link: CONFIG.buildLink(prodUrl, "rfwel"),
      price: rfwPrice,
      sale_price: rfwSalePrice,
      in_stock: rfwStockStat,
      available_date: "--",
      delta_to_match: "--",
      deviation_percent: "--",
      matched_margin: margin,
      matched_margin_percent: margin_percent,
      last_update: rfwPriceLastUpdate,
    };

    // Check if have any competitors for product
    // If there are any competitors, map all the competitors into the object structure above
    let compProds;
    if (p.competitors_for_product.length > 0) {
      compProds = p.competitors_for_product.map((cpt) => {
        const cptProd = buildCompeObj(cpt, prodPrice, prodCost);
        return cptProd;
      }); // end competitors map
    } // end if(p.competitors_for_product.length > 0)

    // if have comps merge it to rfwelObj
    let outCompeProds;
    // if (typeof compProds != "undefined") {
    if (compProds) {
      outCompeProds = [rfwelProd, ...compProds];
    } else {
      outCompeProds = [rfwelProd];
    }

    return outCompeProds;
  });

  // // Return from function buildChannelProducts
  // console.log(`compeProducts at buildCompeProducts Func-->`);
  // console.log(compeProducts);
  // if had more than one products return compeProducts, else just return first item
  if (numProds >= 1) {
    return compeProducts;
  }
  return compeProducts[0];
}

//**********************************************************************************************************************************/
// BUILD INVENTORY DETAIL PRODUCT
//**********************************************************************************************************************************/

export function buildInventoryDetailProduct(product) {
  const numProds = product.length;
  let productCloned;
  if (numProds >= 1) {
    // Clone product so we use the same array in both cases
    // Use JSON stringify for deep copy
    productCloned = JSON.parse(JSON.stringify(product));
  } else {
    // Convert the single product object into an array so can use the same map object below
    productCloned = [product];
  }

  // Map elements
  const inventoryProducts = productCloned.map((p) => {
    // Get Rfwel stock status
    const rfwStock = getStockStatus(p);
    const hasQboStock = rfwStock.qbo;
    const qtyQbo = rfwStock.qbo_qty;
    const hasSuppStock = rfwStock.any_supp;
    const qboCost = _.get(p.inventory_for_product, "cost_qbo");

    // Add FBA stock
    const rfwStockStat = hasQboStock ? "In Stock" : "Out of Stock";

    const rfwStockQty = hasQboStock ? `${qtyQbo} (QBO New)` : "";

    const inventoryLastUpdate = _.get(p.inventory_for_product, "last_update");
    const rfwInventoryLastUpdate = inventoryLastUpdate
      ? formatDistanceToNow(new Date(inventoryLastUpdate), {
          addSuffix: true,
        })
      : "";

    // Create the Rfwel object
    const rfwelInventory = {
      supplier: "Rfwel Stock",
      supplier_sku: p.sku,
      supplier_link: "Rfwel Stock",
      cost: qboCost,
      stock_status: rfwStockStat,
      qty: rfwStockQty,
      leadtime: "--",
      available_date: "--",
      last_update: rfwInventoryLastUpdate,
    };

    // Check if have any suppliers for product
    // If there are any suppliers, map all the suppliers into the object structure above
    let suppProds;
    if (p.suppliers_for_product.length > 0) {
      suppProds = p.suppliers_for_product.map((supp) => {
        const suppProd = buildSuppObj(supp);
        return suppProd;
      });
    }

    // if have supps merge it to rfwelObj
    let outSuppProds;
    if (typeof suppProds !== "undefined") {
      outSuppProds = [rfwelInventory, ...suppProds];
    } else {
      outSuppProds = [rfwelInventory];
    }

    return outSuppProds;
  });

  // Return from function buildChannelProducts
  // if had more than one products return inventoryProducts, else just return first item
  if (numProds >= 1) {
    return inventoryProducts;
  }
  return inventoryProducts[0];
}

//**********************************************************************************************************************************/
// BUILD FILTERED COMPE PRODUCTS
//**********************************************************************************************************************************/
export function getFilteredCompe({
  products,
  marginThreshold,
  showSKU,
  showCompetitors,
  skusToGet,
  priceLockedHide,
  matchOnSale,
}) {
  //-----------------------------------------------------------------------------------------
  // Deep Clone Prods
  //-----------------------------------------------------------------------------------------
  // Clone Prods so can modify it as we go along
  let filteredProds = JSON.parse(JSON.stringify(products));

  //-----------------------------------------------------------------------------------------
  // Remove products with no competitor data
  //-----------------------------------------------------------------------------------------
  filteredProds = _.filter(filteredProds, function (p) {
    if (p.competitors_for_product) {
      return p.competitors_for_product.length !== 0;
    }
  });

  //-----------------------------------------------------------------------------------------
  // Show/Hide PriceLocked Products
  //-----------------------------------------------------------------------------------------
  // Filter out products with BigC price locked if we have Price Lock HIde enabled
  if (priceLockedHide === "HIDE_PRICELOCKED") {
    filteredProds = _.filter(filteredProds, function (p) {
      // Get all the price locks in different channels for this product
      const prodPriceLocks = getPriceLocks(p);

      // For competitor analysis just interested in BigC price locks
      const isBigcPriceLocked = prodPriceLocks.price_lock_bigc;

      // Check if price lock release date is in the future
      let isBigcFutureRelease = false;
      if (
        isBigcPriceLocked &&
        prodPriceLocks.price_lock_release_bigc !== null
      ) {
        isBigcFutureRelease = isDateFuture(
          prodPriceLocks.price_lock_release_bigc
        );
      }
      // @TODO --> Extract BigC price lock notes

      if (isBigcPriceLocked && isBigcFutureRelease) return false;
      return true;
    });
  }

  //-----------------------------------------------------------------------------------------
  // Show Primary Competitors
  //-----------------------------------------------------------------------------------------
  // If have option to show only Primary Competitors, filter out non-primary competitors
  if (showCompetitors === "SHOW_PRIMARY") {
    // in Lodash wrap the condition in the same structure for the test, as the original object
    // products = [{ it1: it1, it2: it2, competitors_for_product=[{},{}]}] or compe array could be empty

    filteredProds = _.filter(filteredProds, {
      competitors_for_product: [{ is_primary_competitor: true }],
    });
  }

  //-----------------------------------------------------------------------------------------
  // Map Remainder
  //-----------------------------------------------------------------------------------------

  const filteredCompes = filteredProds.map((p) => {
    // Get the price for the product instance
    let prodPrice = getBigcPrice(p);

    // Get the cost for the product instance
    // const costObj = cost.get(p);
    // let prodCost = getBigcCost(_.get(costObj, "cost"), p);
    let prodCost = _.get(p, "product_cost");

    // Map competitors and add properties that we can search on later
    let competitors = p.competitors_for_product;

    // If need only competitors with stock, filter out competitors with no stock
    if (showCompetitors === "SHOW_STOCKED") {
      competitors = _.filter(competitors, { stock_status: "Y" });
    }

    // If need only primary competitors, filter out non-primary competitors
    if (showCompetitors === "SHOW_PRIMARY") {
      competitors = _.filter(competitors, {
        is_primary_competitor: true,
      });
    }

    // Remove competitors with a higher price than our price (we are already cheaper!)
    competitors = _.filter(competitors, function (c) {
      // If have a sale price use the sale price for comparison if have "Match On-Sale Price" enabled
      let compePrice =
        matchOnSale === "MATCH_ON_SALE" && c.on_sale
          ? c.competitor_sale_price
          : c.competitor_item_price;
      return compePrice <= prodPrice;
    });

    // Map remaining competitors into object we will use
    const compe = competitors.map((cp) => {
      // Build the competitor object
      const comp = buildCompeObj(cp, prodPrice, prodCost, matchOnSale);

      return comp;
    }); // end map of compe

    // Determine competitor object to use for the SKU
    // Get the competitor with matched margin below or above threshold
    // @TODO --> this returns the first found - we would want to return the compe with the lowest price.
    const competitorObj = _.find(compe, (c) => {
      if (showSKU === "SHOW_ADJUSTABLE") {
        return c.matched_margin_percent >= marginThreshold;
      } else {
        if (showSKU === "SHOW_UNADJUSTABLE") {
          return c.matched_margin_percent < marginThreshold;
        } else {
          return true;
        }
      }
    });

    // Get compePrice
    let compePrice = _.get(competitorObj, "price");
    if (compePrice) {
      compePrice = Number(compePrice);
    }

    // Get margin
    let compeMarginAbs, compeMarginPercent;
    if (prodPrice && prodCost) {
      prodPrice = Number(prodPrice);
      prodCost = Number(prodCost);
      const compeMargin = profit.get(CONFIG.BIGC, prodPrice, prodCost);
      compeMarginAbs = compeMargin.margin;
      compeMarginPercent = compeMargin.margin_percent;
    }

    // Build the object for the product instance
    const compProd = {
      id: p.id,
      sku: p.sku,
      category: p.category,
      listat: p.listing_status_bigc,
      channid: p.pid_bigc,
      rfwel_price: prodPrice,
      cost: prodCost,
      margin: compeMarginAbs,
      margin_percent: compeMarginPercent,
      competitor: _.get(competitorObj, "competitor"),
      compe_link: _.get(competitorObj, "compe_link"),
      is_primary_competitor: _.get(competitorObj, "is_primary_competitor"),
      compe_price: compePrice,
      last_update: _.get(competitorObj, "last_update"),
      compe_stock: _.get(competitorObj, "in_stock"),
      available_date: _.get(competitorObj, "available_date"),
      delta_to_match: _.get(competitorObj, "delta_to_match"),
      deviation_percent: _.get(competitorObj, "deviation_percent"),
      matched_margin_percent: _.get(competitorObj, "matched_margin_percent"),
    };

    return compProd;
  }); //end map of product

  // Filter out any products with no competitors
  const filterOutput = _.filter(filteredCompes, function (pf) {
    return pf.competitor !== undefined;
  });

  /*  console.log(`Products After Remap -->`);
  console.log(filterOutput); */

  //-----------------------------------------------------------------------------------------
  // Sort Based on Margin and Return first N
  //-----------------------------------------------------------------------------------------
  // Sort in descending order of margin and take first N items in list
  // see -> https://masteringjs.io/tutorials/lodash/sortby
  const iteratees = (obj) => -obj.matched_margin_percent;

  const sortedArray = _.sortBy(filterOutput, iteratees);

  // Return first skusToGet items in sorted array
  // see -> https://www.geeksforgeeks.org/lodash-_-take-method/
  const limitedArray = _.take(sortedArray, skusToGet);

  return limitedArray;
}

//**********************************************************************************************************************************/
// BUILD STALE COMPE SKUS
//**********************************************************************************************************************************/
export function getFilteredStaleCompe({
  products,
  marginEnable,
  marginThreshold,
  freshnessDays,
  skusToHide,
  skusToGet,
}) {
  //-----------------------------------------------------------------------------------------
  // Deep Clone Prods
  //-----------------------------------------------------------------------------------------
  // Clone Prods so can modify it as we go along
  let filteredProds = JSON.parse(JSON.stringify(products));

  // [UPDATE - filters moved to backend. Rt 6/25]
  // //-----------------------------------------------------------------------------------------
  // // Filter QBO Stocked
  // //-----------------------------------------------------------------------------------------
  // // Filter out any items that either doesnt have an inventory object or that has QBO stock = 0
  // if (stockStatus === "STOCK_QBO") {
  //   filteredProds = _.filter(filteredProds, function (p) {
  //     if (p.inventory_for_product !== null) {
  //       return p.inventory_for_product.qty_qbo > 0;
  //     }
  //   });
  // }

  // //-----------------------------------------------------------------------------------------
  // // Filter Supplier Stocked
  // //-----------------------------------------------------------------------------------------
  // // Filter out any items that either don't have supplier object or none of the suppliers have stock
  // if (stockStatus === "STOCK_SUPP") {
  //   // in Lodash wrap the condition in the same structure for the test, as the original object
  //   // prods = [{ it1: it1, it2: it2, competitors_for_product=[{},{}]}] or compe array could be empty

  //   filteredProds = _.filter(filteredProds, {
  //     suppliers_for_product: [{ stock_status: "Y" }],
  //   });
  // }

  // //-----------------------------------------------------------------------------------------
  // // Filter Any Stocked
  // //-----------------------------------------------------------------------------------------
  // // Inactive In-Stock: STOCK_ANY filters out items without QBO stock or Supplier Stock
  // if (stockStatus === "STOCK_ANY") {
  //   const prodsWithQboStock = _.filter(filteredProds, function (p) {
  //     if (p.inventory_for_product !== null) {
  //       return p.inventory_for_product.qty_qbo > 0;
  //     }
  //   });

  //   const prodsWithSuppStock = _.filter(filteredProds, {
  //     suppliers_for_product: [{ stock_status: "Y" }],
  //   });

  //   // Use Lodash UnionBy to Concatenate the two arrays without duplicates (Javascript array1.concat(array2) results in duplicates of items with QBO and Supplier stock)
  //   filteredProds = _.unionBy(prodsWithQboStock, prodsWithSuppStock, "id");
  // }

  //-----------------------------------------------------------------------------------------
  // Filter Any Items Tagged as having No Competitors
  //-----------------------------------------------------------------------------------------
  // Filter out SKUs that have "No competitor found" selected
  // Cannot get last refresh date for these so good to redo compe anal once in a while.
  if (skusToHide === "HIDE_NO_COMPE_FOUND") {
    filteredProds = _.filter(filteredProds, function (p) {
      return p.no_competitor_found !== true;
    });
  }

  //-----------------------------------------------------------------------------------------
  // Map Remainder
  //-----------------------------------------------------------------------------------------
  const filteredStaleCompes = filteredProds.map((p) => {
    // Get the price for the product instance
    let prodPrice = getBigcPrice(p);

    // Get the cost for the product instance
    // const costObj = cost.get(p);
    // let prodCost = getBigcCost(_.get(costObj, "cost"), p);
    let prodCost = _.get(p, "product_cost");

    // Get margin
    let bigcMarginAbs, bigcMarginPercent;
    if (prodPrice && prodCost) {
      prodPrice = Number(prodPrice);
      prodCost = Number(prodCost);
      const bigcMargin = profit.get(CONFIG.BIGC, prodPrice, prodCost);
      bigcMarginAbs = bigcMargin.margin;
      bigcMarginPercent = bigcMargin.margin_percent;
    }

    if (!bigcMarginPercent) {
      // If margin is blank (set to 0)
      bigcMarginAbs = 0;
      bigcMarginPercent = 0;
    }

    // If margin % < threshold margin skip over item
    // Exclude items with margin below target margin
    if (
      marginEnable === "MARGIN_ENABLE" &&
      (!bigcMarginPercent || bigcMarginPercent < marginThreshold)
    ) {
      return null;
    }

    // Map competitors and add properties that we can search on later
    // Define status of competitors for this product
    let hasStaleCompeObject = false;
    let competitors = p.competitors_for_product;
    let filteredCompeObjs;
    if (
      competitors !== null &&
      competitors !== undefined &&
      competitors.length !== 0
    ) {
      // has competitor object
      hasStaleCompeObject = true;

      // Map the competitors into object we will use
      const compe = competitors.map((cp) => {
        const comp = buildStaleCompeObj(cp);
        return comp;
      }); // end map of compe

      // Exclude any product with a competitor which has a last_update within refresh period
      const prodhasFreshCompe = _.find(compe, (c) => {
        // Determine if last update for competitor c is more than freshnessDays ago
        // If expiration date is in the future isDateTAgo returns true
        // If so return object so can exclude this product because competitors are fresh
        if (isDateTAgo(c.last_update, freshnessDays)) return true;
        return false;
      });

      if (prodhasFreshCompe !== undefined) {
        // If have an object this product has a fresh compe so exclude
        return null;
      } else {
        // prodhasFreshCompe === undefined means that this product does not have a fresh compe
        filteredCompeObjs = compe.reduce((a, b) =>
          a.last_update > b.last_update ? a : b
        );
      } //end if (prodhasFreshCompe != undefined)
    } //end if (competitors !== null && competitors !== undefined && competitors.length !== 0)

    // Get the freshest compe name
    const freshestCompeName = hasStaleCompeObject
      ? _.get(filteredCompeObjs, "competitor")
      : "--No Compe Listed--";

    const freshestCompeLink = hasStaleCompeObject
      ? _.get(filteredCompeObjs, "link")
      : "--";

    // see -> https://date-fns.org/v2.29.3/docs/formatDistanceToNow
    const freshestCompeLastUpdate = hasStaleCompeObject
      ? formatDistanceToNow(new Date(_.get(filteredCompeObjs, "last_update")), {
          addSuffix: true,
        })
      : "--";

    // Build the object for the product instance
    const staleCompProd = {
      sku: p.sku,
      category: p.category,
      rfwel_price: prodPrice,
      cost: prodCost,
      margin: bigcMarginAbs,
      margin_percent: bigcMarginPercent,
      freshest_compe: freshestCompeName,
      link: freshestCompeLink,
      compe_last_update: freshestCompeLastUpdate,
    };

    return staleCompProd;
  }); //end map of product

  // Filter out any null values in products list
  const filterOutput = _.filter(filteredStaleCompes, (pf) => pf !== null);

  //-----------------------------------------------------------------------------------------
  // Sort Based on Margin and Return first N
  //-----------------------------------------------------------------------------------------
  // Sort in descending order of margin and take first N items in list
  // see -> https://masteringjs.io/tutorials/lodash/sortby
  const iteratees = (obj) => -obj.margin_percent;

  const sortedArray = _.sortBy(filterOutput, iteratees);

  // Return first skusToGet items in sorted array
  // see -> https://www.geeksforgeeks.org/lodash-_-take-method/
  let limitedArray = _.take(sortedArray, skusToGet);

  // Remove duplicates
  let uniqueProducts = [];
  let seenSKUs = new Set();

  limitedArray.forEach((limitedArray) => {
    if (!seenSKUs.has(limitedArray.sku)) {
      uniqueProducts.push(limitedArray);
      seenSKUs.add(limitedArray.sku);
    }
  });

  return uniqueProducts;
}

//**********************************************************************************************************************************/
// BUILD STALE COMPE AUDIT SKUS
//**********************************************************************************************************************************/
export function getFilteredStaleCompeAudit({
  products,
  marginEnable,
  marginThreshold,
  auditFrequency,
  skusToGet,
}) {
  //-----------------------------------------------------------------------------------------
  // Deep Clone Prods
  //-----------------------------------------------------------------------------------------
  // Clone Prods so can modify it as we go along
  let filteredProds = JSON.parse(JSON.stringify(products));

  //-----------------------------------------------------------------------------------------
  // Map Remainder
  //-----------------------------------------------------------------------------------------
  const filteredOverdueAudits = filteredProds.map((p) => {
    // Get the price for the product instance
    const priceSaleBigc = _.get(p, "price_sale_bigc");
    const priceBigc = _.get(p, "price_bigc");
    let prodPrice =
      priceSaleBigc && priceBigc && priceSaleBigc < priceBigc
        ? priceSaleBigc
        : priceBigc;
    let prodCost = _.get(p, "product_cost");

    // Get margin
    let bigcMarginAbs, bigcMarginPercent;
    if (prodPrice && prodCost) {
      prodPrice = Number(prodPrice);
      prodCost = Number(prodCost);
      const bigcMargin = profit.get(CONFIG.BIGC, prodPrice, prodCost);
      bigcMarginAbs = bigcMargin.margin;
      bigcMarginPercent = bigcMargin.margin_percent;
    }

    if (!bigcMarginPercent) {
      // If margin is blank (set to 0)
      bigcMarginAbs = 0;
      bigcMarginPercent = 0;
    }

    // If margin % < threshold margin skip over item
    // Exclude items with margin below target margin
    if (
      marginEnable &&
      (!bigcMarginPercent || bigcMarginPercent < marginThreshold)
    ) {
      return null;
    }

    const lastAuditOn = _.get(p.competitor_audit_for_product, "audited_on");
    const lastAuditBy = _.get(p.competitor_audit_for_product, "audited_by");
    const auditOverdue =
      !isDateTAgo(lastAuditOn, auditFrequency) || !lastAuditOn;

    // if not overdue return null
    if (!auditOverdue) {
      return null;
    }

    // see -> https://date-fns.org/v2.29.3/docs/formatDistanceToNow
    const lastAuditOnStr = lastAuditOn
      ? formatDistanceToNow(new Date(lastAuditOn), {
          addSuffix: true,
        })
      : "--No Compe Audit--";

    const lastAuditByStr = lastAuditBy ? lastAuditBy : "--";

    // Build the object for the product instance
    const overdueAuditProd = {
      sku: p.sku,
      category: p.category,
      rfwel_price: prodPrice,
      cost: prodCost,
      margin: bigcMarginAbs,
      margin_percent: bigcMarginPercent,
      last_audit_on: lastAuditOnStr,
      last_audit_by: lastAuditByStr,
    };

    return overdueAuditProd;
  }); //end map of product

  // Filter out any null values in products list
  const filterOutput = _.filter(filteredOverdueAudits, (pf) => pf !== null);

  //-----------------------------------------------------------------------------------------
  // Sort Based on Margin and Return first N
  //-----------------------------------------------------------------------------------------
  // Sort in descending order of margin and take first N items in list
  // see -> https://masteringjs.io/tutorials/lodash/sortby
  const iteratees = (obj) => -obj.margin_percent;

  const sortedArray = _.sortBy(filterOutput, iteratees);

  // Return first skusToGet items in sorted array
  // see -> https://www.geeksforgeeks.org/lodash-_-take-method/
  let limitedArray = _.take(sortedArray, skusToGet);

  // Remove duplicates
  let uniqueProducts = [];
  let seenSKUs = new Set();

  limitedArray.forEach((limitedArray) => {
    if (!seenSKUs.has(limitedArray.sku)) {
      uniqueProducts.push(limitedArray);
      seenSKUs.add(limitedArray.sku);
    }
  });

  return uniqueProducts;
}

//**********************************************************************************************************************************/
// BUILD FILTERED LISTING & DQ PRODUCTS
//**********************************************************************************************************************************/
export function getFilteredToListProds({
  products,
  minPrice,
  maxPrice,
  priceDelta,
  marginThreshold,
  channelSelected,
  skusToGet,
}) {
  //-----------------------------------------------------------------------------------------
  // Deep Clone Prods
  //-----------------------------------------------------------------------------------------
  // Clone Prods so can modify it as we go along
  let filteredProds = JSON.parse(JSON.stringify(products));

  //-----------------------------------------------------------------------------------------
  // Get items with price between min and max
  //-----------------------------------------------------------------------------------------
  // This also filters out items with no prices
  filteredProds = _.filter(filteredProds, function (p) {
    if (p.prices_for_product !== null) {
      // Get the price for the product instance
      const bigcPrice = getBigcPrice(p);

      const chanPrice =
        parseFloat(bigcPrice) + parseFloat((priceDelta * bigcPrice) / 100);
      return chanPrice >= minPrice && chanPrice <= maxPrice;
    }
  });

  // UPDATED: This is now handled in the backend. Rt 6/21/23.
  // //-----------------------------------------------------------------------------------------
  // // Filter Any Stocked
  // //-----------------------------------------------------------------------------------------
  // // Note STOCK_ALL passes through all whether or not in stock so no filter for it.
  // // But STOCK_ANY needs to filter out items without QBO stock or Supplier Stock
  // if (showStock === "STOCK_ANY") {
  //   const prodsWithQboStock = _.filter(filteredProds, function (p) {
  //     if (p.inventory_for_product !== null) {
  //       return p.inventory_for_product.qty_qbo > 0;
  //     }
  //   });

  //   const prodsWithSuppStock = _.filter(filteredProds, {
  //     suppliers_for_product: [{ stock_status: "Y" }],
  //   });

  //   // Use Lodash UnionBy to Concatenate the two arrays without duplicates (Javascript array1.concat(array2) results in duplicates of items with QBO and Supplier stock)
  //   filteredProds = _.unionBy(prodsWithQboStock, prodsWithSuppStock, "id");
  // }

  // //-----------------------------------------------------------------------------------------
  // // Filter QBO Stocked
  // //-----------------------------------------------------------------------------------------
  // // Filter out any items that either doesnt have an inventory object or that has QBO stock = 0
  // if (showStock === "STOCK_QBO") {
  //   filteredProds = _.filter(filteredProds, function (p) {
  //     if (p.inventory_for_product !== null) {
  //       return p.inventory_for_product.qty_qbo > 0;
  //     }
  //   });
  // }

  // //-----------------------------------------------------------------------------------------
  // // Filter Supplier Stocked
  // //-----------------------------------------------------------------------------------------
  // // Filter out any items that either dont have supplier object or none of the suppliers have stock
  // if (showStock === "STOCK_SUPP") {
  //   // in Lodash wrap the condition in the same structure for the test, as the original object
  //   // prods = [{ it1: it1, it2: it2, competitors_for_product=[{},{}]}] or compe array could be empty

  //   filteredProds = _.filter(filteredProds, {
  //     suppliers_for_product: [{ stock_status: "Y" }],
  //   });
  // }

  //-----------------------------------------------------------------------------------------
  // Map Remainder
  //-----------------------------------------------------------------------------------------
  const mappedListProds = filteredProds.map((p) => {
    // Get the Price
    // For AMZ-FBA or AMZ-CA we use AMZ-US price if it exists
    let channelPrice;
    let prodPrice = getBigcPrice(p);

    if (
      (channelSelected === CONFIG.AMZ_FBA_US ||
        channelSelected === CONFIG.AMZ_SELLER_CA) &&
      p.prices_for_product.price_amz_seller_us !== null
    ) {
      channelPrice = parseFloat(p.prices_for_product.price_amz_seller_us);
    } else {
      channelPrice = prodPrice + parseFloat((priceDelta * prodPrice) / 100);
    }

    // // Get the cost for the product instance
    // const costObj = cost.get(p);
    // const prodCost = _.get(costObj, "cost"); // No BigC so no MOQ issues
    const prodCost = _.get(p, "product_cost");

    // // Map competitors and add properties that we can search on later
    // let competitors = p.competitors_for_product;

    // let noCompe = false;
    // if (competitors) {
    //   noCompe = competitors.length === 0;
    // }

    // // Unless disregarding compe, need only competitors with stock, filter out competitors with no stock
    // if (showCompeStatus !== "SHOW_ALL") {
    //   competitors = _.filter(competitors, { stock_status: "Y" });
    // }

    // // Remove competitors with a higher price than our price - if we are lowest there would be no competitors left
    // competitors = _.filter(competitors, function (c) {
    //   return c.competitor_item_price < prodPrice;
    // });

    // // If showing items without compe and have any compe that made it through here we will skip this item
    // // Return null to map and will filter out nulls later
    // if (
    //   showCompeStatus === "COMPE_BEATEN" &&
    //   !noCompe &&
    //   competitors.length > 0
    // ) {
    //   // have a compe with inventory and price < rfwel so return null
    //   return null;
    // }

    // Calculate margin
    let prodMarginAbs, prodMarginPercent;
    if (channelPrice && prodCost) {
      channelPrice = Number(channelPrice.toFixed(2));
      const prodMargin = profit.get(channelSelected, channelPrice, prodCost);
      prodMarginAbs = prodMargin.margin;
      prodMarginPercent = prodMargin.margin_percent;
    }

    // Exclude items with margin below target margin
    if (!prodMarginPercent || prodMarginPercent < marginThreshold) {
      return null;
    }

    // Build the object for the product instance
    const listProd = {
      sku: p.sku,
      category: p.category,
      brand: p.brand,
      rfwel_price: prodPrice,
      channel_price: channelPrice,
      margin: prodMarginAbs,
      margin_percent: prodMarginPercent,
    };

    return listProd;
  }); //end map of product

  //-----------------------------------------------------------------------------------------
  // Remove nulls
  //-----------------------------------------------------------------------------------------
  const filteredListProds = _.filter(mappedListProds, function (p) {
    return p !== null;
  });

  //-----------------------------------------------------------------------------------------
  // Sort Based on Margin and Return first N
  //-----------------------------------------------------------------------------------------
  // Sort in descending order of margin and take first N items in list
  // see -> https://masteringjs.io/tutorials/lodash/sortby
  const iteratees = (obj) => -obj.margin_percent;

  const sortedArray = _.sortBy(filteredListProds, iteratees);

  // Return first skusToGet items in sorted array
  // see -> https://www.geeksforgeeks.org/lodash-_-take-method/
  let limitedArray = _.take(sortedArray, skusToGet);

  // Remove duplicates
  let uniqueProducts = [];
  let seenSKUs = new Set();

  limitedArray.forEach((limitedArray) => {
    if (!seenSKUs.has(limitedArray.sku)) {
      uniqueProducts.push(limitedArray);
      seenSKUs.add(limitedArray.sku);
    }
  });

  return uniqueProducts;
}

//**********************************************************************************************************************************/
// BUILD Competitor Object
//**********************************************************************************************************************************/
export function buildCompeObj(prod, refPrice, refCost, matchOnSale) {
  // Get the competitor data
  let compePrice;
  const compeOnSale = _.get(prod, "on_sale");
  const compeDefaultPrice = _.get(prod, "competitor_item_price");
  const compeSaleprice = _.get(prod, "competitor_sale_price");
  compePrice =
    matchOnSale === "MATCH_ON_SALE" && compeOnSale && compeSaleprice
      ? compeSaleprice
      : compeDefaultPrice;
  const compeUrl = _.get(prod, "competitor_item_url");
  const compeStock = _.get(prod, "stock_status");
  const compeStockStr =
    compeStock === "Y" ? "Yes" : compeStock === "DS" ? "Direct-Ship" : "No";
  const compeAvailDate = formatDate(_.get(prod, "available_date"));
  const compeAvaileDateStr = compeStock !== "Y" ? compeAvailDate : "--";
  const compeLastUpdate = formatDistanceToNow(
    new Date(_.get(prod, "last_update")),
    {
      addSuffix: true,
    }
  );

  // Delta to Match & Deviation Percent
  // How much to increase/decrease our price to match competitor
  // Only provide detail if have compePrice and refPrice
  let deltaToMatch, devPercent, matched_margin, matched_margin_percent;
  if (compePrice && refPrice) {
    compePrice = parseFloat(compePrice);
    compePrice = Number(compePrice.toFixed(2));
    deltaToMatch = Number((compePrice - refPrice).toFixed(2));
    devPercent = Number(((deltaToMatch / refPrice) * 100).toFixed(2));
    if (refCost) {
      const margin = profit.get(CONFIG.BIGC, compePrice, refCost);
      const prodMarginAbs = margin.margin;
      const prodMarginPercent = margin.margin_percent;
      matched_margin = prodMarginAbs;
      matched_margin_percent = prodMarginPercent;
    }
  }

  const compeName = _.get(prod, "competitor");
  const compeLinked = CONFIG.buildLink(compeUrl, compeName);

  const compeObj = {
    competitor: compeName,
    compe_link: compeLinked,
    is_primary_competitor: _.get(prod, "is_primary_competitor"),
    price: compePrice, //if have "Match-on-Sale" this will be sale price (only for compe anal)
    sale_price: compeSaleprice,
    in_stock: compeStockStr,
    available_date: compeAvaileDateStr,
    delta_to_match: deltaToMatch,
    deviation_percent: devPercent,
    matched_margin: matched_margin,
    matched_margin_percent: matched_margin_percent,
    last_update: compeLastUpdate,
  };

  return compeObj;
}

//**********************************************************************************************************************************/
// BUILD Supplier Object
//**********************************************************************************************************************************/
export function buildSuppObj(prod) {
  const suppName = _.get(prod, "supplier");
  const suppIsPrimary = _.get(prod, "is_primary_supplier");
  const suppNameStr = suppIsPrimary ? `${suppName} (Pri)` : `${suppName}`;
  const suppSku = _.get(prod, "item_sku");
  const suppUrl = _.get(prod, "item_url");
  const suppCost = _.get(prod, "item_cost");
  const suppStock = _.get(prod, "stock_status");
  const suppStockStr = suppStock === "Y" ? "In Stock" : "Out of Stock";
  const suppQty = _.get(prod, "qty");
  const suppLeadTime = _.get(prod, "leadtime_days");
  const suppAvailabeDate = _.get(prod, "available_date");
  const suppLastUpdate = formatDistanceToNow(
    new Date(_.get(prod, "last_update")),
    {
      addSuffix: true,
    }
  );

  const suppObj = {
    supplier: suppNameStr,
    supplier_sku: suppSku,
    supplier_link: CONFIG.buildLink(suppUrl, suppNameStr),
    cost: suppCost,
    stock_status: suppStockStr,
    qty: suppQty,
    leadtime: suppLeadTime,
    available_date: suppAvailabeDate,
    last_update: suppLastUpdate,
  };

  return suppObj;
}

//**********************************************************************************************************************************/
// BUILD Stale Competitor Object
//**********************************************************************************************************************************/
export function buildStaleCompeObj(prod) {
  // Get the competitor data
  const compeUrl = _.get(prod, "competitor_item_url");
  const compeLastUpdate = _.get(prod, "last_update");

  const staleCompeObj = {
    competitor: _.get(prod, "competitor"),
    is_primary_competitor: _.get(prod, "is_primary_competitor"),
    link: CONFIG.buildLink(compeUrl, "Link"),
    last_update: compeLastUpdate,
  };

  return staleCompeObj;
}

//**********************************************************************************************************************************/
// BUILD Stale Supplier Object
//**********************************************************************************************************************************/
export function buildStaleSupplierObj(prod) {
  // Get the supplier data
  const supplierUrl = _.get(prod, "item_url");
  const supplierLastUpdate = _.get(prod, "last_update");

  const staleSupplierObj = {
    supplier: _.get(prod, "supplier"),
    is_primary_supplier: _.get(prod, "is_primary_supplier"),
    link: CONFIG.buildLink(supplierUrl, "Link"),
    last_update: supplierLastUpdate,
  };

  return staleSupplierObj;
}

//**********************************************************************************************************************************/
// Get Inactive Items
//**********************************************************************************************************************************/
export function getStockStatAnalListings({
  stockStatAnalType,
  products,
  skusToGet,
  skusToHide,
  inventoryLockedHide,
}) {
  //-----------------------------------------------------------------------------------------
  // Deep Clone Prods
  //-----------------------------------------------------------------------------------------
  // Clone Prods so can modify it as we go along
  let filteredProds = JSON.parse(JSON.stringify(products));

  //-----------------------------------------------------------------------------------------
  // Filter Any Stocked
  //-----------------------------------------------------------------------------------------
  // [UPDATED 6/9/23]
  // The endpoint /patanal_api/products-inactive-instock/ already returns items with qty_qbo > 0 or supplier stock
  // // Inactive In-Stock: filter out items without QBO stock or Supplier Stock - return items with stock
  // if (stockStatAnalType === "INACTIVE_IN_STOCK") {
  //   const prodsWithQboStock = _.filter(filteredProds, function (p) {
  //     if (p.inventory_for_product !== null) {
  //       return p.inventory_for_product.qty_qbo > 0;
  //     }
  //   });

  //   const prodsWithSuppStock = _.filter(filteredProds, {
  //     suppliers_for_product: [{ stock_status: "Y" }],
  //   });

  //   // Use Lodash UnionBy to Concatenate the two arrays without duplicates (Javascript array1.concat(array2) results in duplicates of items with QBO and Supplier stock)
  //   filteredProds = _.unionBy(prodsWithQboStock, prodsWithSuppStock, "id");
  // }

  // // Active Not-In-Stock: filter out items with either QBO stock or Supplier stock - return items with no stock anywhere
  // if (stockStatAnalType === "ACTIVE_NOT_IN_STOCK") {
  //   const prodsWithoutQboStock = _.filter(filteredProds, function (p) {
  //     if (p.inventory_for_product !== null) {
  //       return !p.inventory_for_product.qty_qbo;
  //     }
  //   });

  //   const prodsWithoutSuppStock = _.filter(filteredProds, {
  //     suppliers_for_product: [{ stock_status: "N" }],
  //   });

  //   // Use Lodash UnionBy to Concatenate the two arrays without duplicates (Javascript array1.concat(array2) results in duplicates of items with QBO and Supplier stock)
  //   filteredProds = _.unionBy(
  //     prodsWithoutQboStock,
  //     prodsWithoutSuppStock,
  //     "id"
  //   );
  // }

  //-----------------------------------------------------------------------------------------
  // Map Remainder
  //-----------------------------------------------------------------------------------------
  const mappedChannProds = filteredProds.map((p) => {
    // Get stock status "has_supplier_stock" which is true if has supplier stock.
    // [UPDATED 6/9/23] the new serializer includes a field
    // const boolStockStatus = getStockStatus(p);
    // const qtyQbo = boolStockStatus.qbo_qty;
    // let hasQboStock = boolStockStatus.qbo;
    // const hasSuppStock = boolStockStatus.any_supp;
    const qtyQbo = _.get(p, "inventory_for_product.qty_qbo");
    const hasSuppStock = _.get(p, "has_supplier_stock");
    const suppWithStock = _.get(p, "supplier_with_stock");
    const suppWithStockQty = _.get(p, "supplier_with_stock_qty");
    let hasQboStock = qtyQbo > 0;
    const isUsed = _.get(p, "item_condition") === "Used";

    // For Active-not-in-stock, ignore blank QBO (assume true)
    if (stockStatAnalType === "ACTIVE_NOT_IN_STOCK") hasQboStock = qtyQbo > 0;
    const hasAnyStock = hasQboStock || hasSuppStock;

    // Get the BigC price for the product instance
    const bigcPrice = getBigcPrice(p);

    // Get the BigC Cost
    const costObj = cost.get(p);
    const prodCost = _.get(costObj, "cost");
    const bigcCost = getBigcCost(_.get(costObj, "cost"), p);

    // Define stock status label
    const suppStockLabel = hasSuppStock ? `${suppWithStock}` : "--";

    // Determine if item is inventory locked for specific channels
    const prodInventoryLocks = getInventoryLocks(p);

    // Expand out to create array of channel products
    let channProd = [
      {
        sku: p.sku,
        category: p.category,
        channel: CONFIG.BIGC,
        listat: p.listing_status_bigc,
        chanstat: "--",
        channid: p.pid_bigc,
        price: bigcPrice,
        cost: bigcCost,
        qty_channel: _.get(p, "inventory_for_product.qty_bigc"),
        handling: _.get(p, "inventory_for_product.availability_bigc"),
        inventory_locked: prodInventoryLocks.inventory_lock_bigc,
        inventory_lock_release: prodInventoryLocks.inventory_lock_release_bigc,
      },
      {
        sku: p.sku,
        category: p.category,
        channel: CONFIG.AMZ_SELLER_US,
        listat: p.listing_status_amz_seller_us,
        channid: p.pid_amz,
        chanstat: p.channel_status_amz_seller_us,
        price: _.get(p, "prices_for_product.price_amz_seller_us"),
        cost: prodCost,
        qty_channel: _.get(p, "inventory_for_product.qty_amz_seller_us"),
        handling: _.get(p, "inventory_for_product.handling_amz"),
        inventory_locked: prodInventoryLocks.inventory_lock_amz_seller_us,
        inventory_lock_release:
          prodInventoryLocks.inventory_lock_release_amz_seller_us,
      },
      {
        sku: p.sku,
        category: p.category,
        channel: CONFIG.AMZ_FBA_US,
        listat: p.listing_status_amz_fba_us,
        chanstat: p.channel_status_amz_fba_us,
        channid: p.pid_amz,
        price: _.get(p, "prices_for_product.price_amz_fba_us"),
        cost: prodCost,
        qty_channel: _.get(p, "inventory_for_product.qty_amz_fba_shippable"),
        handling: "--",
        inventory_locked: prodInventoryLocks.inventory_lock_amz_fba_us,
        inventory_lock_release:
          prodInventoryLocks.inventory_lock_release_amz_fba_us,
      },
      {
        sku: p.sku,
        category: p.category,
        channel: CONFIG.AMZ_SELLER_CA,
        listat: p.listing_status_amz_seller_ca,
        chanstat: p.channel_status_amz_seller_ca,
        channid: p.pid_amz,
        price: _.get(p, "prices_for_product.price_amz_seller_ca"),
        cost: prodCost,
        qty_channel: _.get(p, "inventory_for_product.qty_amz_seller_ca"),
        handling: _.get(p, "inventory_for_product.handling_amz"),
        inventory_locked: prodInventoryLocks.inventory_lock_amz_seller_ca,
        inventory_lock_release:
          prodInventoryLocks.inventory_lock_release_amz_seller_ca,
      },
      {
        sku: p.sku,
        category: p.category,
        channel: CONFIG.EBAY,
        listat: p.listing_status_ebay,
        chanstat: p.channel_status_ebay,
        channid: p.pid_ebay,
        price: _.get(p, "prices_for_product.price_ebay"),
        cost: prodCost,
        qty_channel: _.get(p, "inventory_for_product.qty_ebay"),
        handling: _.get(p, "inventory_for_product.handling_ebay"),
        inventory_locked: prodInventoryLocks.inventory_lock_ebay,
        inventory_lock_release: prodInventoryLocks.inventory_lock_release_ebay,
      },
      {
        sku: p.sku,
        category: p.category,
        channel: CONFIG.WMT,
        listat: p.listing_status_wmt,
        chanstat: p.channel_status_wmt,
        channid: p.pid_wmt,
        price: _.get(p, "prices_for_product.price_wmt"),
        cost: prodCost,
        qty_channel: _.get(p, "inventory_for_product.qty_wmt"),
        handling: _.get(p, "inventory_for_product.handling_wmt"),
        inventory_locked: prodInventoryLocks.inventory_lock_wmt,
        inventory_lock_release: prodInventoryLocks.inventory_lock_release_wmt,
      },
      {
        sku: p.sku,
        category: p.category,
        channel: CONFIG.NE,
        listat: p.listing_status_newegg,
        chanstat: "--",
        channid: p.pid_ne,
        price: _.get(p, "prices_for_product.price_ne"),
        cost: prodCost,
        qty_channel: _.get(p, "inventory_for_product.qty_ne"),
        handling: "--",
        inventory_locked: prodInventoryLocks.inventory_lock_newegg,
        inventory_lock_release:
          prodInventoryLocks.inventory_lock_release_newegg,
      },
      {
        sku: p.sku,
        category: p.category,
        channel: CONFIG.NE_BIZ,
        listat: p.listing_status_newegg_biz,
        chanstat: "--",
        channid: p.pid_nebiz,
        price: _.get(p, "prices_for_product.price_nebiz"),
        cost: prodCost,
        qty_channel: _.get(p, "inventory_for_product.qty_nebiz"),
        handling: "--",
        inventory_locked: prodInventoryLocks.inventory_lock_newegg_biz,
        inventory_lock_release:
          prodInventoryLocks.inventory_lock_release_newegg_biz,
      },
    ];

    let filtChannProds = [];
    channProd.forEach((cp) => {
      let handlingValue =
        cp.handling !== undefined && cp.handling !== null && cp.handling !== ""
          ? cp.handling
          : "";
      // If channel is BigC handlingValue is a string else it is a number
      if (
        cp.channel !== CONFIG.BIGC &&
        cp.handling !== "--" &&
        handlingValue !== ""
      ) {
        handlingValue = parseInt(handlingValue);
      }

      // Filter out inventory locked item for the particular channel

      // GROUP 1: Conditions for InactiveInStock Filter (stockStatAnalType === "INACTIVE_IN_STOCK"):
      //  (1) Has Any Stock, is listed in channel, but channel quantity is 0 or blank
      //  (2) Has QBO stock, is listed in AMZ/AMZ-CA/WMT/EBAY handling time > 2.
      //  (3) Has Any stock and AMZ/AMZ-CA/WMT/EBAY handling time > 5.
      //  (4) Has Any stock, is listed in BigC, but BigC Availability is not "In Stock ?" or variant (?same day, ?next day)
      //  (5) Override condition (4) error if BigC Availability merely blank and have "HIDE_BIGC_BLANK" set
      //  (6) Has Any stock, is listed in EBAY/WMT, but EBAY/WMT status !== "PUBLISEHD"
      //  (7) Has Any stock, is listed in AMZ/FBA, but AMZ/FBA status === "NOT_DISCOVERABLE" || "NOT_BUYABLE"
      // GROUP 2: Conditions for ActiveNotInStock Filter (stockStatAnalType ==="ACTIVE_NOT_IN_STOCK")
      //  (8) Has No stock, is listed in BigC, has BigC QTY > 0, BigC Availability is "In Stock *" or variant (*same day, *next day)
      //  (9) Has No stock, is listed in third party channel, but channel quantity > 0
      //  (10) Override condition (7) error if in AMZ/AMZ-CA/WMT/EBAY and handling time > 10
      //  (11) Remove AMZ items that are NOT_BUYABLE and EBAY/WMT items that are not PUBLISHED
      // GROUP 3: For both InactiveInStock and ActiveNotInStock
      //  (12) Hide any product that has inventory locked if inventoryLockedHide === "HIDE_INVENTORYLOCKED"

      //---------------------- Inactive In Stock -------------------------------------
      // Condition (0)
      let isInStockForChannel = false;
      switch (cp.channel) {
        case CONFIG.BIGC:
          // Enable Bigc if have any QBO or supplier stock
          isInStockForChannel = hasAnyStock;
          break;
        case CONFIG.AMZ_SELLER_US:
        case CONFIG.AMZ_SELLER_US_B2B:
        case CONFIG.AMZ_SELLER_CA:
          // Enable AMZ seller-fulfilled if have any QBO or supplier stock
          isInStockForChannel = hasAnyStock;
          break;
        case CONFIG.AMZ_FBA_US:
        case CONFIG.AMZ_FBA_US_B2B:
          // Enable FBA only if have more than 10 units in QBO or supplier (or if Supplier qty is blank indicating not supply constrained)
          isInStockForChannel =
            qtyQbo >= 10 ||
            suppWithStockQty >= 10 ||
            (hasSuppStock && !suppWithStockQty);
          break;
        case CONFIG.EBAY:
          // Enable FBA only if have more than 3 units in QBO or supplier (or if Supplier qty is blank indicating not supply constrained)
          // ... or, if a used item, have one or more (all used items should be listed in eBay)
          isInStockForChannel =
            qtyQbo >= 3 ||
            (isUsed && qtyQbo >= 1) ||
            suppWithStockQty >= 3 ||
            (hasSuppStock && !suppWithStockQty);
          break;
        case CONFIG.WMT:
          // Enable FBA only if have more than 5 units in QBO or supplier (or if Supplier qty is blank indicating not supply constrained)
          isInStockForChannel =
            qtyQbo >= 5 ||
            suppWithStockQty >= 5 ||
            (hasSuppStock && !suppWithStockQty);
          break;
        case CONFIG.NE:
        case CONFIG.NE_BIZ:
          // Enable FBA only if have more than 7 units in QBO or supplier (or if Supplier qty is blank indicating not supply constrained)
          isInStockForChannel =
            qtyQbo >= 7 ||
            suppWithStockQty >= 7 ||
            (hasSuppStock && !suppWithStockQty);
          break;
      }

      // Condition (1)
      const listedButChannelQtyZero =
        stockStatAnalType === "INACTIVE_IN_STOCK" &&
        cp.listat === "Listed" &&
        // hasAnyStock &&
        isInStockForChannel &&
        !cp.qty_channel;

      // Condition (2)
      const hasQboStockButHandlingLong =
        stockStatAnalType === "INACTIVE_IN_STOCK" &&
        (cp.channel === CONFIG.AMZ_SELLER_US ||
          cp.channel === CONFIG.AMZ_SELLER_CA ||
          cp.channel === CONFIG.EBAY ||
          cp.channel === CONFIG.WMT) &&
        cp.listat === "Listed" &&
        hasQboStock &&
        handlingValue > 2;

      // Condition (3)
      const hasAnyStockButHandlingLong =
        stockStatAnalType === "INACTIVE_IN_STOCK" &&
        (cp.channel === CONFIG.AMZ_SELLER_US ||
          cp.channel === CONFIG.AMZ_SELLER_CA ||
          cp.channel === CONFIG.EBAY ||
          cp.channel === CONFIG.WMT) &&
        cp.listat === "Listed" &&
        // hasAnyStock &&
        isInStockForChannel &&
        handlingValue > 5;

      // Condition (4)
      let bigcAvailabilityError =
        stockStatAnalType === "INACTIVE_IN_STOCK" &&
        cp.channel === CONFIG.BIGC &&
        cp.listat === "Listed" &&
        // hasAnyStock &&
        isInStockForChannel &&
        handlingValue.indexOf("In Stock") === -1 &&
        handlingValue.toLowerCase().indexOf("same day") === -1 &&
        handlingValue.toLowerCase().indexOf("next day") === -1;

      // Condition (5)
      const hideBigcBlankAvail =
        cp.channel === CONFIG.BIGC &&
        skusToHide === "HIDE_BIGC_BLANK" &&
        handlingValue === "";
      bigcAvailabilityError = bigcAvailabilityError && !hideBigcBlankAvail;

      // Condition (6)
      const hasAnyStockButWmtEbayUnpublished =
        stockStatAnalType === "INACTIVE_IN_STOCK" &&
        (cp.channel === CONFIG.WMT || cp.channel === CONFIG.EBAY) &&
        cp.listat === "Listed" &&
        // hasAnyStock &&
        isInStockForChannel &&
        cp.chanstat !== "PUBLISHED";

      // Condition (7)
      const hasAnyStockButAmzUnbuyable =
        stockStatAnalType === "INACTIVE_IN_STOCK" &&
        (cp.channel === CONFIG.AMZ_SELLER_US ||
          cp.channel === CONFIG.AMZ_SELLER_CA ||
          cp.channel === CONFIG.AMZ_FBA_US) &&
        cp.listat === "Listed" &&
        // hasAnyStock &&
        isInStockForChannel &&
        (cp.chanstat === "NOT_BUYABLE" || cp.chanstat === "NOT_DISCOVERABLE");

      //---------------------- Active Not In Stock -------------------------------------
      // Condition (8)
      const activeNotInStockBigc =
        stockStatAnalType === "ACTIVE_NOT_IN_STOCK" &&
        cp.channel === CONFIG.BIGC &&
        cp.listat === "Listed" &&
        cp.qty_channel > 0 &&
        !hasAnyStock &&
        (handlingValue.indexOf("In Stock") > -1 ||
          handlingValue.toLowerCase().indexOf("same day") > -1 ||
          handlingValue.toLowerCase().indexOf("Ships in") > -1 ||
          handlingValue.toLowerCase().indexOf("next day") > -1);

      // Condition (9)
      let activeNotInStock3rdParty =
        stockStatAnalType === "ACTIVE_NOT_IN_STOCK" &&
        cp.channel !== CONFIG.BIGC &&
        cp.listat === "Listed" &&
        !hasAnyStock &&
        cp.qty_channel > 0;

      // Condition (10)
      const handlingOverrideActiveNotInStock =
        skusToHide === "HIDE_LONG_LEAD_TIME" &&
        (cp.channel === CONFIG.AMZ_SELLER_US ||
          cp.channel === CONFIG.AMZ_SELLER_CA ||
          cp.channel === CONFIG.EBAY ||
          cp.channel === CONFIG.WMT) &&
        cp.listat === "Listed" &&
        handlingValue > 10;
      activeNotInStock3rdParty =
        activeNotInStock3rdParty && !handlingOverrideActiveNotInStock;

      // Condition (11)
      const isInactiveForActiveNotInStock =
        stockStatAnalType === "ACTIVE_NOT_IN_STOCK" &&
        (cp.channel === CONFIG.AMZ_SELLER_US ||
          cp.channel === CONFIG.AMZ_SELLER_CA ||
          cp.channel === CONFIG.EBAY ||
          cp.channel === CONFIG.WMT) &&
        (cp.chanstat === "NOT_BUYABLE" || cp.chanstat !== "PUBLISHED");

      // Condition (12)
      const hideInventoryLocked =
        inventoryLockedHide === "HIDE_INVENTORYLOCKED" &&
        cp.inventory_locked &&
        isDateFuture(cp.inventory_lock_release);

      // OR all conditions
      const stockStatConditionEnable =
        (listedButChannelQtyZero ||
          hasQboStockButHandlingLong ||
          hasAnyStockButHandlingLong ||
          bigcAvailabilityError ||
          hasAnyStockButWmtEbayUnpublished ||
          hasAnyStockButAmzUnbuyable ||
          activeNotInStockBigc ||
          activeNotInStock3rdParty) &&
        !hideInventoryLocked &&
        !isInactiveForActiveNotInStock;

      if (stockStatConditionEnable) {
        // Calculate the Margin
        let prodPrice, margin, marginPercent;
        if (cp.price && cp.cost) {
          prodPrice = parseFloat(cp.price);
          prodPrice = prodPrice.toFixed(2);
          const prodMargin = profit.get(cp.channel, cp.price, cp.cost);
          margin = prodMargin.margin;
          marginPercent = prodMargin.margin_percent;
        }
        marginPercent = marginPercent ? marginPercent : 0;

        filtChannProds.push({
          sku: cp.sku,
          channel: cp.channel,
          channel_id: cp.channid,
          qty_channel: cp.qty_channel,
          category: cp.category,
          listat: cp.listat,
          chanstat: cp.chanstat,
          handling: cp.handling,
          qty_qbo: qtyQbo,
          supp_stock: suppStockLabel,
          supp_qty: suppWithStockQty,
          margin_percent: marginPercent,
        });
      }
    });

    return filtChannProds;
  }); //end map of product

  // Flatten channel arrays --> https://lodash.com/docs/4.17.15#flatten (Flattens array a single level deep.)
  const flattenedChannProds = _.flatten(mappedChannProds);

  //-----------------------------------------------------------------------------------------
  // Sort Based on Margin and Return first N
  //-----------------------------------------------------------------------------------------
  // Sort in descending order of margin and take first N items in list
  // see -> https://masteringjs.io/tutorials/lodash/sortby
  const iteratees = (obj) => -obj.margin_percent;

  const sortedArray = _.sortBy(flattenedChannProds, iteratees);

  // Return first skusToGet items in sorted array
  // see -> https://www.geeksforgeeks.org/lodash-_-take-method/
  const limitedArray = _.take(sortedArray, skusToGet);

  return limitedArray;
}

/**********************************************************************************************************************************/
// Marketing Analysis
//**********************************************************************************************************************************/
export function getMarketingListings({
  products,
  marketingAnal,
  freshnessDays,
  skusToGet,
  mktgChannel,
}) {
  const marketingShopAnalEnabled =
    marketingAnal === "SHOP_NO" ||
    marketingAnal === "SHOP_P1" ||
    marketingAnal === "SHOP_MOREPAGE" ||
    marketingAnal === "SHOP_STALE";

  const marketingOrganicAnalEnabled =
    marketingAnal === "ORGANIC_NOTP1" ||
    marketingAnal === "ORGANIC_P1TOP5" ||
    marketingAnal === "ORGANIC_P1TOP10" ||
    marketingAnal === "ORGANIC_NOTTOP10" ||
    marketingAnal === "ORGANIC_STALE";

  //-----------------------------------------------------------------------------------------
  // Deep Clone Prods
  //-----------------------------------------------------------------------------------------
  // Clone Prods so can modify it as we go along
  let filteredProds = JSON.parse(JSON.stringify(products));

  //-----------------------------------------------------------------------------------------
  // Remove items without marketing data (except if looking for stale/missing)
  //-----------------------------------------------------------------------------------------
  if (marketingAnal !== "ORGANIC_STALE" && marketingAnal !== "SHOP_STALE") {
    filteredProds = _.filter(filteredProds, function (p) {
      return p.marketing_for_product !== null;
    });
  }

  //-----------------------------------------------------------------------------------------
  // Get items with price between min and max Marketing Shop Price
  //-----------------------------------------------------------------------------------------
  if (marketingShopAnalEnabled) {
    filteredProds = _.filter(filteredProds, function (p) {
      if (p.prices_for_product !== null) {
        // Get the price for the product instance
        const bigcPrice = getBigcPrice(p);

        return (
          bigcPrice >= CONFIG.PRICE_MIN_GOOGLE &&
          bigcPrice <= CONFIG.PRICE_MAX_GOOGLE
        );
      }
    });
  }

  //-----------------------------------------------------------------------------------------
  // Filter Marketing Shop
  //-----------------------------------------------------------------------------------------

  if (marketingShopAnalEnabled) {
    filteredProds = _.filter(filteredProds, function (p) {
      // Define compare fields for different marketing channels
      let mkgShopComparisonField;
      if (mktgChannel === "google" && p.marketing_for_product) {
        mkgShopComparisonField =
          p.marketing_for_product.on_google_shop_comparison;
      }
      if (mktgChannel === "bing" && p.marketing_for_product) {
        mkgShopComparisonField =
          p.marketing_for_product.on_bing_shop_comparison;
      }

      if (marketingAnal === "SHOP_NO" && p.marketing_for_product) {
        return mkgShopComparisonField === "PAID_NO";
      }
      if (marketingAnal === "SHOP_P1" && p.marketing_for_product) {
        return mkgShopComparisonField === "PAID_PG1";
      }
      if (marketingAnal === "SHOP_MOREPAGE" && p.marketing_for_product) {
        return mkgShopComparisonField === "PAID_PGN";
      }
      if (
        marketingAnal === "SHOP_STALE" &&
        (!p.marketing_for_product ||
          !mkgShopComparisonField ||
          !isDateTAgo(p.marketing_for_product.last_update, freshnessDays))
      ) {
        return true;
      }
      return false;
    });
  }

  //-----------------------------------------------------------------------------------------
  // Filter Marketing Organic
  //-----------------------------------------------------------------------------------------
  if (marketingOrganicAnalEnabled) {
    filteredProds = _.filter(filteredProds, function (p) {
      // Define compare fields for different marketing channels
      let mkgOrganicComparisonField;
      if (mktgChannel === "google" && p.marketing_for_product) {
        mkgOrganicComparisonField = p.marketing_for_product.on_google_page1;
      }
      if (mktgChannel === "bing" && p.marketing_for_product) {
        mkgOrganicComparisonField = p.marketing_for_product.on_bing_page1;
      }

      if (marketingAnal === "ORGANIC_NOTP1" && p.marketing_for_product) {
        return mkgOrganicComparisonField === "ORG_NO";
      }
      if (marketingAnal === "ORGANIC_P1TOP5" && p.marketing_for_product) {
        return mkgOrganicComparisonField === "ORG_PG1_TOP5";
      }
      if (marketingAnal === "ORGANIC_P1TOP10" && p.marketing_for_product) {
        return mkgOrganicComparisonField === "ORG_PG1_TOP10";
      }
      if (marketingAnal === "ORGANIC_NOTTOP10" && p.marketing_for_product) {
        return mkgOrganicComparisonField === "ORG_PG1_NOT10";
      }
      if (
        marketingAnal === "ORGANIC_STALE" &&
        (!p.marketing_for_product ||
          !mkgOrganicComparisonField ||
          !isDateTAgo(p.marketing_for_product.last_update, freshnessDays))
      ) {
        return true;
      }
      return false;
    });
  }

  //-----------------------------------------------------------------------------------------
  // Map Remainder
  //-----------------------------------------------------------------------------------------
  const mappedChannProds = filteredProds.map((p) => {
    // Get the price for the product instance
    let prodPrice = getBigcPrice(p);

    const prodCost = _.get(p, "product_cost");

    // Calculate the Margin
    let margin, margin_percent;
    if (prodPrice && prodCost) {
      prodPrice = parseFloat(prodPrice);
      prodPrice = Number(prodPrice.toFixed(2));
      const prodMargin = profit.get(CONFIG.BIGC, prodPrice, prodCost);
      margin = prodMargin.margin;
      margin_percent = prodMargin.margin_percent;
    }

    // Get the product Link
    const prodUrl = `https://shop.rfwel.com/products.php?productId=${p.pid_bigc}&source=${CONFIG.SOURCE_TAG}`;

    // Get last update date
    const lastMarketingUpdateStr = _.get(
      p,
      "marketing_for_product.last_update"
    );
    const lastMarketingUpdate = lastMarketingUpdateStr
      ? formatDistanceToNow(
          new Date(_.get(p, "marketing_for_product.last_update")),
          {
            addSuffix: true,
          }
        )
      : "--";

    const marketingListProd = {
      sku: p.sku,
      category: p.category,
      link: CONFIG.buildLink(prodUrl, "Link"),
      price: prodPrice,
      margin_percent: margin_percent,
      last_update: lastMarketingUpdate,
    };

    return marketingListProd;
  }); //end map of product

  //-----------------------------------------------------------------------------------------
  // Sort Based on Margin and Return first N
  //-----------------------------------------------------------------------------------------
  // Sort in descending order of margin and take first N items in list
  // see -> https://masteringjs.io/tutorials/lodash/sortby
  const iteratees = (obj) => -obj.margin_percent;

  const sortedArray = _.sortBy(mappedChannProds, iteratees);

  // Return first skusToGet items in sorted array
  // see -> https://www.geeksforgeeks.org/lodash-_-take-method/
  const limitedArray = _.take(sortedArray, skusToGet);

  // Remove duplicates
  let uniqueProducts = [];
  let seenSKUs = new Set();

  limitedArray.forEach((limitedArray) => {
    if (!seenSKUs.has(limitedArray.sku)) {
      uniqueProducts.push(limitedArray);
      seenSKUs.add(limitedArray.sku);
    }
  });

  return uniqueProducts;
}

//**********************************************************************************************************************************/
// Get Stock Status
//**********************************************************************************************************************************/
export function getStockStatus(product) {
  const qboQty = _.get(product, "inventory_for_product.qty_qbo");
  const boolQboStock = qboQty > 0;

  // Get suppliers - could be an empty array if suppliers not entered
  const suppliers = product.suppliers_for_product;

  // Get Primary Supplier Object if any supplier tagged as primary supplier (picks up first one if there are inadvertently multiple primary suppliers)
  const priSuppObj = _.find(suppliers, { is_primary_supplier: true });

  // Determine if primary supplier has stock
  const boolPriSuppStock = _.get(priSuppObj, "stock_status") === "Y";

  // Get any supplier with stock
  const supplierWithStockObj = _.find(suppliers, { stock_status: "Y" });

  // Determine if any supplier with stock exists
  const boolAnySuppStock = supplierWithStockObj !== undefined;

  // Get name of supplier with stock - if primary supplier has stock, use that. Else use other supplier with stock
  let suppWithStockName;
  if (boolPriSuppStock) {
    suppWithStockName = _.get(priSuppObj, "supplier");
  } else {
    if (boolAnySuppStock) {
      suppWithStockName = _.get(supplierWithStockObj, "supplier");
    } else {
      suppWithStockName = "";
    }
  }

  // Create stock status object
  const boolStockStatus = {
    qbo: boolQboStock,
    qbo_qty: qboQty,
    pri_supp: boolPriSuppStock,
    any_supp: boolAnySuppStock,
    supp_with_stock: suppWithStockName,
  };

  return boolStockStatus;
}

//**********************************************************************************************************************************/
// BUILD STALE COMPE SKUS
//**********************************************************************************************************************************/
export function getFilteredStaleInventory({
  products,
  showCompeStatus,
  marginThreshold,
  freshnessDays,
  skusToGet,
}) {
  //-----------------------------------------------------------------------------------------
  // Deep Clone Prods
  //-----------------------------------------------------------------------------------------
  // Clone Prods so can modify it as we go along
  let filteredProds = JSON.parse(JSON.stringify(products));

  // //-----------------------------------------------------------------------------------------
  // // Filter QBO Stocked
  // //-----------------------------------------------------------------------------------------
  // // Filter out any items that have QBO stock
  // // No need to check supplier stock freshness if have it in stock in QBO
  // if (skusToHide === "HIDE_QBO_STOCKED") {
  //   filteredProds = _.filter(filteredProds, function (p) {
  //     if (p.inventory_for_product !== null) {
  //       return p.inventory_for_product.qty_qbo === 0;
  //     }
  //   });
  // }

  //-----------------------------------------------------------------------------------------
  // Map Remainder
  //-----------------------------------------------------------------------------------------
  const filteredStaleInventory = filteredProds.map((p) => {
    // Get the price for the product instance
    let prodPrice = getBigcPrice(p);

    // Get the cost for the product instance
    // const costObj = cost.get(p);
    // let prodCost = getBigcCost(_.get(costObj, "cost"), p);
    let prodCost = _.get(p, "product_cost");

    // Get margin
    let bigcMarginAbs, bigcMarginPercent;
    if (prodPrice && prodCost) {
      prodPrice = Number(prodPrice);
      prodCost = Number(prodCost);
      const bigcMargin = profit.get(CONFIG.BIGC, prodPrice, prodCost);
      bigcMarginAbs = bigcMargin.margin;
      bigcMarginPercent = bigcMargin.margin_percent;
    }

    // If margin % < threshold margin skip over item
    // Exclude items with margin below target margin
    if (!bigcMarginPercent || bigcMarginPercent < marginThreshold) {
      return null;
    }

    // // Map competitors and add properties that we can search on later
    // let competitors = p.competitors_for_product;

    // let noCompe = false;
    // if (competitors) {
    //   noCompe = competitors.length === 0;
    // }

    // // Unless disregarding compe, need only competitors with stock, filter out competitors with no stock
    // if (showCompeStatus !== "SHOW_ALL") {
    //   competitors = _.filter(competitors, { stock_status: "Y" });
    // }

    // // Remove competitors with a higher price than our price - if we are lowest there would be no competitors left
    // competitors = _.filter(competitors, function (c) {
    //   return c.competitor_item_price < prodPrice;
    // });

    // // If showing items without compe and have any compe that made it through here we will skip this item
    // // Return null to map and will filter out nulls later
    // if (
    //   showCompeStatus === "COMPE_BEATEN" &&
    //   !noCompe &&
    //   competitors.length > 0
    // ) {
    //   // have a compe with inventory and price < rfwel so return null
    //   return null;
    // }

    // Map suppliers and add properties that we can search on later
    // Define status of competitors for this product
    let hasStaleSupplierObject = false;
    let suppliers = p.suppliers_for_product;
    let filteredSupplierObjs;
    if (
      suppliers !== null &&
      suppliers !== undefined &&
      suppliers.length !== 0
    ) {
      // has supplier object
      hasStaleSupplierObject = true;

      // Map the suppliers into object we will use
      const suppObj = suppliers.map((cp) => {
        const supp = buildStaleSupplierObj(cp);
        return supp;
      }); // end map of supplier

      // Exclude any product with last_update within refresh period
      const prodhasFreshSupplier = _.find(suppObj, (s) => {
        // Determine if last update for supplier s is more than freshnessDays ago
        // If expiration date is in the future isDateTAgo returns true
        // If so return object so can exclude this product because suppliers are fresh
        if (isDateTAgo(s.last_update, freshnessDays)) return true;
        return false;
      });

      if (prodhasFreshSupplier !== undefined) {
        // If have an object this product has a fresh compe so exclude
        return null;
      } else {
        // filteredSupplierObjs === undefined means that this product does not have a fresh supplier
        // Get object with closest date (i.e., freshest supplier)
        filteredSupplierObjs = suppObj.reduce((a, b) =>
          a.last_update > b.last_update ? a : b
        );
      }
    }

    // Get the freshest compe name
    const freshestSupplierName = hasStaleSupplierObject
      ? _.get(filteredSupplierObjs, "supplier")
      : "--No Supplier Listed--";

    const freshestSupplierLink = hasStaleSupplierObject
      ? _.get(filteredSupplierObjs, "link")
      : "--";

    // see -> https://date-fns.org/v2.29.3/docs/formatDistanceToNow
    const freshestSupplierLastUpdate = hasStaleSupplierObject
      ? formatDistanceToNow(
          new Date(_.get(filteredSupplierObjs, "last_update")),
          {
            addSuffix: true,
          }
        )
      : "--";

    // Build the object for the product instance
    const staleSuppProd = {
      sku: p.sku,
      category: p.category,
      listat: p.listing_status_bigc,
      channid: p.pid_bigc,
      rfwel_price: prodPrice,
      cost: prodCost,
      margin: bigcMarginAbs,
      margin_percent: bigcMarginPercent,
      freshest_supplier: freshestSupplierName,
      link: freshestSupplierLink,
      supplier_last_update: freshestSupplierLastUpdate,
    };

    return staleSuppProd;
  }); //end map of product

  // Filter out any null values in products list
  const filterOutput = _.filter(filteredStaleInventory, (pf) => pf !== null);

  //-----------------------------------------------------------------------------------------
  // Sort Based on Margin and Return first N
  //-----------------------------------------------------------------------------------------
  // Sort in descending order of margin and take first N items in list
  // see -> https://masteringjs.io/tutorials/lodash/sortby
  const iteratees = (obj) => -obj.margin_percent;

  const sortedArray = _.sortBy(filterOutput, iteratees);

  // Return first skusToGet items in sorted array
  // see -> https://www.geeksforgeeks.org/lodash-_-take-method/
  const limitedArray = _.take(sortedArray, skusToGet);

  return limitedArray;
}

//**********************************************************************************************************************************/
// Default Export - LAST LINE
//**********************************************************************************************************************************/
export default buildChannelProducts;
