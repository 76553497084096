import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  List,
  ListItemIcon,
  Paper,
  Dialog,
  DialogContent,
  DialogActions,
  Collapse,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faEdit,
  faPlus,
  faInfoCircle,
} from "@fortawesome/pro-solid-svg-icons";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import parse from "html-react-parser";
import KthContactUsCommonContent from "../KthContactUsCommonContent";
import ContactUsForm from "../../common/ContactUsForm";
import WorksheetActionButton from "../../common/WorksheetActionButton";
import { useAuth } from "../../../context/AuthContext";
import { useWorksheet } from "../../../context/WorksheetContext";
import { exportToPDF } from "../../../utils/pdfExportUtils";

const WizActionSelectFinished = ({
  wizardType,
  history,
  iconMapping,
  onEdit,
  baseConfig,
}) => {
  const { user } = useAuth();
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const [expandedInfoIndex, setExpandedInfoIndex] = useState(null);
  const [contactFormDefaults, setContactFormDefaults] = useState({});
  const { worksheet, addToWorksheet, replaceInWorksheet } = useWorksheet();
  const wizInWorksheet =
    worksheet?.[wizardType]?.some((wiz) => wiz.wizid === 0) || false;
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const finishedTermShortLabel = "Report";

  const getWizardConfig = (wizardType) => {
    switch (wizardType) {
      case "patent-enforcement":
        return {
          contactFormTit: "Patent Assertion/Defense Help",
          contactFormSubject: "Patent Assertion/Defense Help",
          wizallHeader: "Patent Enforcement Checker Summary",
        };
      case "patent-application":
        return {
          contactFormTit: "Patent Application Help",
          contactFormSubject: "Patent Application Help",
          wizallHeader: "Patent Application Summary",
        };
      case "ai-compliance":
        return {
          contactFormTit: "AI Compliance Help",
          contactFormSubject: "AI Compliance Help",
          wizallHeader: "AI Compliance Checker Summary",
        };
      case "ai-legal-terms":
        return {
          contactFormTit: "AI Legal Terms Help",
          contactFormSubject: "AI Legal Terms Help",
          wizallHeader: "AI Legal Terms Checklist Summary",
        };
      default:
        return {
          contactFormTit: "Contact Us",
          contactFormSubject: "",
          wizallHeader: "Checklist Summary",
        };
    }
  };

  const { contactFormTit, contactFormSubject, wizallHeader } =
    getWizardConfig(wizardType);

  const createWizWorksheetData = (history, wizardType) => {
    return {
      wizid: 0, // for now put all wizard data in ID0
      wizall: [
        {
          header: wizallHeader,
          questions: history
            .filter((entry) => {
              const answer = Array.isArray(entry.selectedOption)
                ? entry.selectedOption.join(", ")
                : entry.selectedOption || "Not answered";
              return answer !== "Not answered"; // Exclude entries with "Not answered" (includes Report term)
            })
            .map((entry) => ({
              question: entry.term,
              answer: Array.isArray(entry.selectedOption)
                ? entry.selectedOption.join(", ")
                : entry.selectedOption,
              notes: entry.optionInfo || null, // Include additional info if available
            })),
        },
      ],
    };
  };

  // Dynamically create wizWorksheetData
  const wizWorksheetData = createWizWorksheetData(history, wizardType);

  const toggleInfo = (index) => {
    setExpandedInfoIndex((prev) => (prev === index ? null : index));
  };

  const openContactForm = () => {
    const defaultValues = {
      subject: contactFormSubject,
      description: ` Enter additional questions/comments below:
------------------------------------------------------------------------------


-------------------------------------------------------------------------------
***DO NOT EDIT BELOW THIS LINE***
-------------------------------------------------------------------------------
${history
  .map(
    (entry, index) =>
      `${index + 1}. ${entry.term} ${
        Array.isArray(entry.selectedOption)
          ? entry.selectedOption.join(", ")
          : entry.selectedOption
      }`
  )
  .join("\n")}
`,
    };

    if (user) {
      setContactFormDefaults({
        ...defaultValues,
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
      });
    } else {
      setContactFormDefaults(defaultValues);
    }
    setContactDialogOpen(true);
  };

  const closeContactForm = () => setContactDialogOpen(false);

  const getFirstParagraph = (htmlString) => {
    const paragraphMatch = htmlString.match(/<p[^>]*>(.*?)<\/p>/);
    return paragraphMatch ? paragraphMatch[1] : ""; // Extracts the text of the first paragraph if found
  };

  const hasMultipleParagraphs = (htmlString) => {
    const paragraphMatches = htmlString.match(/<p[^>]*>(.*?)<\/p>/g);
    return paragraphMatches && paragraphMatches.length > 1;
  };

  return (
    <Box>
      {/* Top Action Buttons */}
      <Box
        display="flex"
        flexDirection={isLargeScreen ? "row" : "column"}
        justifyContent="left"
        alignItems="left"
        gap={isLargeScreen ? 2 : 1}
        mb={isLargeScreen ? 0 : 2}
      >
        <WorksheetActionButton
          inWorksheet={wizInWorksheet}
          worksheetData={wizWorksheetData}
          worksheetType={wizardType}
          addToWorksheet={addToWorksheet}
          replaceInWorksheet={replaceInWorksheet}
        />
        <Button
          variant="outlined"
          startIcon={<FontAwesomeIcon icon={faFilePdf} />}
          onClick={() => exportToPDF([wizWorksheetData], wizardType)}
          sx={{ justifyContent: "flex-start" }}
        >
          Download Report
        </Button>
        <Button
          startIcon={<ConnectWithoutContactIcon />}
          variant="outlined"
          color="primary"
          onClick={openContactForm}
          sx={{ justifyContent: "flex-start" }}
        >
          Submit to Attorney
        </Button>
      </Box>

      {/* End of Wizard Message */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="left"
        sx={{ mt: 2, mb: 2, backgroundColor: "#f0f9f0", p: 2, borderRadius: 1 }}
      >
        <CheckCircleIcon sx={{ color: "green", fontSize: "2rem", mr: 1 }} />
        <Typography variant="body1">
          You have reached the end of the wizard! The information below
          summarizes the details you provided.
        </Typography>
      </Box>

      {/* Summary List */}
      <List>
        {history.map((entry, index) =>
          entry.termShort === finishedTermShortLabel ? null : (
            <Paper key={index} variant="outlined" sx={{ mb: 2, p: 2 }}>
              {/* First Row: term_short with icon */}
              <Box display="flex" alignItems="center">
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={iconMapping[entry.term_subgroup_icon] || faInfoCircle}
                  />
                </ListItemIcon>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  {entry.termShort}
                </Typography>
              </Box>

              {/* Second Row: Question */}
              <Typography variant="body2" sx={{ mt: 1, fontWeight: "bold" }}>
                {entry.term}
              </Typography>

              {/* Third Row: Selected Option with Edit Button */}
              <Box display="flex" alignItems="center" mt={1}>
                {Array.isArray(entry.selectedOption) ? (
                  <ul>
                    {entry.selectedOption.map((item, idx) => (
                      <li key={idx}>{item}</li>
                    ))}
                  </ul>
                ) : (
                  <Typography variant="body2">
                    {entry.selectedOption}
                  </Typography>
                )}

                <IconButton onClick={() => onEdit(index)} sx={{ ml: 2 }}>
                  <FontAwesomeIcon
                    icon={faEdit}
                    style={{ fontSize: "0.8em" }}
                  />
                </IconButton>
              </Box>

              {/* Fourth Row: Option Info (show preview with expandable toggle) */}
              {entry.optionInfo && (
                <Box sx={{ mt: 1 }}>
                  <Typography variant="body2" component="div">
                    {expandedInfoIndex === index ? (
                      parse(entry.optionInfo) // Full text when expanded
                    ) : (
                      <>
                        {parse(`<p>${getFirstParagraph(entry.optionInfo)}</p>`)}
                        {hasMultipleParagraphs(entry.optionInfo) && (
                          <Button
                            variant="text"
                            size="small"
                            onClick={() => toggleInfo(index)}
                            sx={{ ml: 1, fontSize: "0.8em" }}
                          >
                            <ArrowDropDownIcon /> More
                          </Button>
                        )}
                      </>
                    )}
                    {expandedInfoIndex === index && (
                      <Button
                        variant="text"
                        size="small"
                        onClick={() => toggleInfo(index)}
                        sx={{ ml: 1, fontSize: "0.8em" }}
                      >
                        <ArrowDropUpIcon /> Less
                      </Button>
                    )}
                  </Typography>
                </Box>
              )}
            </Paper>
          )
        )}
      </List>

      {/* Contact Us Form Dialog */}
      <Dialog
        open={contactDialogOpen}
        onClose={closeContactForm}
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          <KthContactUsCommonContent>
            {(agreementText, organizationErrorText) => (
              <ContactUsForm
                baseConfig={baseConfig}
                initialValues={contactFormDefaults}
                formTitle={contactFormTit}
                agreementText={agreementText}
                isOrganizationRequired={true}
                organizationErrorText={organizationErrorText}
                onSuccessCallback={closeContactForm} // Close dialog on success
              />
            )}
          </KthContactUsCommonContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeContactForm}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default WizActionSelectFinished;
