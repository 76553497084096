import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  IconButton,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Alert,
} from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faBars,
  faTimes,
  faUser,
  faMessages,
  faBan,
  faBullseyePointer,
  faBallotCheck,
  faBarcodeRead,
  faBuilding,
  faMapLocationDot,
  faCircleExclamation,
  faFileCertificate,
  faFileContract,
  faCartCircleCheck,
  faFileCheck,
  faHandHoldingBox,
  faTrash,
  faSquareCheck,
  faArrowRightFromBracket,
} from "@fortawesome/pro-solid-svg-icons";
import SetDocumentMeta from "../../common/SetDocumentMeta";
import parse from "html-react-parser";
import WizDecisionPoint from "./WizDecisionPoint";
import WizMaintenance from "./WizMaintenance";
import WizActionSelectFinished from "./WizActionSelectFinished";
import WizActionSelectTextField from "./WizActionSelectTextField";
import WizActionSelectOptionField from "./WizActionSelectOptionField";

const apiUrlKth = `${window._env_.REACT_APP_PATANALAI_API_URL}`;

// Maintenance mode toggle
const ENABLE_MAINTENANCE_MODE = false;

// Mapping from icon names in the payload to FontAwesome icon objects
const iconMapping = {
  faBallotCheck: faBallotCheck, // Fallback icon
  faUser: faUser,
  faMessages: faMessages,
  faBullseyePointer: faBullseyePointer,
  faBan: faBan,
  faBuilding: faBuilding,
  faBarcodeRead: faBarcodeRead,
  faFileCertificate: faFileCertificate,
  faFileContract: faFileContract,
  faCircleExclamation: faCircleExclamation,
  faFileCheck: faFileCheck,
  faCartCircleCheck: faCartCircleCheck,
  faHandHoldingBox: faHandHoldingBox,
  faMapLocationDot: faMapLocationDot,
  faSquareCheck: faSquareCheck,
  faArrowRightFromBracket: faArrowRightFromBracket,
};

// Mapping of term_subgroup values to the corresponding custom components
const actionComponents = {
  // "Action-Select-Location": WizActionSelectLocation,
  "Action-Select-Location": (props) => (
    <WizActionSelectOptionField {...props} fieldType="location" />
  ),
  "Action-Select-ASIN": (props) => (
    <WizActionSelectTextField {...props} fieldType="asin" />
  ),
  "Action-Select-Patent": (props) => (
    <WizActionSelectTextField {...props} fieldType="patent" />
  ),
  "Action-Select-Market": (props) => (
    <WizActionSelectOptionField {...props} fieldType="market" />
  ),
  "Action-Select-Product": (props) => (
    <WizActionSelectOptionField {...props} fieldType="productType" />
  ),
  "Action-Select-Finished": (props) => (
    <WizActionSelectFinished {...props} iconMapping={iconMapping} />
  ),
};

const WizMain = ({ baseConfig, wizardType }) => {
  const {
    priColor,
    priHoverColor,
    tertColor,
    tertHoverColor,
    quadColor,
    quadHoverColor,
  } = baseConfig.siteBranding;
  const [terms, setTerms] = useState([]);
  const [currentStep, setCurrentStep] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const [currentHistoryIndex, setCurrentHistoryIndex] = useState(0);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [isSidebarOpen, setIsSidebarOpen] = useState(isLargeScreen);

  // Effect to sync sidebar open state with screen size
  useEffect(() => {
    setIsSidebarOpen(isLargeScreen);
  }, [isLargeScreen]);

  useEffect(() => {
    if (!ENABLE_MAINTENANCE_MODE) {
      resetState();
      fetchTerms();
    }
  }, [wizardType]);

  const getWizardConfig = (wizardType) => {
    switch (wizardType) {
      case "patent-enforcement":
        return {
          apiEndpoint: "/kth-patent/terms?scope=patent-enforcement",
          wizTit: "Patent Enforcement Wizard",
          wizBlurb:
            "The Wizard provides insights for both patent owners wishing to enforce their patents, and businesses defending \
            against patent infringement claims.",
          pageTitle:
            "Patent Enforcement Checklist | Kama Thuo, PLLC Patent Law",
          pageMetaDescription: "",
          pageMetaKeywords: "",
          returnUrl: "/patents",
          returnMessage: "Return to Patents Service page",
        };
      case "patent-application":
        return {
          apiEndpoint: "/kth-patent/terms?scope=patent-application",
          wizTit: "Patent Application Wizard",
          wizBlurb: "",
          pageTitle:
            "Patent Application Checklist | Kama Thuo, PLLC Patent Law",
          pageMetaDescription: "",
          pageMetaKeywords: "",
          returnUrl: "/patents",
          returnMessage: "Return to Patents Service page",
        };
      case "ai-compliance":
        return {
          apiEndpoint: "/kth-ai/terms?scope=ai-compliance",
          wizTit: "AI Compliance Checker",
          wizBlurb: "",
          pageTitle: "AI Compliance Checker | Kama Thuo, PLLC AI Law",
          pageMetaDescription: "",
          pageMetaKeywords: "",
          returnUrl: "/ai",
          returnMessage: "Return to AI Service page",
        };
      case "ai-legal-terms":
        return {
          apiEndpoint: "/kth-ai/terms?scope=ai-legal-terms",
          wizTit: "AI Legal Terms Checker",
          wizBlurb: "",
          pageTitle: "AI Legal Terms Checker | Kama Thuo, PLLC AI Law",
          pageMetaDescription: "",
          pageMetaKeywords: "",
          returnUrl: "/ai",
          returnMessage: "Return to AI Service page",
        };
      default:
        return {
          apiEndpoint: "",
          wizTit: "Wizard",
          wizBlurb: "",
          pageTitle: "Wizard",
          pageMetaDescription: "",
          pageMetaKeywords: "",
          returnUrl: "/",
          returnMessage: "Return to Home page",

          //   termGroup: "",
        };
    }
  };

  const {
    apiEndpoint,
    wizTit,
    wizBlurb,
    pageTitle,
    pageMetaDescription,
    pageMetaKeywords,
    returnUrl,
    returnMessage,
  } = getWizardConfig(wizardType);

  SetDocumentMeta({
    title: pageTitle,
    description: pageMetaDescription,
    keywords: pageMetaKeywords,
  });

  const fetchTerms = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${apiUrlKth}${apiEndpoint}`);
      const sortedTerms = response.data.sort((a, b) => a.ordering - b.ordering);
      setTerms(sortedTerms);
      if (sortedTerms.length > 0) {
        const initialStep = sortedTerms[0].id;
        setCurrentStep(initialStep);
        // Add the initial step to the history
        setHistory([
          {
            step: initialStep,
            termShort: sortedTerms[0].term_short,
            selectedOption: null,
          },
        ]);
        setCurrentHistoryIndex(0);
      }
    } catch (error) {
      setError("Failed to fetch data.");
      console.error("Failed to fetch terms:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const resetState = () => {
    setTerms([]);
    setCurrentStep(null);
    setHistory([]);
    setCurrentHistoryIndex(0);
  };

  const nextStep = (nextStepId, selectedOption, optionInfo = "") => {
    const newHistory = [...history];
    const nextIndex = currentHistoryIndex + 1;
    const currentTerm = terms.find((term) => term.id === currentStep);
    const nextTerm = terms.find((term) => term.id === nextStepId);

    // If the next term is a branch node and navigating from a previous state, trim the history
    if (currentTerm?.is_branch_node && nextIndex < newHistory.length) {
      newHistory.splice(nextIndex);
    }

    // Update the current history item with selected option and additional data
    newHistory[currentHistoryIndex] = {
      ...newHistory[currentHistoryIndex],
      selectedOption,
      optionInfo, // Additional data for the selected option
      term: currentTerm.term, // Store the term (question)
      term_subgroup_icon: currentTerm.term_subgroup_icon, // Store the icon
    };

    // If navigating forward, add a new entry
    if (nextIndex === newHistory.length && nextTerm) {
      newHistory.push({
        step: nextStepId,
        termShort: nextTerm.term_short,
        selectedOption: null,
        optionInfo: "", // Initialize with empty info
        term: nextTerm.term,
        term_subgroup_icon: nextTerm.term_subgroup_icon,
      });
    }

    setHistory(newHistory);
    setCurrentHistoryIndex(nextIndex);
    setCurrentStep(nextStepId);
  };

  const goBack = () => {
    if (currentHistoryIndex > 0) {
      const previousIndex = currentHistoryIndex - 1;
      setCurrentHistoryIndex(previousIndex);
      setCurrentStep(history[previousIndex].step);
    }
  };

  const goForward = () => {
    if (currentHistoryIndex < history.length - 1) {
      const nextIndex = currentHistoryIndex + 1;
      setCurrentHistoryIndex(nextIndex);
      setCurrentStep(history[nextIndex].step);
    }
  };

  const resetWizard = () => {
    setHistory([]);
    setCurrentHistoryIndex(0);
    setCurrentStep(terms.length > 0 ? terms[0].id : null);
    if (terms.length > 0) {
      // Reinitialize the first history entry
      setHistory([
        {
          step: terms[0].id,
          termShort: terms[0].term_short,
          selectedOption: null,
        },
      ]);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleEdit = (index) => {
    setCurrentHistoryIndex(index);
    setCurrentStep(history[index].step);
  };

  const renderOptions = () => {
    const stepData = terms.find((term) => term.id === currentStep);

    // Display "Contact Us" message if stepData is not found
    if (!stepData) {
      return (
        <Typography>
          Please <RouterLink to="/contact">Contact us</RouterLink> for
          additional assistance.
        </Typography>
      );
    }

    // Get options based on wizardType
    const options = wizardType.startsWith("patent")
      ? stepData.options_for_patent_term
      : stepData.options_for_ai_term;

    // If options are undefined, display a message
    if (!options) {
      return (
        <Typography>
          No options available for this step. Please try another action.
        </Typography>
      );
    }

    // Get saved selection for this step from history, if available
    const savedSelection =
      history.find((entry) => entry.step === currentStep)?.selectedOption ||
      null;

    // Check if term_subgroup starts with "Action-" and render the appropriate Action component
    if (
      stepData.term_subgroup &&
      stepData.term_subgroup.startsWith("Action-")
    ) {
      const ActionComponent = actionComponents[stepData.term_subgroup];
      const nextStepId = options[0]?.next_step; // Get next step from options
      const actionComponentOptionInfo = options[0]?.info; // Get info from options

      if (ActionComponent) {
        return (
          <ActionComponent
            wizardType={wizardType}
            baseConfig={baseConfig}
            question={stepData.term}
            questionInfo={stepData.info}
            initialValue={savedSelection} // Pass saved selection as initialValue
            onComplete={(selectedOption, optionInfo) => {
              const combinedOptionInfo = `${actionComponentOptionInfo} ${
                optionInfo || ""
              }`;
              nextStep(nextStepId, selectedOption, combinedOptionInfo.trim());
            }}
            history={history} // only needed for Action-Finished to print history
            onEdit={handleEdit} // only needed for Action-Finished to navigate back to history
          />
        );
      }
    }

    // Sort and filter the options if they exist
    const sortedOptions = options
      .filter((option) => option.in_decision_flow)
      .sort((a, b) => a.ordering - b.ordering);

    return (
      <WizDecisionPoint
        baseConfig={baseConfig}
        question={stepData.term}
        questionInfo={stepData.info}
        options={sortedOptions}
        onAnswer={(nextStepId, text, optionInfo) =>
          nextStep(nextStepId, text, optionInfo)
        }
        selectedOption={history[currentHistoryIndex]?.selectedOption || ""}
      />
    );
  };

  if (ENABLE_MAINTENANCE_MODE) {
    return <WizMaintenance baseConfig={baseConfig} wizardType={wizardType} />;
  }

  if (isLoading)
    return (
      <Container>
        <CircularProgress />{" "}
      </Container>
    );
  if (error)
    return (
      <Container>
        <Grid item xs={12}>
          <Alert severity="error" sx={{ mt: 4 }}>
            {error}
          </Alert>
          <Button
            startIcon={<FirstPageIcon />}
            color="primary"
            component={RouterLink}
            to={returnUrl}
            sx={{ mt: 2, mb: 2 }}
          >
            {returnMessage}
          </Button>
        </Grid>
      </Container>
    );

  return (
    <Container>
      <Typography variant="h4" align="center" sx={{ mt: 2 }}>
        {wizTit}
      </Typography>
      <Typography variant="body2" align="center" sx={{ mt: 2, mb: 2 }}>
        {wizBlurb}
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={isLargeScreen ? 3 : isSidebarOpen ? 3 : 1}>
          {!isLargeScreen && (
            <IconButton onClick={toggleSidebar} sx={{ mb: 1 }}>
              <FontAwesomeIcon icon={isSidebarOpen ? faTimes : faBars} />
            </IconButton>
          )}
          <Paper
            elevation={3}
            className={
              isSidebarOpen
                ? "wiz-flowchart-sidebar-open"
                : "wiz-flowchart-sidebar-closed"
            }
            style={{ maxHeight: "80vh", overflow: "hidden" }}
            sx={{ mt: 1 }}
          >
            <List component="nav">
              {isSidebarOpen && (
                <ListItem>
                  <ListItemText
                    align="center"
                    primary={
                      <Typography variant="h6" fontWeight="bold" align="center">
                        Summary
                      </Typography>
                    }
                  />
                </ListItem>
              )}
              {history.map((entry, index) => (
                <ListItem disablePadding key={index}>
                  <ListItemButton
                    onClick={() => {
                      setCurrentHistoryIndex(index);
                      setCurrentStep(entry.step);
                    }}
                    selected={index === currentHistoryIndex}
                    style={{
                      backgroundColor:
                        index === currentHistoryIndex ? tertColor : "inherit",
                      color: index === currentHistoryIndex ? "#fff" : "inherit",
                    }}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon
                        icon={
                          iconMapping[
                            terms.find((term) => term.id === entry.step)
                              ?.term_subgroup_icon
                          ] || faBallotCheck
                        }
                        style={{
                          color:
                            index === currentHistoryIndex ? "#fff" : "inherit",
                        }}
                      />
                    </ListItemIcon>
                    {isSidebarOpen && (
                      <ListItemText primary={entry.termShort} />
                    )}
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={9}>
          <Paper elevation={3} sx={isLargeScreen ? { ml: 0 } : { ml: 2 }}>
            <Box p={2} className="wiz-flowchart-block">
              {renderOptions()}
            </Box>

            <Box display="flex" alignItems="center" mt={3}>
              {currentHistoryIndex > 0 ? (
                <Button
                  startIcon={
                    isLargeScreen ? <FontAwesomeIcon icon={faArrowLeft} /> : ""
                  }
                  onClick={goBack}
                  variant="contained"
                  color="secondary"
                  sx={{
                    ml: 2,
                    mb: 2,
                    backgroundColor: priColor,
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: priHoverColor,
                      color: "#fff",
                    },
                  }}
                >
                  {isLargeScreen ? (
                    "Back"
                  ) : (
                    <FontAwesomeIcon icon={faArrowLeft} />
                  )}
                </Button>
              ) : (
                // Empty Box for spacing
                <Box sx={{ width: 100, ml: 2, mb: 2 }} />
              )}

              <Box display="flex" justifyContent="center" flexGrow={1}>
                <Button
                  startIcon={
                    isLargeScreen ? <FontAwesomeIcon icon={faTrash} /> : ""
                  }
                  onClick={resetWizard}
                  variant="contained"
                  sx={{
                    mb: 2,
                    bgcolor: "error.main", // Red background color
                    "&:hover": { bgcolor: "error.dark" }, // Darker red on hover
                    color: "#fff", // White text color
                  }}
                >
                  {isLargeScreen ? "Reset" : <FontAwesomeIcon icon={faTrash} />}
                </Button>
              </Box>

              {currentHistoryIndex < history.length - 1 ? (
                <Button
                  endIcon={
                    isLargeScreen ? <FontAwesomeIcon icon={faArrowRight} /> : ""
                  }
                  onClick={goForward}
                  variant="contained"
                  color="primary"
                  sx={{
                    mb: 2,
                    mr: 2,
                    backgroundColor: tertColor,
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: tertHoverColor,
                      color: "#fff",
                    },
                  }}
                >
                  {/* {isLargeScreen ? (
                    "Forward"
                  ) : (
                    <FontAwesomeIcon icon={faArrowRight} />
                  )} */}
                  {isLargeScreen ? (
                    terms.find(
                      (term) =>
                        term.id === history[currentHistoryIndex + 1]?.step
                    )?.term_subgroup === "Action-Select-Finished" ? (
                      "Complete"
                    ) : (
                      "Forward"
                    )
                  ) : (
                    <FontAwesomeIcon icon={faArrowRight} />
                  )}
                </Button>
              ) : (
                // Empty Box for spacing
                <Box sx={{ width: 100, mr: 2, mb: 2 }} />
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default WizMain;
