import React from "react";
import {
  Typography,
  Container,
  Paper,
  Grid,
  Card,
  CardContent,
  Box,
} from "@mui/material";
import { Business, Gavel, Wifi, SmartToy, Science } from "@mui/icons-material";
import SetDocumentMeta from "../common/SetDocumentMeta";

const sections = [
  {
    title: "Patent Practice",
    icon: <Gavel fontSize="large" color="primary" />,
    content: `We help clients secure, optimize, and monetize their intellectual property portfolios, particularly in high-tech electrical innovations. 
    Our expertise spans AI/ML, integrated circuits, antennas, embedded systems, wireless communications, medical devices, radio frequency circuits and systems, and more.`,
  },
  {
    title: "AI Counseling",
    icon: <SmartToy fontSize="large" color="primary" />,
    content: `We provide legal guidance for AI startups and enterprises, including drafting AI-related agreements. 
    We also evaluate AI tools and automation workflows for legal risks in complex technical applications, pre-process, label, and annotate AI training data for legal applications.`,
  },
  {
    title: "Wireless Telecom Law",
    icon: <Wifi fontSize="large" color="primary" />,
    content: `We assist clients with FCC compliance, spectrum licensing, equipment authorization, wireless infrastructure deployment, antenna siting, and emerging wireless technologies.`,
  },
  {
    title: "Technology Transactions & Licensing",
    icon: <Business fontSize="large" color="primary" />,
    content: `Our firm provides expertise in patent licensing, IP valuation, technology transactions, and strategic counseling to help clients monetize their innovations effectively.`,
  },
  {
    title: "Regulatory & Policy Advocacy",
    icon: <Science fontSize="large" color="primary" />,
    content: `We represent clients in regulatory matters before the FCC and other agencies, providing strategic advocacy and compliance support for wireless and AI technologies.`,
  },
];

const AboutKth = () => {
  SetDocumentMeta({
    title: "About Us | Kama Thuo, PLLC",
    description: "",
    keywords: "",
  });
  return (
    <Container className="link-no-decoration" sx={{ py: 5 }}>
      <Typography variant="h3" align="center" gutterBottom>
        About Kama Thuo, PLLC
      </Typography>
      <Typography variant="h6" align="center" color="textSecondary" paragraph>
        Kama Thuo, PLLC is a boutique law firm specializing in patent law, AI
        legal counseling, and wireless telecommunications law. Founded by Roque
        Thuo, an experienced patent attorney and licensed professional engineer
        (Electrical), we bring a unique combination of BigLaw expertise and
        hands-on engineering experience.
      </Typography>

      <Grid container spacing={4} sx={{ mt: 4 }}>
        {sections.map((section, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                p: 2,
              }}
            >
              <Box sx={{ mt: 2 }}>{section.icon}</Box>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {section.title}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  textAlign="left"
                >
                  {section.content}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default AboutKth;
