import React from "react";
import { Typography, Box } from "@mui/material";

const NumberedList = ({ title, items, numColor }) => {
  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        {title}
      </Typography>
      <ol
        style={{
          paddingLeft: "1rem",
          margin: 0,
          listStyleType: "none", // Remove default numbering
        }}
      >
        {items.map((item, index) => (
          <li
            key={index}
            style={{
              display: "flex",
              marginBottom: "0.5rem",
              position: "relative", // For proper alignment
            }}
          >
            <Typography
              component="span"
              sx={{
                color: numColor,
                fontWeight: "bold",
                fontSize: "1.2rem",
                minWidth: "2rem", // Fixed width for the number
                mr: 1,
                lineHeight: 1.5,
              }}
            >
              {index + 1}.
            </Typography>
            <Typography
              component="div" // Changed to div to properly contain HTML content
              variant="body1"
              sx={{
                lineHeight: 1.5,
                flex: 1, // Take up remaining space
              }}
              dangerouslySetInnerHTML={{ __html: item }}
            />
          </li>
        ))}
      </ol>
    </Box>
  );
};

export default NumberedList;
