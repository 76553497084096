import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faFilePdf,
  faExternalLinkAlt,
  faLink,
  faToggleOn,
  faGlobe,
  faSection,
  faConveyorBeltArm,
  faShieldHalved,
  faFlagUsa,
  faScaleBalanced,
  faGavel,
  faBadgeSheriff,
  faIndustry,
  faMessageBot,
  faFileCertificate,
  faPrintMagnifyingGlass,
  faFileCheck,
  faBracketsCurly,
  faScroll,
  faList,
} from "@fortawesome/pro-solid-svg-icons";
import {
  Typography,
  Container,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Grid,
  Button,
  Alert,
  InputAdornment,
  Box,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogActions,
  Divider,
  Stack,
  useMediaQuery,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import SearchIcon from "@mui/icons-material/Search";
import KthContactUsCommonContent from "./KthContactUsCommonContent";
import ContactUsForm from "../common/ContactUsForm";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import ConstructionIcon from "@mui/icons-material/Construction";

import parse from "html-react-parser";
import Pagination from "../common/Pagination";
import * as CONFIG from "./utils/kthGlobalLabelsVars";
import SetDocumentMeta from "../common/SetDocumentMeta";

const apiUrlKth = `${window._env_.REACT_APP_PATANALAI_API_URL}`;

// Set ITEMS_PER_PAGE based on environment
const environment = window._env_.REACT_APP_ENVIRONMENT || "production";
const ITEMS_PER_PAGE = environment === "production" ? 10 : 5;

// Set # of jurisdictions to show in drop-down list
const SHOW_JURISDICTIONS = environment === "production" ? 6 : 4;

const statusColors = {
  Active: "#28a745", // Green
  "Partially Active": "#ffc107", // Yellow/Orange
  // Pending: "#17a2b8", // Blue
  Pending: "red",
  Withdrawn: "red",
  Unknown: "#6c757d", // Gray
};

const scrollToElement = (id) => {
  const element = document.getElementById(id);
  if (element) {
    const offset = 20; // Adjust this value as needed
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
};

const Compliance = ({ scope, baseConfig }) => {
  const {
    priColor,
    priHoverColor,
    tertColor,
    tertHoverColor,
    quadColor,
    quadHoverColor,
  } = baseConfig.siteBranding;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const navigate = useNavigate();
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const [compliances, setCompliances] = useState([]);
  const [filteredCompliances, setFilteredCompliances] = useState([]);
  const [complianceTypes, setComplianceTypes] = useState([]);
  const [complianceStatuses, setComplianceStatuses] = useState([]);
  const [complianceJurisdictions, setComplianceJurisdictions] = useState([]);
  const [currentPage, setCurrentPage] = useState({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [skipInitialEffect, setSkipInitialEffect] = useState(true);
  const [filters, setFilters] = useState({
    compliance_type: "",
    compliance_status: "",
    compliance_jurisdiction: "",
    search: "",
    importance: "all",
  });

  // Title and Labels Based on Scope
  const getComplianceConfig = (scope) => {
    switch (scope) {
      case "ai":
        return {
          complianceTitle: "AI Legal Compliance",
          searchLabel: "Search AI Compliance",
          detailLinkBase: "/legal-compliance-for-ai",
          infoBlockTit: "Automated AI Compliance Checker",
          infoBlockBLurb:
            "Evaluate AI compliance and risks for your AI applications and AI tool use (U.S. entities only).",
          infoBlockLink: "/compliance-checker-for-ai",
          infoBlockLinkLabel: "Start Compliance Check",
          contactHelpBlurb:
            "Need Help Now? Contact Us to Discuss Your AI Legal Compliance.",
          contactFormTit: "AI Compliance Help",
          pageTitle: "AI Compliance Data for Legal Assistance",
          pageMetaDescription:
            "Legal services for AI compliance, data privacy, and regulatory standards by AI counseling attorneys.",
          pageMetaKeywords:
            "AI compliance legal services, AI regulatory compliance, data privacy regulations, AI legal counseling, \
            AI risk management, AI model governance, ethical AI practices, AI compliance law",
        };
      case "wireless":
        return {
          complianceTitle: "Wireless Legal Compliance",
          searchLabel: "Search Wireless Compliance",
          detailLinkBase: "/wireless-legal-compliance",
          infoBlockTit: "Wireless Telecom Law",
          infoBlockBLurb:
            "Legal counseling on wireless telecommunications — devices, networks, standards, sites, spectrum, regulations, and more.",
          infoBlockLink: "/wireless",
          infoBlockLinkLabel: "Learn More",
          contactHelpBlurb:
            "Need Help Now? Contact Us to Discuss Your Wireless Compliance Needs.",
          contactFormTit: "Wireless Legal Compliance Help",
          pageTitle: "Wireless Compliance Data | Telecom Law Help",
          pageMetaDescription:
            "Wireless compliance data compiled by Kama Thuo, PLLC's wireless telecom attorneys can be used to provide legal assistance \
               for wireless regulatory compliance (e.g., FCC compliance), spectrum allocation legal asssistance, and more.",
          pageMetaKeywords:
            "wireless compliance legal services, wireless telecom attorneys, FCC regulations, \
              spectrum allocation legal assistance, equipment authorization, wireless telecom compliance law, U.S. wireless regulations, telecom legal compliance, \
              CE marking, FCC labeling requirement, Legal advice for wireless certification",
        };
      default:
        return {
          complianceTitle: "Compliance",
          searchLabel: "Search Compliance",
          detailLinkBase: "/",
          infoBlockTit: "Automated Compliance Checker",
          infoBlockBLurb: "Evaluate compliance",
          infoBlockLink: "/",
          infoBlockLinkLabel: "Start Compliance Check",
          contactHelpBlurb:
            "Need Help Now? Contact Us to Discuss Your Compliance Needs.",
          contactFormTit: "Compliance Help",
          pageTitle: "Compliance",
          pageMetaDescription: "",
          pageMetaKeywords: "",
        };
    }
  };

  const {
    complianceTitle,
    searchLabel,
    detailLinkBase,
    infoBlockTit,
    infoBlockBLurb,
    infoBlockLink,
    infoBlockLinkLabel,
    contactHelpBlurb,
    contactFormTit,
    pageTitle,
    pageMetaDescription,
    pageMetaKeywords,
  } = getComplianceConfig(scope);

  SetDocumentMeta({
    title: pageTitle,
    description: pageMetaDescription,
    keywords: pageMetaKeywords,
  });

  // Update filters based on URL query parameters
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const complianceType = queryParams.get("type") || "";
    const complianceStatus = queryParams.get("status") || "";
    const complianceJurisdiction = queryParams.get("jurisdiction") || "";
    const search = queryParams.get("search") || "";

    setFilters((prevFilters) => ({
      ...prevFilters,
      compliance_type: complianceType,
      compliance_status: complianceStatus,
      compliance_jurisdiction: complianceJurisdiction,
      search: search,
    }));
    setSkipInitialEffect(false); // Allow subsequent effects to run
    fetchComplianceData();
  }, [location.search]);

  // Apply filters to content
  useEffect(() => {
    if (skipInitialEffect) return; // Skip this effect during initialization
    applyFilters();
    updateURLWithFilters();
  }, [filters, compliances]);

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    if (hash) {
      scrollToElement(hash);
    }
  }, []);

  const fetchComplianceData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${apiUrlKth}/kth-ai/compliances`, {
        params: { scope },
      });
      const data = response.data;
      // Sort standards by ordering field
      data.sort((a, b) => a.ordering - b.ordering);
      setCompliances(data);
      setFilteredCompliances(data);

      const uniqueComplianceTypes = Array.from(
        new Set(
          data
            .filter((compliance) => compliance.compliance_type?.slug) // Ensure compliance_type exists
            .map((compliance) => compliance.compliance_type.slug)
        )
      ).map(
        (slug) =>
          data.find((compliance) => compliance.compliance_type?.slug === slug)
            .compliance_type
      );

      const uniqueComplianceStatuses = Array.from(
        new Set(
          data
            .filter((compliance) => compliance.compliance_status) // Ensure compliance_status exists
            .map((compliance) => compliance.compliance_status)
        )
      );

      const uniqueComplianceJurisdictions = Array.from(
        new Set(
          data
            .filter((compliance) => compliance.compliance_jurisdiction?.slug) // Ensure compliance_jurisdiction exists
            .map((compliance) => compliance.compliance_jurisdiction.slug)
        )
      ).map(
        (slug) =>
          data.find(
            (compliance) => compliance.compliance_jurisdiction?.slug === slug
          ).compliance_jurisdiction
      );

      setComplianceTypes(uniqueComplianceTypes);
      setComplianceStatuses(uniqueComplianceStatuses);
      setComplianceJurisdictions(uniqueComplianceJurisdictions);
    } catch (err) {
      setError("Failed to fetch compliance data.");
      console.error("Error fetching data:", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchComplianceData();
  }, [scope]);

  const applyFilters = () => {
    const {
      compliance_type,
      compliance_status,
      compliance_jurisdiction,
      search,
      importance,
    } = filters;

    let filtered = compliances.filter((compliance) => {
      const matchesComplianceType =
        !compliance_type || compliance.compliance_type.slug === compliance_type;
      const matchesComplianceStatus =
        !compliance_status ||
        compliance.compliance_status === compliance_status;
      const matchesComplianceJurisdiction =
        !compliance_jurisdiction ||
        (compliance.compliance_jurisdiction &&
          compliance.compliance_jurisdiction.slug === compliance_jurisdiction);
      const matchesImportance =
        importance === "all" ||
        ["Featured", "Important"].includes(compliance.importance);
      const matchesSearch =
        !search ||
        compliance.name.toLowerCase().includes(search.toLowerCase()) ||
        (compliance.brief_legal_description &&
          compliance.brief_legal_description
            .toLowerCase()
            .includes(search.toLowerCase())) ||
        (compliance.brief_technical_description &&
          compliance.brief_technical_description
            .toLowerCase()
            .includes(search.toLowerCase()));

      return (
        matchesComplianceType &&
        matchesComplianceStatus &&
        matchesComplianceJurisdiction &&
        (importance === "all" || matchesImportance) &&
        matchesSearch
      );
    });

    setFilteredCompliances(filtered);
  };

  const handleSearchChange = (event) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      search: event.target.value,
    }));
    setCurrentPage({}); // Reset the currentPage state to reset to page 1
  };

  const handleFilterChange = (event) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [event.target.name]: event.target.value,
    }));
    setCurrentPage({}); // Reset the currentPage state to reset to page 1
  };

  const handleImportanceChange = (event) => {
    setFilters({ ...filters, importance: event.target.value });
    setCurrentPage({}); // Reset the currentPage state to reset to page 1
  };

  const updateURLWithFilters = () => {
    const queryParams = new URLSearchParams();

    if (filters.compliance_type)
      queryParams.set("type", filters.compliance_type);
    if (filters.compliance_status)
      queryParams.set("status", filters.compliance_status);
    if (filters.compliance_jurisdiction)
      queryParams.set("jurisdiction", filters.compliance_jurisdiction);
    if (filters.search) queryParams.set("search", filters.search);

    const newUrl = queryParams.toString()
      ? `${detailLinkBase}?${queryParams.toString()}`
      : { detailLinkBase };

    navigate(newUrl, { replace: true });
  };

  const clearFilters = () => {
    setFilters({
      compliance_type: "",
      compliance_status: "",
      compliance_jurisdiction: "",
      search: "",
      importance: "all",
    });
  };

  const handlePageChange = (contentType, page) => {
    setCurrentPage({
      ...currentPage,
      [contentType]: page,
    });
  };

  const groupedCompliances = filteredCompliances.reduce((acc, compliance) => {
    const type = compliance.compliance_type.slug || "Unknown Type";
    if (!acc[type]) {
      acc[type] = {
        name: compliance.compliance_type.name,
        items: [],
      };
    }
    acc[type].items.push(compliance);
    return acc;
  }, {});

  const complianceTypeIcons = {
    "quasi-government-report": faFileCertificate,
    "model-rule": faPrintMagnifyingGlass,
    "white-papers": faFileCheck,
    "genai-chat-bots": faMessageBot,
    statute: faSection,
    // "military": faConveyorBeltArm,
    regulation: faFlagUsa,
    "court-opinion": faScaleBalanced,
    "court-rule": faGavel,
    military: faBadgeSheriff,
    industry: faIndustry,
    "executive-order": faScroll,
    "ethical-framework": faList,
  };

  const openContactForm = () => {
    setContactDialogOpen(true);
  };

  const closeContactForm = () => {
    setContactDialogOpen(false);
  };

  const _renderInformationBlocks = () => {
    return (
      <>
        {/* Information Block 1 */}
        <Grid item xs={12} md={6}>
          <Card
            variant="outlined"
            sx={{
              backgroundColor: "#f8f9fa",
              // borderLeft: "4px solid #28a745",
              borderLeft: "4px solid #4ABDAC",
              display: "flex",
              alignItems: "center",
              height: "100%", // Ensure this card stretches to full height
            }}
          >
            <CardContent sx={{ display: "flex", alignItems: "center" }}>
              <Box>
                <Typography variant="h6" gutterBottom>
                  {infoBlockTit}
                </Typography>

                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  gutterBottom
                >
                  {infoBlockBLurb}
                </Typography>
                <Button
                  variant="contained"
                  color="success"
                  sx={{
                    mt: 2,
                    backgroundColor: tertColor,
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: tertHoverColor,
                      color: "#fff",
                    },
                  }}
                  component={RouterLink}
                  to={infoBlockLink}
                  endIcon={<ArrowForwardIosIcon />}
                >
                  {infoBlockLinkLabel}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Information Block 2 */}
        <Grid item xs={12} md={6}>
          <Card
            variant="outlined"
            sx={{
              backgroundColor: "#f8f9fa",
              borderLeft: "4px solid #007bff",
              height: "100%", // Ensure this card stretches to full height
            }}
          >
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {contactHelpBlurb}
              </Typography>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                // spacing={2}
                alignItems={isSmallScreen ? "left" : "center"}
              >
                <Box>
                  <Typography
                    variant="subtitle1"
                    color="textPrimary"
                    gutterBottom
                  >
                    Call us for a free consultation
                  </Typography>
                  <Typography
                    variant="h5"
                    color="textPrimary"
                    sx={{ fontWeight: "bold" }}
                  >
                    +1-602-837-0631
                  </Typography>
                </Box>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ ml: 3, mr: 3 }}
                />
                <Button
                  startIcon={<ConnectWithoutContactIcon />}
                  variant="contained"
                  color="primary"
                  onClick={openContactForm}
                  endIcon={<ArrowForwardIosIcon />}
                  sx={{ maxWidth: "200px", maxHeight: "40px" }}
                >
                  Contact Us
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        {/* Contact Us Form Dialog */}
        <Dialog
          open={contactDialogOpen}
          onClose={closeContactForm}
          maxWidth="md"
          fullWidth
        >
          <DialogContent>
            <KthContactUsCommonContent>
              {(agreementText, organizationErrorText) => (
                <ContactUsForm
                  baseConfig={baseConfig}
                  formTitle={contactFormTit}
                  agreementText={agreementText}
                  isOrganizationRequired={true}
                  organizationErrorText={organizationErrorText}
                  onSuccessCallback={closeContactForm} // Close dialog on success
                />
              )}
            </KthContactUsCommonContent>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeContactForm}>Close</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  return (
    <Container className="link-no-decoration">
      <Typography variant="h4" sx={{ mt: 4 }}>
        {complianceTitle}
      </Typography>

      <Box sx={{ mt: 2 }}>
        {/* Information Blocks Container */}
        <Grid container spacing={2} alignItems="stretch">
          {_renderInformationBlocks()}
        </Grid>
      </Box>

      <Typography variant="h4" sx={{ mt: 4 }}>
        {searchLabel}
      </Typography>

      <Grid container spacing={2} sx={{ mt: 2, mb: 4 }}>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            label={`Search by name or description`}
            value={filters.search}
            onChange={handleSearchChange}
            variant="outlined"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Compliance Type</InputLabel>
            <Select
              name="compliance_type"
              value={filters.compliance_type}
              onChange={handleFilterChange}
              label="Compliance Type"
            >
              <MenuItem value="">
                <em>All Types</em>
              </MenuItem>
              {complianceTypes.map((type) => (
                <MenuItem key={type.slug} value={type.slug}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Compliance Status</InputLabel>
            <Select
              name="compliance_status"
              value={filters.compliance_status}
              onChange={handleFilterChange}
              label="Compliance Status"
            >
              <MenuItem value="">
                <em>All Statuses</em>
              </MenuItem>
              {complianceStatuses.map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Jurisdiction</InputLabel>
            <Select
              name="compliance_jurisdiction"
              value={filters.compliance_jurisdiction}
              onChange={handleFilterChange}
              label="Jurisdiction"
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: SHOW_JURISDICTIONS * 48, // Limit the height for the visible options
                    overflowY: "auto", // Enable vertical scroll
                  },
                },
              }}
            >
              <MenuItem value="">
                <em>All Jurisdictions</em>
              </MenuItem>
              {complianceJurisdictions.map((jurisdiction) => (
                <MenuItem key={jurisdiction.slug} value={jurisdiction.slug}>
                  {jurisdiction.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Box sx={{ mb: 3 }}>
        <FormControl component="fieldset" sx={{ mr: 2, mb: 2 }}>
          <RadioGroup
            row
            name="importance"
            value={filters.importance}
            onChange={handleImportanceChange}
          >
            <FormControlLabel
              value="all"
              control={<Radio />}
              label="Show All"
            />
            <FormControlLabel
              value="key"
              control={<Radio />}
              label="Show Key Compliances"
            />
          </RadioGroup>
        </FormControl>
        <Button
          startIcon={<SearchOffIcon />}
          onClick={clearFilters}
          variant="outlined"
          sx={{ mb: 2 }}
        >
          Clear Filters
        </Button>
      </Box>

      {filters.search ||
      filters.compliance_type ||
      filters.compliance_status ||
      filters.compliance_jurisdiction ||
      filters.importance !== "all" ? (
        <Alert
          severity="info"
          action={
            <Button color="inherit" size="small" onClick={clearFilters}>
              Show All
            </Button>
          }
        >
          {filters.search ||
          filters.compliance_type ||
          filters.compliance_status ||
          filters.compliance_jurisdiction ? (
            <>
              Showing results for
              {filters.search && ` search keywords = "${filters.search}"`}
              {filters.compliance_type && (filters.search ? " & " : "")}
              {filters.compliance_type && ` type = ${filters.compliance_type}`}
              {(filters.compliance_type || filters.search) &&
                filters.compliance_status &&
                " & "}
              {filters.compliance_status &&
                ` status = ${filters.compliance_status}`}
              {(filters.compliance_type ||
                filters.compliance_status ||
                filters.search) &&
                filters.compliance_jurisdiction &&
                " & "}
              {filters.compliance_jurisdiction &&
                ` jurisdiction = ${filters.compliance_jurisdiction}`}
              {filters.importance !== "all" &&
                " & showing key compliances only"}
            </>
          ) : (
            "Showing key compliances only"
          )}
        </Alert>
      ) : null}

      {CONFIG.RFW_HORIZDOT}

      {filteredCompliances.length > 0 && (
        <>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ marginTop: "10px", marginBottom: "5px" }}
          >
            Click on link to jump to compliance type:
          </Typography>
          <div style={{ display: "flex", flexWrap: "wrap", marginBottom: 40 }}>
            {Object.keys(groupedCompliances).map((slug, index) => (
              <Typography variant="body1" style={{ marginRight: 8 }} key={slug}>
                <a
                  href={`#${slug}`}
                  onClick={(e) => {
                    e.preventDefault();
                    const element = document.getElementById(slug);
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                  }}
                >
                  <FontAwesomeIcon
                    icon={complianceTypeIcons[slug]}
                    style={{ marginRight: "7px" }}
                  />
                  {groupedCompliances[slug].name}
                </a>
                {index < Object.keys(groupedCompliances).length - 1 && " | "}
              </Typography>
            ))}
          </div>
        </>
      )}
      {isLoading ? (
        <CircularProgress />
      ) : filteredCompliances.length === 0 ? (
        <Alert severity="warning" sx={{ mt: 4 }}>
          No results found for the selected filters.
        </Alert>
      ) : (
        Object.keys(groupedCompliances).map((slug) => {
          const complianceTypeItems = groupedCompliances[slug].items;
          const page = currentPage[slug] || 1;
          const paginatedItems = complianceTypeItems.slice(
            (page - 1) * ITEMS_PER_PAGE,
            page * ITEMS_PER_PAGE
          );

          return (
            <div key={slug}>
              <Typography variant="h5" id={slug} sx={{ mt: 6 }}>
                {groupedCompliances[slug].name} ({complianceTypeItems.length})
              </Typography>
              <hr />
              {paginatedItems.map((compliance) => (
                <div key={compliance.slug}>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Typography
                      component={RouterLink}
                      to={`${detailLinkBase}/${compliance.slug}`}
                      sx={{
                        fontSize: "1.1rem",
                        fontWeight: "bold",
                        color: "black",
                        textDecoration: "none",
                        "&:hover": { color: tertColor },
                        display: "inline-flex",
                      }}
                    >
                      {compliance.name}
                    </Typography>

                    {/* Compliance Status */}
                    {compliance.compliance_status && (
                      <Typography
                        sx={{
                          color: "white",
                          backgroundColor:
                            statusColors[compliance.compliance_status] ||
                            "#6c757d",
                          borderRadius: "4px",
                          p: 0.5,
                          ml: 2, // Space between name and status
                          display: "inline-flex",
                          fontSize: "0.8rem", // Smaller font size
                        }}
                      >
                        {compliance.compliance_status}
                      </Typography>
                    )}

                    {/* Jurisdiction with Icon */}
                    {compliance.compliance_jurisdiction && (
                      <Typography
                        sx={{
                          color: "white",
                          backgroundColor: "#343a40",
                          borderRadius: "4px",
                          p: 0.5,
                          ml: 2, // Space between name and status
                          display: "inline-flex",
                          fontSize: "0.8rem", // Smaller font size
                        }}
                      >
                        {compliance.compliance_jurisdiction.name}
                      </Typography>
                    )}
                  </Box>

                  {compliance.brief_legal_description && (
                    <Box color="textSecondary" sx={{ mt: 1, mb: 2 }}>
                      {parse(compliance.brief_legal_description)}
                    </Box>
                  )}
                </div>
              ))}

              {complianceTypeItems.length > ITEMS_PER_PAGE && (
                <Box sx={{ mt: 5 }}>
                  <Pagination
                    itemsCount={complianceTypeItems.length}
                    pageSize={ITEMS_PER_PAGE}
                    currentPage={page}
                    onPageChange={(page) => handlePageChange(slug, page)}
                  />
                </Box>
              )}
            </div>
          );
        })
      )}
    </Container>
  );
};

export default Compliance;
