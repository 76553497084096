import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReusableTitle from "../common/ReusableTitle";
import GavelIcon from "@mui/icons-material/Gavel";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { faFileContract } from "@fortawesome/pro-solid-svg-icons";
import { getImageUrl } from "../../utils/helpers";

const KthPatentLicensing = ({ baseConfig }) => {
  const {
    priColor,
    priHoverColor,
    tertColor,
    tertHoverColor,
    secColor,
    quadColor,
    quadHoverColor,
  } = baseConfig.siteBranding;

  return (
    <Container>
      <Box sx={{ mt: 2 }}>
        <ReusableTitle
          title="Patent Licensing & Enforcement"
          isMainHeading={true}
          siteBranding={baseConfig.siteBranding}
          icon={{ faIcon: faFileContract }}
        />

        {/* Amazon Patent Enforcement Section */}
        <Box sx={{ mt: 2, mb: 2 }}>
          <ReusableTitle
            title="Amazon Patent Enforcement (Assertion and Defense)"
            isMainHeading={false}
            siteBranding={baseConfig.siteBranding}
          />
          <Typography variant="body1" gutterBottom sx={{ mt: 2, ml: 3 }}>
            At Kama Thuo, PLLC, we provide expert legal guidance for patent
            owners seeking to protect their intellectual property rights against
            Amazon sellers, and Amazon sellers defending against infringement
            claims. Our goal is to deliver efficient resolutions, including
            reinstating suspended listings for resellers when their ASINs do not
            infringe the asserted patent’s claims.
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ mt: 2, ml: 3 }}>
            Amazon's APEX program has streamlined the patent enforcement process
            for disputes involving Amazon resellers. This program offers a
            faster and more cost-effective alternative to traditional
            litigation, but it comes with unique challenges. Learn more about
            the pros and cons of the APEX program in our{" "}
            <a
              href="https://news-insights.kthlaw.com/en-us/patents/patent-enforcement/amazon-apex"
              target="_blank"
              rel="noopener noreferrer"
              // style={{ textDecoration: "none" }} // Optional styling
            >
              News & Insights
            </a>{" "}
            site.
          </Typography>

          <Typography variant="body1" gutterBottom sx={{ mt: 2, ml: 3 }}>
            Explore how our <strong>Patent Enforcement Wizard</strong> can
            provide valuable insights. Whether you're an Amazon seller or a
            patent owner, the wizard simplifies the enforcement or defense flow
            and offers actionable guidance.
            <RouterLink to="/patent-enforcement">Try it now</RouterLink> and
            take the next step toward resolving your dispute effectively.
          </Typography>

          {/* Call-to-Actions */}
          {/* Amazon Patent Enforcement Container */}
          <Grid
            container
            spacing={2}
            alignItems="stretch"
            sx={{ mt: 2, ml: 2 }}
          >
            <Grid item xs={12} md={6}>
              <Card
                variant="outlined"
                sx={{
                  borderLeft: `6px solid ${secColor}`,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "100%",
                  p: 2, // Reduced padding for compact design
                }}
              >
                <CardContent sx={{ width: "100%", p: 0 }}>
                  {/* Amazon Logo + Headline (Same Row) */}
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ backgroundColor: "#fff", p: 1 }}
                  >
                    <img
                      src={getImageUrl("amz-pat-disputes.png")}
                      alt="Amazon Patent Disputes"
                      style={{
                        width: "180px",
                        height: "auto",
                        marginRight: 10,
                      }}
                    />
                    <Typography variant="h6" fontWeight="bold">
                      Patent Enforcement
                    </Typography>
                  </Box>

                  {/* Content Section with Background */}
                  <Box sx={{ backgroundColor: "#f8f9fa", p: 2 }}>
                    <Grid container spacing={2} justifyContent="center">
                      {/* Patent Owners Column */}
                      <Grid item xs={12} sm={6}>
                        <Box display="flex" alignItems="center" mb={1}>
                          <GavelIcon sx={{ color: "#007BFF", mr: 1 }} />
                          <Typography variant="subtitle1" fontWeight="bold">
                            For Patent Owners
                          </Typography>
                        </Box>
                        <Typography variant="body2" color="textSecondary">
                          Enforce your patents against unauthorized Amazon
                          sellers.
                        </Typography>
                      </Grid>

                      {/* Amazon Sellers Column */}
                      <Grid item xs={12} sm={6}>
                        <Box display="flex" alignItems="center" mb={1}>
                          <ShoppingCartIcon sx={{ color: "#28a745", mr: 1 }} />
                          <Typography variant="subtitle1" fontWeight="bold">
                            For Amazon Sellers
                          </Typography>
                        </Box>
                        <Typography variant="body2" color="textSecondary">
                          Defend your listings from unfair patent infringement
                          claims.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  {/* CTA Button (Reduced Margin for Compact Design) */}
                  <Box textAlign="left" sx={{ mt: 2 }}>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: tertColor,
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: tertHoverColor,
                          color: "#fff",
                        },
                      }}
                      component={RouterLink}
                      to="/amazon-patent-enforcement"
                      endIcon={<ArrowForwardIosIcon />}
                    >
                      Learn More
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>

        {/* General Patent Licensing Section */}
        <Box sx={{ mt: 4, mb: 2 }}>
          <ReusableTitle
            title="Licensing & Monetization Expertise"
            isMainHeading={false}
            siteBranding={baseConfig.siteBranding}
          />
          <Typography variant="body1" gutterBottom sx={{ mt: 2, ml: 3 }}>
            Beyond Amazon, we provide comprehensive patent licensing and
            monetization services to help you maximize your intellectual
            property investment. Whether you're looking to license your patents,
            negotiate royalties, or assess the value of your portfolio, our team
            is here to guide you every step of the way.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default KthPatentLicensing;
