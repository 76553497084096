import React, { useEffect, useState } from "react";
import {
  useParams,
  Link as RouterLink,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import {
  Container,
  Grid,
  Typography,
  Paper,
  Button,
  Box,
  CircularProgress,
  Alert,
  Chip,
  Divider,
} from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faFilePdf,
  faExternalLinkAlt,
  faLink,
  faGavel,
  faToggleOn,
  faBracketsCurly,
  faCompassDrafting,
  faArrowLeft,
} from "@fortawesome/pro-solid-svg-icons";
import * as CONFIG from "./utils/kthGlobalLabelsVars";
import parse from "html-react-parser";
import ZoomableContent from "../common/ZoomableContent";
import moment from "moment";
import SetDocumentMeta from "../common/SetDocumentMeta";
const apiUrlKth = `${window._env_.REACT_APP_PATANALAI_API_URL}`;

const statusColors = {
  Active: "#28a745", // Green
  "Partially Active": "#ffc107", // Yellow/Orange
  // Pending: "#17a2b8", // Blue
  Pending: "red",
  Withdrawn: "red",
  Unknown: "#6c757d", // Gray
};

const ComplianceDetail = ({ scope, baseConfig }) => {
  const { slug } = useParams();
  const [searchParams] = useSearchParams(); // Used to capture query parameters
  const [compliance, setCompliance] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getComplianceDetailConfig = (scope) => {
    switch (scope) {
      case "ai":
        return {
          returnUrl: "/legal-compliance-for-ai",
          showRfwLink: false,
          technicalDetailUrlBase: `${CONFIG.RFW_AI_BASE_URL}/ai-compliance/`,
          pageTitle: "AI Legal Compliance Standard or Regulation Detail",
          pageMetaDescription: "",
          pageMetaKeywords: "",
        };
      case "wireless":
        return {
          returnUrl: "/wireless-legal-compliance",
          showRfwLink: true,
          technicalDetailUrlBase: `${CONFIG.RFW_WDI_BASE_URL}/wireless-compliance/`,
          pageTitle:
            "Wireless Device, Network, and Site Legal Compliance Detail",
          pageMetaDescription: "",
          pageMetaKeywords: "",
        };
      default:
        return {
          returnUrl: "",
          showRfwLink: false,
          technicalDetailUrlBase: "",
          pageTitle: "Compliance Detail",
          pageMetaDescription: "",
          pageMetaKeywords: "",
        };
    }
  };

  const {
    returnUrl,
    technicalDetailUrlBase,
    showRfwLink,
    pageTitle,
    pageMetaDescription,
    pageMetaKeywords,
  } = getComplianceDetailConfig(scope);

  SetDocumentMeta({
    title: pageTitle,
    description: pageMetaDescription,
    keywords: pageMetaKeywords,
  });

  useEffect(() => {
    const fetchComplianceDetail = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${apiUrlKth}/kth-ai/compliances?scope=${scope}&compliance=${slug}`
        );
        setCompliance(response.data);
      } catch (err) {
        setError("Failed to fetch compliance detail.");
        console.error("Error fetching compliance detail:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchComplianceDetail();
  }, [slug, scope]);

  if (isLoading)
    return (
      <Container>
        <CircularProgress />{" "}
      </Container>
    );
  if (error)
    return (
      <Container>
        <Grid item xs={12}>
          <Alert severity="error" sx={{ mt: 4 }}>
            {error}
          </Alert>
          <Button
            startIcon={<FirstPageIcon />}
            color="primary"
            component={RouterLink}
            to={returnUrl}
            sx={{ mt: 2, mb: 2 }}
          >
            Return to {scope === "wireless" ? "Wireless" : "AI"} Compliance
            Search
          </Button>
        </Grid>
      </Container>
    );
  if (!compliance)
    return (
      <Container>
        <Grid item xs={12}>
          <Alert severity="warning" sx={{ mt: 4 }}>
            No compliance data found.
          </Alert>
          <Button
            startIcon={<FirstPageIcon />}
            color="primary"
            component={RouterLink}
            to={returnUrl}
            sx={{ mt: 2, mb: 2 }}
          >
            Return to {scope === "wireless" ? "Wireless" : "AI"} Compliance
            Search
          </Button>
        </Grid>
      </Container>
    );

  const formatDate = (date) => moment(date).format("MMM D, YYYY");

  return (
    <Container className="link-no-decoration">
      {/* Compliance Details */}
      <Grid container spacing={3} padding={3}>
        <Grid item xs={12}>
          <Button
            startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            color="secondary"
            variant="outlined"
            component={RouterLink}
            to={returnUrl}
            sx={{ mb: 2, textTransform: "none" }}
          >
            Back to Compliance Search
          </Button>

          <Box mb={1}>
            <Typography variant="h4" gutterBottom>
              {compliance.name}
            </Typography>
            {compliance.compliance_status && (
              <Chip
                label={compliance.compliance_status}
                sx={{
                  backgroundColor:
                    statusColors[compliance.compliance_status] || "#6c757d",
                  color: "white",
                  marginRight: 1,
                }}
              />
            )}

            {compliance.compliance_jurisdiction && (
              <Chip
                label={compliance.compliance_jurisdiction.name}
                sx={{
                  backgroundColor: "#343a40",
                  color: "white",
                  marginRight: 1,
                }}
              />
            )}
            {compliance.compliance_type && (
              <Chip
                label={compliance.compliance_type.name}
                sx={{
                  backgroundColor: "#007bff",
                  color: "white",
                  marginRight: 1,
                }}
              />
            )}
          </Box>
        </Grid>

        {compliance.earliest_date && (
          <Grid item xs={12}>
            <Typography variant="body1">
              <FontAwesomeIcon
                icon={faCalendarAlt}
                style={{ marginRight: 8 }}
              />
              <strong>Earliest Date:</strong>{" "}
              {formatDate(compliance.earliest_date)}
            </Typography>
          </Grid>
        )}

        {compliance.full_force_date && (
          <Grid item xs={12}>
            <Typography variant="body1">
              <FontAwesomeIcon
                icon={faCalendarAlt}
                style={{ marginRight: 8 }}
              />
              <strong>Full Force Date:</strong>{" "}
              {formatDate(compliance.full_force_date)}
            </Typography>
          </Grid>
        )}

        {compliance.compliance_link && (
          <Grid item xs={12}>
            <Typography variant="body1">
              <FontAwesomeIcon
                icon={faExternalLinkAlt}
                style={{ marginRight: 8 }}
              />
              <a
                href={compliance.compliance_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {compliance.compliance_link_label || "Compliance Link"}
              </a>
            </Typography>
          </Grid>
        )}

        {compliance.compliance_doc && (
          <Grid item xs={12}>
            <Typography variant="body1">
              <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: 8 }} />
              <a
                href={compliance.compliance_doc}
                target="_blank"
                rel="noopener noreferrer"
              >
                {compliance.compliance_doc_label || "Compliance Document"}
              </a>
            </Typography>
          </Grid>
        )}

        <Grid item xs={12}>
          {compliance.brief_legal_description && (
            <>
              <Divider sx={{ my: 3 }} />
              <Typography variant="h5">Legal Overview</Typography>
              {parse(compliance.brief_legal_description)}
            </>
          )}

          {/* Always show link to tech details even when no legal details */}
          {showRfwLink && (
            <Typography variant="body1" sx={{ mt: 4, mb: 2 }}>
              <FontAwesomeIcon
                icon={faCompassDrafting}
                style={{ marginRight: 8 }}
              />
              <a
                href={`${technicalDetailUrlBase}${compliance.slug}`}
                target="_blank"
              >
                Explore Technical Details
              </a>{" "}
              (external link)
            </Typography>
          )}

          {/* Legal Summary Section */}
          {compliance.refs_for_compliance?.filter(
            (ref) => ref.ref_type === "Legal-Only" && ref.is_summary_table
          ).length > 0 && (
            <Grid item xs={12}>
              <Divider sx={{ my: 3 }} />
              <Typography variant="h5" sx={{ mt: 3, mb: 1 }}>
                Legal Summary
              </Typography>
              {compliance.refs_for_compliance
                .filter(
                  (ref) => ref.ref_type === "Legal-Only" && ref.is_summary_table
                )
                .map((ref, index) => (
                  <React.Fragment key={index}>
                    {ref.ref_discussion_title && (
                      <Typography
                        key={`${index}-title`}
                        variant="body1"
                        sx={{ mt: 1 }}
                      >
                        <strong>{ref.ref_discussion_title}</strong>{" "}
                      </Typography>
                    )}
                    {ref.ref_discussion && (
                      <Box
                        key={`${index}-discussion`}
                        color="textSecondary"
                        // sx={{ ml: 4 }}
                      >
                        {parse(ref.ref_discussion)}
                      </Box>
                    )}

                    {ref.ref_link_label && ref.ref_link && (
                      <Typography
                        key={`${index}-link`}
                        variant="body1"
                        sx={{ ml: 4, mb: 2 }}
                      >
                        <FontAwesomeIcon
                          key={`${index}-icon`}
                          icon={faLink}
                          style={{ marginRight: 8 }}
                        />
                        <a
                          href={ref.ref_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {ref.ref_link_label}
                        </a>
                      </Typography>
                    )}
                  </React.Fragment>
                ))}
            </Grid>
          )}

          {compliance.detailed_legal_description && (
            <>
              <Divider sx={{ my: 3 }} />
              <Typography variant="h5" sx={{ mt: 3 }}>
                Discussion
              </Typography>
              {parse(compliance.detailed_legal_description)}
            </>
          )}
        </Grid>

        {/* ComplianceRefs Section */}
        {compliance.refs_for_compliance?.filter(
          (ref) =>
            !ref.is_summary_table &&
            (ref.ref_type === "Legal-Only" || ref.ref_type === "General")
        ).length > 0 && (
          <Grid item xs={12}>
            <Divider sx={{ my: 3 }} />
            <Typography variant="h5" sx={{ mt: 3, mb: 1 }}>
              References
            </Typography>
            {compliance.refs_for_compliance
              .filter(
                (ref) =>
                  !ref.is_summary_table &&
                  (ref.ref_type === "Legal-Only" || ref.ref_type === "General")
              )
              .map((ref, index) => (
                <Box
                  key={index}
                  sx={{
                    backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#e8e8e8",
                    p: 2,
                    borderRadius: 1,
                    mb: 2,
                    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
                  }}
                >
                  {ref.ref_discussion_title && (
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "bold", color: "#333", mb: 1 }}
                    >
                      {ref.ref_discussion_title}
                    </Typography>
                  )}
                  {ref.ref_discussion && (
                    <Box color="textSecondary" sx={{ ml: 2, mb: 1 }}>
                      {/* {parse(ref.ref_discussion)} */}
                      <ZoomableContent content={ref.ref_discussion} />
                    </Box>
                  )}
                  {ref.ref_link_label && ref.ref_link && (
                    <Typography variant="body1" sx={{ ml: 2 }}>
                      <FontAwesomeIcon
                        icon={faLink}
                        style={{ marginRight: 8, color: "#007bff" }}
                      />
                      <a
                        href={ref.ref_link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "none",
                          color: "#007bff",

                          "&:hover": { color: "#0056b3" },
                        }}
                      >
                        {ref.ref_link_label}
                      </a>
                    </Typography>
                  )}
                </Box>
              ))}
          </Grid>
        )}

        {/* Related Compliances */}
        {compliance.related_compliances?.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ mt: 4 }}>
              Related Compliance
            </Typography>
            <Box>
              {compliance.related_compliances.map((related, index) => (
                <Button
                  key={index}
                  component={RouterLink}
                  to={`${returnUrl}/${related.related_compliance_slug}`}
                  sx={{ mr: 2, mt: 1 }}
                >
                  {related.related_compliance_name}
                </Button>
              ))}
            </Box>
          </Grid>
        )}

        <Grid item xs={12}>
          <Button
            startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            color="secondary"
            variant="outlined"
            component={RouterLink}
            to={returnUrl}
            sx={{ mb: 2, textTransform: "none" }}
          >
            Back to Compliance Search
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ComplianceDetail;
