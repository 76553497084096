/**
 * Custom Accordion with Chevron Up/Down as open and close icon.
 */
import React, { useState } from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const CustomAccordion = ({ title, children, defaultExpanded = true }) => {
  const [expanded, setExpanded] = useState(defaultExpanded);

  return (
    <Accordion
      expanded={expanded}
      onChange={(_, isExpanded) => setExpanded(isExpanded)} // Use the onChange event
      sx={{ mb: 2 }}
    >
      <AccordionSummary
        expandIcon={<KeyboardArrowDownIcon />} // Just use the down icon
        sx={{ backgroundColor: "#f7f7f7" }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: "#fff" }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;
