import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { exportFieldsMapping } from "./worksheetFieldsForExport";

//---------------------------------------------------------------------------------------
// Legal Disclaimer KTH
//---------------------------------------------------------------------------------------
const legalDisclaimerKth = (doc) => {
  doc.addPage(); // Ensure Legal Disclaimer is on a new page

  const startX = 14; // Margin from the left
  const pageWidth = doc.internal.pageSize.width;
  const contentWidth = pageWidth - 2 * startX; // Width for text after margins
  const lineHeight = 10; // Line height for text

  // Header
  let currentY = 30;
  doc.setFont("helvetica", "bold");
  doc.setFontSize(18);
  doc.setTextColor("#2D93AD"); // Blue-Green
  doc.text("Legal Disclaimer", startX, currentY);

  currentY += 10;

  // Red Disclaimer Text
  doc.setFont("helvetica", "bold");
  doc.setFontSize(14);
  doc.setTextColor(255, 0, 0); // Red
  const disclaimerText = "This report DOES NOT REPRESENT A LEGAL OPINION.";
  const disclaimerLines = doc.splitTextToSize(disclaimerText, contentWidth);
  doc.text(disclaimerLines, startX, currentY);

  currentY += disclaimerLines.length * lineHeight + 5;

  // Regular Text
  doc.setFont("helvetica", "normal");
  doc.setFontSize(12);
  doc.setTextColor(0, 0, 0); // Black

  const paragraphs = [
    "For legal analysis, please contact us to evaluate if we can assist you in this matter. No attorney-client relationship is established until an engagement letter agreeing to the representation is executed.",
    "Please review our Terms of Service for more details, and take particular note of the DISCLAIMER OF WARRANTIES section.",
  ];

  paragraphs.forEach((paragraph) => {
    const lines = doc.splitTextToSize(paragraph, contentWidth);
    doc.text(lines, startX, currentY);
    // currentY += lines.length * lineHeight + 5;
    currentY += lines.length * lineHeight - lineHeight;
  });

  // Divider
  doc.setDrawColor("#77C2CF"); // Light Blue
  doc.line(startX, currentY, pageWidth - startX, currentY); // Horizontal line

  // Add Links with Blue Text
  const contactLink = "https://www.kthlaw.com/contact";
  const termsLink = "https://www.kthlaw.com/terms-of-service";

  doc.setTextColor(0, 0, 255); // Blue
  doc.textWithLink("Contact Us", startX, currentY - lineHeight + 20, {
    url: contactLink,
  });
  doc.textWithLink("Terms of Service", startX, currentY - lineHeight + 30, {
    url: termsLink,
  });
};

//---------------------------------------------------------------------------------------
// Legal Disclaimer PATANAL
//---------------------------------------------------------------------------------------
const legalDisclaimerPatanal = (doc) => {
  doc.addPage(); // Ensure Legal Disclaimer is on a new page

  const startX = 14;
  const pageWidth = doc.internal.pageSize.width;
  const contentWidth = pageWidth - 2 * startX;
  const lineHeight = 10;

  // Header
  let currentY = 30;
  doc.setFont("helvetica", "bold");
  doc.setFontSize(18);
  doc.setTextColor("#2D93AD"); // Blue-Green
  doc.text("Legal Disclaimer", startX, currentY);

  currentY += 10;

  // Red Disclaimer Text
  doc.setFont("helvetica", "bold");
  doc.setFontSize(14);
  doc.setTextColor(255, 0, 0); // Red
  const disclaimerText = "This report DOES NOT REPRESENT A LEGAL OPINION.";
  const disclaimerLines = doc.splitTextToSize(disclaimerText, contentWidth);
  doc.text(disclaimerLines, startX, currentY);

  currentY += disclaimerLines.length * lineHeight + 5;

  // Regular Text
  doc.setFont("helvetica", "normal");
  doc.setFontSize(12);
  doc.setTextColor(0, 0, 0); // Black

  const paragraphs = [
    "For legal analysis talk to a patent attorney. Firms experienced in strategic analysis of wireless patents include, for example, Kama Thuo, PLLC and Perkins Coie, LLP, among others. See links below, or search your local lawyer referral service, or contact us for more information.",
    "Please review our Terms of Service for more details, and take particular note of the DISCLAIMER OF WARRANTIES section.",
  ];

  paragraphs.forEach((paragraph) => {
    const lines = doc.splitTextToSize(paragraph, contentWidth);
    doc.text(lines, startX, currentY);
    // currentY += lines.length * lineHeight + 5;
    currentY += lines.length * lineHeight;
  });

  // Divider
  doc.setDrawColor("#77C2CF"); // Light Blue
  doc.line(startX, currentY, pageWidth - startX, currentY); // Horizontal line

  // Add Links with Blue Text
  const contactLink = "https://www.patanal.ai/contact";
  const termsLink = "https://www.patentanalytics.com/terms-of-service";
  const kthLink = "https://www.kthlaw.com/patents";
  const perkinsLink =
    "https://www.perkinscoie.com/en/practices/intellectual-property-law/index.html";

  doc.setTextColor(0, 0, 255); // Blue
  doc.textWithLink("Contact Us", startX, currentY - lineHeight + 20, {
    url: contactLink,
  });
  doc.textWithLink("Kama Thuo, PLLC", startX, currentY - lineHeight + 30, {
    url: kthLink,
  });
  doc.textWithLink("Perkins Coie, LLP", startX, currentY - lineHeight + 40, {
    url: perkinsLink,
  });
  doc.textWithLink("Terms of Service", startX, currentY - lineHeight + 50, {
    url: termsLink,
  });
};

//---------------------------------------------------------------------------------------
// Footer Text
//---------------------------------------------------------------------------------------
const footerText = (doc, type = "") => {
  let link = "www.patanal.ai";
  if (
    type === "patent-enforcement" ||
    type === "ai-compliance" ||
    type === "ai-legal-terms"
  )
    link = "www.kthlaw.com";
  const pageCount = doc.internal.getNumberOfPages();
  doc.setFontSize(11);
  doc.setTextColor(150); // Muted grey color
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.textWithLink(
      `This report was automatically generated at ${link}.`,
      14,
      doc.internal.pageSize.height - 10,
      { url: `https://${link}`, color: "blue", textDecoration: "none" }
    );
  }
};

//---------------------------------------------------------------------------------------
// Export One Worksheet to PDF
//---------------------------------------------------------------------------------------
export const exportToPDF = (data, type) => {
  const getPdfConfig = (type) => {
    switch (type) {
      case "patent-enforcement":
        return {
          tit: "Patent Assertion/Defense Worksheet",
          reportFormat: "text",
          reportOrientation: "portrait",
          legalDisclaimer: "kth",
          weblinkUrl: "https://www.kthlaw.com/patents",
          weblinkLabel:
            "Kama Thuo, PLLC (Patent, AI, Wireless Telecom Law Firm)",
        };
      case "patent-application":
        return {
          tit: "Patent Application Worksheet",
          reportFormat: "text",
          reportOrientation: "portrait",
          legalDisclaimer: "kth",
          weblinkUrl: "https://www.kthlaw.com/patents",
          weblinkLabel:
            "Kama Thuo, PLLC (Patent, AI, Wireless Telecom Law Firm)",
        };
      case "ai-compliance":
        return {
          tit: "AI Compliance Worksheet",
          reportFormat: "text",
          reportOrientation: "portrait",
          legalDisclaimer: "kth",
          weblinkUrl: "https://www.kthlaw.com/ai",
          weblinkLabel:
            "Kama Thuo, PLLC (Patent, AI, Wireless Telecom Law Firm)",
        };
      case "ai-legal-terms":
        return {
          tit: "AI Legal Terms Worksheet",
          reportFormat: "text",
          reportOrientation: "portrait",
          legalDisclaimer: "kth",
          weblinkUrl: "https://www.kthlaw.com/ai",
          weblinkLabel:
            "Kama Thuo, PLLC (Patent, AI, Wireless Telecom Law Firm)",
        };
      default:
        return {
          tit: "Patent Analysis Worksheet",
          reportFormat: "tabular",
          reportOrientation: "landscape",
          legalDisclaimer: "patanal",
          weblinkUrl: "https://www.patanal.ai",
          weblinkLabel:
            "Kama Thuo, PLLC (Patent, AI, Wireless Telecom Law Firm)",
        };
    }
  };

  const {
    tit,
    reportFormat,
    reportOrientation,
    legalDisclaimer,
    weblinkUrl,
    weblinkLabel,
  } = getPdfConfig(type);

  const startX = 14;
  const pageWidth = reportOrientation === "portrait" ? 210 : 297; // A4 Portrait or Landscape Width
  const maxContentWidth = pageWidth - 2 * startX; // Deduct margins

  const doc = new jsPDF({
    orientation: reportOrientation,
  });

  // Cover Page
  doc.setFont("helvetica", "bold");
  doc.setFontSize(22);
  doc.setTextColor("#2D93AD");
  doc.text(tit, startX, 30);

  doc.setFont("helvetica", "normal");
  doc.setFontSize(14);
  doc.setTextColor("#7A828B");
  doc.text(`${type} report`, startX, 50);
  doc.text(`Date: ${new Date().toLocaleDateString()}`, startX, 60);
  doc.setTextColor(0, 0, 255); // Blue for link
  doc.textWithLink(weblinkLabel, startX, 70, {
    url: weblinkUrl,
  });

  //------------------- TABULAR WORKSHEET -------------------------
  if (reportFormat === "tabular") {
    const fields = exportFieldsMapping[type] || Object.keys(data[0] || {});
    const tableData = data.map((item) =>
      fields.map((field) => item[field] || "")
    );
    doc.autoTable({
      head: [fields],
      body: tableData,
      startY: 80,
    });
  }

  //--------------------- TEXT WORKSHEET ---------------------------
  if (reportFormat === "text") {
    data.forEach((item) => {
      item.wizall.forEach((section, sectionIdx) => {
        if (sectionIdx > 0) doc.addPage();

        let yOffset = 90; // Start below the report title and date

        // Section Header
        doc.setFont("helvetica", "bold");
        doc.setFontSize(18);
        doc.setTextColor("#7A4E78");
        doc.text(section.header, startX, yOffset);

        // Add a divider below the section header
        doc.setDrawColor("#7A828B"); // Grey
        doc.line(startX, yOffset + 5, pageWidth - startX, yOffset + 5);

        yOffset += 15;

        section.questions.forEach((q, qIdx) => {
          if (yOffset + 50 > doc.internal.pageSize.height - 20) {
            doc.addPage();
            yOffset = 20;
          }

          // Render question
          const questionText = `Q${qIdx + 1}: ${q.question}`;
          const questionLines = doc.splitTextToSize(
            questionText,
            maxContentWidth - 5 //-5 is kludge to make it fit. Answer fits ok
          );
          doc.setFont("helvetica", "normal");
          doc.setFontSize(13);
          doc.setTextColor(0, 0, 0);
          doc.text(questionLines, startX, yOffset);
          yOffset += questionLines.length * 7;

          // Render answer
          const answerText = `Answer: ${q.answer}`;
          const answerLines = doc.splitTextToSize(answerText, maxContentWidth);
          doc.setFontSize(12);
          doc.setTextColor("#4ABDAC");
          doc.text(answerLines, startX + 5, yOffset);
          yOffset += answerLines.length * 7;

          // Render notes if available
          if (q.notes) {
            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(q.notes, "text/html");
            const paragraphs = Array.from(htmlDoc.body.children).map(
              (child) => child.textContent
            );

            const noteWidth = maxContentWidth - 10; // Subtract padding for the box
            let noteHeight = 5;

            const formattedParagraphs = paragraphs.map((paragraph) =>
              doc.splitTextToSize(paragraph, noteWidth)
            );

            formattedParagraphs.forEach((lines) => {
              noteHeight += lines.length * 7; // Calculate height
            });

            if (yOffset + noteHeight > doc.internal.pageSize.height - 20) {
              doc.addPage();
              yOffset = 20;
            }

            // Draw note box
            doc.setFillColor(240, 240, 240); // Light grey background
            doc.setTextColor("#96858F"); // black font for notes
            doc.rect(startX, yOffset, maxContentWidth, noteHeight, "F");

            doc.setFont("helvetica", "italic");
            let paragraphYOffset = yOffset + 7;
            formattedParagraphs.forEach((lines) => {
              doc.text(lines, startX + 5, paragraphYOffset);
              paragraphYOffset += lines.length * 7;
            });

            yOffset += noteHeight + 5;
          }

          yOffset += 10; // Add space for the next question
        });
      });
    });
  }

  //--------------------- Disclaimer & Footer --------------------
  if (legalDisclaimer === "kth") legalDisclaimerKth(doc);
  if (legalDisclaimer === "patanal") legalDisclaimerPatanal(doc);
  footerText(doc, type);
  doc.save(`${type}-worksheet.pdf`);
};

//---------------------------------------------------------------------------------------
// Export All WorkSheets to PDF
//---------------------------------------------------------------------------------------
//@TODO - update exportAllToPDF for non-tablular data
export const exportAllToPDF = (worksheet) => {
  const startX = 14;

  const doc = new jsPDF({
    orientation: "landscape",
  });

  Object.entries(worksheet).forEach(([type, data], index) => {
    if (index !== 0) {
      doc.addPage();
    }

    // Cover Page for each type
    doc.setFontSize(22);
    doc.text("Patanal Analysis Worksheet", startX, 30);
    doc.setFontSize(16);
    doc.text(`${type} report`, startX, 50);
    doc.text(`Date: ${new Date().toLocaleDateString()}`, startX, 60);

    // Check if there is data to display
    if (data.length > 0) {
      // There is data, render the table
      const fields = exportFieldsMapping[type] || Object.keys(data[0] || {});
      const tableData = data.map((item) =>
        fields.map((field) => item[field] || "")
      );
      doc.autoTable({
        head: [fields],
        body: tableData,
        startY: 70,
      });
    } else {
      // No data, display a message instead of the table
      doc.setFontSize(12);
      doc.text("No saved worksheet items.", startX, 80);
    }
  });

  legalDisclaimerPatanal(doc); // Add Legal Disclaimer as last page
  footerText(doc); // Add footer to all pages
  doc.save("All-worksheets.pdf");
};
