export const kthNavbarConfig = [
  {
    title: "Services",
    authRequired: false,
    children: [
      {
        title: "Patents",
        path: "/patents",
      },
      {
        title: "Artificial Intelligence",
        path: "/ai",
      },
      {
        title: "Wireless Telecom",
        path: "/wireless",
      },
    ],
  },

  {
    title: "News & Insights",
    path: "/news-insights",
    authRequired: false,
    children: [],
  },
  // {
  //   title: "News & Insights",
  //   path: "external",
  //   externalUrl: "https://news-insights.kthlaw.com/en-us/",
  //   authRequired: false,
  //   children: [],
  // },
  {
    title: "Patent Resources",
    authRequired: false,
    children: [
      {
        title: "Amazon Patent Enforcement",
        path: "/amazon-patent-enforcement",
      },
      {
        title: "Patent Enforcement Checklist",
        path: "/patent-enforcement",
      },
      {
        title: "Patent Law AI Resources",
        path: "/patent-ai-resources",
      },
    ],
  },
  {
    title: "AI Resources",
    authRequired: false,
    children: [
      {
        title: "Legal Agreements for AI",
        path: "/legal-agreements-for-ai",
      },
      {
        title: "Legal Terms for AI",
        path: "/legal-terms-for-ai",
      },
      {
        title: "Legal Terms Checker for AI",
        path: "/legal-terms-checker-for-ai",
      },
      {
        title: "Legal Compliance for AI",
        path: "/legal-compliance-for-ai",
      },
      {
        title: "Compliance Checker for AI",
        path: "/compliance-checker-for-ai",
      },
      // {
      //   title: "EU AI Act Checker",
      //   path: "/eu-ai-act-checker",
      // },
      {
        title: "Legal Definitions for AI",
        path: "/legal-definitions-for-ai",
      },
    ],
  },
  {
    title: "Wireless Resources",
    authRequired: false,
    children: [
      {
        title: "Spectrum Licensing",
        path: "/spectrum-licensing",
      },
      {
        title: "Wireless Compliance",
        path: "/wireless-legal-compliance",
      },
      {
        title: "Wireless Standards",
        path: "/wireless-standards",
      },
    ],
  },
  {
    title: "Professionals",
    path: "/professionals",
    authRequired: false,
    children: [],
  },
  {
    title: "Disclaimer",
    path: "/disclaimer",
    authRequired: false,
    children: [],
  },

  // ... more menu items
];
