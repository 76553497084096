import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Container,
  List,
  ListItem,
  Typography,
  CircularProgress,
  Alert,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Slider,
  Button,
  useMediaQuery,
  useTheme,
  ListItemButton,
  Checkbox,
  FormControlLabel,
  Paper,
} from "@mui/material";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import SetDocumentMeta from "../common/SetDocumentMeta";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons";

const apiUrlKth = `${window._env_.REACT_APP_PATANALAI_API_URL}`;

// Default frequency parameters
const DEFAULT_MIN_FREQUENCY = 10; // MHz
const DEFAULT_MAX_FREQUENCY = 30000; // MHz
const DEFAULT_LICENSE_TYPES_ARR = ["Direct Licensed", "Light Licensed"];

// Constants for frequency ranges
const FREQUENCY_RANGES = [
  { label: "All", min: null, max: null },
  { label: "Sub-7GHz", min: 10, max: 7000 },
  { label: "VHF (30-300 MHz)", min: 30, max: 300 },
  { label: "UHF (300 MHz - 3 GHz)", min: 300, max: 3000 },
  { label: "L Band (1-2 GHz)", min: 1000, max: 2000 },
  { label: "S Band (2-4 GHz)", min: 2000, max: 4000 },
  { label: "C Band (4-8 GHz)", min: 4000, max: 8000 },
  { label: "X Band (8-12 GHz)", min: 8000, max: 12000 },
  { label: "Ku Band (12-18 GHz)", min: 12000, max: 18000 },
  { label: "K Band (18-27 GHz)", min: 18000, max: 27000 },
  { label: "Ka Band (27-40 GHz)", min: 27000, max: 40000 },
  { label: "mm Wave (30-300 GHz)", min: 30000, max: 300000 },
];

const Spectrum = ({ baseConfig }) => {
  const {
    priColor,
    priHoverColor,
    tertColor,
    tertHoverColor,
    quadColor,
    quadHoverColor,
  } = baseConfig.siteBranding;

  const [bands, setBands] = useState([]);
  const [filteredBands, setFilteredBands] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [allocatedUse, setAllocatedUse] = useState("");

  const [licenseType, setLicenseType] = useState([
    ...DEFAULT_LICENSE_TYPES_ARR,
  ]); // Ensure it's an array

  const [frequencyRange, setFrequencyRange] = useState([
    DEFAULT_MIN_FREQUENCY,
    DEFAULT_MAX_FREQUENCY,
  ]);
  const [minFrequency, setMinFrequency] = useState(DEFAULT_MIN_FREQUENCY);
  const [maxFrequency, setMaxFrequency] = useState(DEFAULT_MAX_FREQUENCY);

  const [selectedFreqRange, setSelectedFreqRange] = useState(
    FREQUENCY_RANGES[0].label
  );

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const pageTitle = "Spectrum Licensing | Telecom Law Help";
  const pageMetaDescription =
    "Explore a comprehensive list of wireless spectrum licensing details curated by expert wireless telecommunications attorneys. \
    Learn more about licensing types, coordination methods, permitted uses, and geographic applicability for different frequency bands.";
  const pageMetaKeywords =
    "wireless telecom attorneys, spectrum allocation, spectrum licensing, licensed spectrum, unlicensed spectrum, frequency coordination, \
    legal advice for U.S. wireless spectrum";

  SetDocumentMeta({
    title: pageTitle,
    description: pageMetaDescription,
    keywords: pageMetaKeywords,
  });

  useEffect(() => {
    if (bands.length > 0) {
      handleFilter();
    }
  }, [searchQuery, allocatedUse, licenseType, minFrequency, maxFrequency]);

  useEffect(() => {
    const fetchBands = async () => {
      try {
        const response = await axios.get(`${apiUrlKth}/kth-telecom/spectrum`);
        const fetchedBands = response.data;

        // Sort bands by ordering field
        fetchedBands.sort((a, b) => a.ordering - b.ordering);

        setBands(fetchedBands);

        // Apply default filter on initial load
        const defaultFilteredBands = fetchedBands.filter((band) =>
          DEFAULT_LICENSE_TYPES_ARR.some((type) =>
            band.license_types.includes(type)
          )
        );
        setFilteredBands(defaultFilteredBands);
      } catch (err) {
        setError("Failed to fetch spectrum list.");
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBands();
  }, []);

  const handleFilter = () => {
    let filtered = [...bands];

    // Filter by search query
    if (searchQuery) {
      filtered = filtered.filter(
        (band) =>
          band.band.toLowerCase().includes(searchQuery.toLowerCase()) ||
          (band.band_name &&
            band.band_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (band.frequency &&
            band.frequency.toLowerCase().includes(searchQuery.toLowerCase()))
      );
    }

    // Filter by allocated use
    if (allocatedUse) {
      filtered = filtered.filter((band) =>
        band.allocated_use.includes(allocatedUse)
      );
    }

    // Filter by license type
    if (licenseType.length > 0) {
      filtered = filtered.filter((band) =>
        licenseType.some((type) => band.license_types.includes(type))
      );
    }

    // Filter by frequency range (from minFrequency and maxFrequency)
    // Skip frequency filtering if "All" is selected
    if (selectedFreqRange !== "All") {
      filtered = filtered.filter(
        (band) =>
          band.minimum_frequency !== null &&
          band.maximum_frequency !== null &&
          band.minimum_frequency >= minFrequency &&
          band.maximum_frequency <= maxFrequency
      );
    }

    setFilteredBands(filtered);
  };

  const handleClearFilters = () => {
    setSearchQuery("");
    setAllocatedUse("");
    setLicenseType([...DEFAULT_LICENSE_TYPES_ARR]);
    setMinFrequency(DEFAULT_MIN_FREQUENCY);
    setMaxFrequency(DEFAULT_MAX_FREQUENCY);
    setFrequencyRange([DEFAULT_MIN_FREQUENCY, DEFAULT_MAX_FREQUENCY]);
    setSelectedFreqRange(FREQUENCY_RANGES[0].label);

    setFilteredBands(bands);
  };

  useEffect(() => {
    handleFilter();
  }, [searchQuery, allocatedUse, licenseType, frequencyRange]);

  const handleFreqRangeChange = (rangeLabel) => {
    setSelectedFreqRange(rangeLabel);

    // Handle "All" selection
    if (rangeLabel === "All") {
      setFrequencyRange([DEFAULT_MIN_FREQUENCY, DEFAULT_MAX_FREQUENCY]);
      setMinFrequency(DEFAULT_MIN_FREQUENCY);
      setMaxFrequency(DEFAULT_MAX_FREQUENCY);
      return; // Skip additional updates
    }

    // Find the selected range object
    const selectedRange = FREQUENCY_RANGES.find(
      (range) => range.label === rangeLabel
    );

    if (selectedRange) {
      // Update frequency inputs and slider range to the new bounds
      setMinFrequency(selectedRange.min);
      setMaxFrequency(selectedRange.max);
      setFrequencyRange([selectedRange.min, selectedRange.max]);
    }
  };

  const handleFrequencyInputChange = (type, value) => {
    // Allow any input temporarily, sanitize on blur
    if (type === "min") {
      setMinFrequency(value);
    } else if (type === "max") {
      setMaxFrequency(value);
    }
  };

  const handleFrequencyBlur = () => {
    // Ensure values stay within bounds
    const sanitizedMin = Math.max(minFrequency, DEFAULT_MIN_FREQUENCY);
    const sanitizedMax = Math.min(maxFrequency, DEFAULT_MAX_FREQUENCY);

    if (sanitizedMin > sanitizedMax) {
      // Reset if min > max
      setMinFrequency(DEFAULT_MIN_FREQUENCY);
      setMaxFrequency(DEFAULT_MAX_FREQUENCY);
      setFrequencyRange([DEFAULT_MIN_FREQUENCY, DEFAULT_MAX_FREQUENCY]);
    } else {
      setMinFrequency(sanitizedMin);
      setMaxFrequency(sanitizedMax);
      setFrequencyRange([sanitizedMin, sanitizedMax]);
    }

    handleFilter();
  };

  const handleSliderChange = (e, newValue) => {
    setFrequencyRange(newValue);
    setMinFrequency(newValue[0]);
    setMaxFrequency(newValue[1]);
  };

  const _renderFreqRangeSlider = () => {
    if (selectedFreqRange === "All") {
      return (
        <>
          <Typography
            gutterBottom
            align="center"
            sx={{ fontWeight: "bold", color: tertColor }}
          >
            Frequency Range: All
          </Typography>
          <Typography
            gutterBottom
            align="center"
            sx={{
              fontSize: "0.9rem",
              color: "#6c757d", // Muted text color (e.g., Bootstrap muted color)
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "0.2rem",
            }}
          >
            (select a narrower frequency range to filter)
          </Typography>
          <Slider
            value={[DEFAULT_MIN_FREQUENCY, DEFAULT_MAX_FREQUENCY]}
            valueLabelDisplay="auto"
            min={DEFAULT_MIN_FREQUENCY}
            max={DEFAULT_MAX_FREQUENCY}
            disabled
          />
        </>
      );
    }

    return (
      <>
        <Typography
          gutterBottom
          align="center"
          sx={{ fontWeight: "bold", color: tertColor }}
        >
          Frequency Band ({frequencyRange[0]} MHz - {frequencyRange[1]} MHz)
        </Typography>
        <Typography
          gutterBottom
          align="center"
          sx={{
            fontSize: "0.9rem",
            color: "#6c757d", // Muted text color (e.g., Bootstrap muted color)
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "0.5rem",
          }}
        >
          (select a different frequency range to change slider bounds)
        </Typography>
        <Slider
          value={frequencyRange}
          onChange={handleSliderChange}
          valueLabelDisplay="auto"
          min={
            FREQUENCY_RANGES.find((range) => range.label === selectedFreqRange)
              ?.min || DEFAULT_MIN_FREQUENCY
          }
          max={
            FREQUENCY_RANGES.find((range) => range.label === selectedFreqRange)
              ?.max || DEFAULT_MAX_FREQUENCY
          }
        />
      </>
    );
  };

  const _renderMinFreqTextBox = () => {
    return (
      <TextField
        label="Min Frequency (MHz)"
        type="number"
        value={minFrequency}
        onChange={(e) =>
          handleFrequencyInputChange("min", parseInt(e.target.value, 10))
        }
        onBlur={handleFrequencyBlur}
        disabled={selectedFreqRange === "All"}
        fullWidth
      />
    );
  };

  const _renderMaxFreqTextBox = () => {
    return (
      <TextField
        label="Max Frequency (MHz)"
        type="number"
        value={maxFrequency}
        onChange={(e) =>
          handleFrequencyInputChange("max", parseInt(e.target.value, 10))
        }
        onBlur={handleFrequencyBlur}
        disabled={selectedFreqRange === "All"}
        fullWidth
      />
    );
  };

  if (isLoading)
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  if (error)
    return (
      <Container>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Alert severity="error">{error}</Alert>
        </Grid>
      </Container>
    );

  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom sx={{ mt: 2 }}>
        Spectrum Licensing (United States)
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ mb: 3 }}>
        Search U.S. spectrum by license type, allocated use, frequency range, of
        text string to learn more about spectrum allocation and licensing.
      </Typography>

      <Paper elevation={3} sx={{ marginBottom: 2, padding: 2 }}>
        {/* Filters */}
        <Grid container spacing={2} sx={{ mt: 2, mb: 3 }}>
          <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
            {/* Search Field */}
            <TextField
              fullWidth
              label="Search"
              variant="outlined"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />

            {/* Allocated Use Filter */}
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel>Allocated Use</InputLabel>
              <Select
                value={allocatedUse}
                onChange={(e) => setAllocatedUse(e.target.value)}
                label="Allocated Use"
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {[...new Set(bands.flatMap((band) => band.allocated_use))].map(
                  (use, index) => (
                    <MenuItem key={index} value={use}>
                      {use}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>

            {/* Frequency Range Filter */}
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel>Frequency Range</InputLabel>
              <Select
                value={selectedFreqRange}
                onChange={(e) => handleFreqRangeChange(e.target.value)}
                label="Frequency Range"
              >
                {FREQUENCY_RANGES.map((range, index) => (
                  <MenuItem key={index} value={range.label}>
                    {range.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* License Type Filter */}
          <Grid item xs={12} sm={6} sx={{ mb: 4 }}>
            <FormControl
              component="fieldset"
              sx={{ background: "#f5f5f5", p: 2, borderRadius: 2 }}
            >
              <Typography
                variant="subtitle1"
                align="center"
                gutterBottom
                sx={{ fontWeight: "bold", color: tertColor }}
              >
                License Type
              </Typography>
              <Grid container>
                {[...new Set(bands.flatMap((band) => band.license_types))].map(
                  (type, index) => (
                    <Grid item xs={6} key={index}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={licenseType.includes(type)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setLicenseType([...licenseType, type]);
                              } else {
                                setLicenseType(
                                  licenseType.filter((t) => t !== type)
                                );
                              }
                            }}
                          />
                        }
                        label={type}
                      />
                    </Grid>
                  )
                )}
              </Grid>
            </FormControl>
          </Grid>

          {isSmallScreen ? (
            <>
              {/* Frequency Range Slider */}
              <Grid item xs={12}>
                {_renderFreqRangeSlider()}
              </Grid>

              {/* Min and Max Frequency Input Fields Side-by-Side */}
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                  {_renderMinFreqTextBox()}
                </Grid>
                <Grid item xs={6}>
                  {_renderMaxFreqTextBox()}
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid
                container
                item
                xs={12}
                spacing={2}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* Min Frequency Input Field */}
                <Grid item xs={6} sm={3} md={2}>
                  {_renderMinFreqTextBox()}
                </Grid>

                {/* Frequency Range Slider */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={8}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <div style={{ width: "100%" }}>
                    {_renderFreqRangeSlider()}
                  </div>
                </Grid>

                {/* Max Frequency Input Field */}
                <Grid item xs={6} sm={3} md={2}>
                  {_renderMaxFreqTextBox()}
                </Grid>
              </Grid>
            </>
          )}

          {/* Clear Filters Button */}
          <Grid item xs={12} align="center" sx={{ mt: 2 }}>
            <Button
              startIcon={<SearchOffIcon />}
              onClick={handleClearFilters}
              variant="outlined"
            >
              Clear Filters
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Typography variant="body2" gutterBottom sx={{ mt: 4 }}>
        (Click on spectrum to view licensing details)
      </Typography>
      <hr />
      {/* Spectrum List */}
      <List>
        {filteredBands.map((band) => (
          <ListItem key={band.slug} disablePadding>
            <ListItemButton
              component={Link}
              to={`/wireless-spectrum/${band.slug}`}
            >
              <Typography variant="body1" sx={{ mr: 2 }}>
                {band.band}
              </Typography>
              <Typography variant="body2">
                {band.band_name && band.frequency
                  ? `${band.band_name} | (${band.frequency})`
                  : `${band.band_name}` || `${band.frequency}` || ""}
              </Typography>
            </ListItemButton>
          </ListItem>
        ))}
        {filteredBands.length === 0 && (
          <Typography variant="body2" sx={{ mt: 2 }}>
            No results match the selected filters.
          </Typography>
        )}
      </List>
    </Container>
  );
};

export default Spectrum;
